export function getWidgetType(vm) {
    let widgetTypeMode = 'light';
    if (vm.elementRef.current) {
        const computedStyle = window.getComputedStyle(vm.elementRef.current);
        if (computedStyle.color === '#000' || computedStyle.color === 'rgba(0, 0, 0, 0)' || computedStyle.color === 'rgb(0, 0, 0)') {
            widgetTypeMode = 'dark';
        }
    }
    vm.setState({ widgetTypeMode });
}

export const checkIfTurnstileValid = (token = null, type, vm) => {
    const { captchaToken, compSettings, alwaysShowTurnstile } = vm.state;
    const isAllowTurnstileForLiteApp = compSettings?.isAllowTurnstileForLiteApp;
    const { siteKey } = vm.props.portal;
    const { failLoginCode } = vm.props.user;
    const { systemFeature } = vm.props.systemFeatureReducer;

    const isCFTurnstileToggle = systemFeature?.[type]?.verify_security_validation?.includes('CLOUDFLARE_TURNSTILE');
    const checkTurnstileSpecialRule = alwaysShowTurnstile ? true : systemFeature?.[type]?.cf_turnstile_rules?.maxLoginAttempt && failLoginCode === 6206;
    const isTurnstileTokenEmpty = captchaToken === null && token === null;
    const isSiteKeyEmpty = siteKey === null || siteKey === '';

    const ifFinalValidTurnstile = isCFTurnstileToggle && checkTurnstileSpecialRule ? !isSiteKeyEmpty && !isTurnstileTokenEmpty : true;

    if (!isAllowTurnstileForLiteApp && window.SPL_LiteApp.isZT828LiteApp()) {
        return true;
    }
    return ifFinalValidTurnstile;
};
