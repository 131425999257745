import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
// import classnames from 'classnames';
import { Col } from 'react-bootstrap';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
// import ChangePassword from '../13ChangePassword/13_ChangePassword';
// import BankDetail from '../17BankDetail/17_BankDetail';
// import Messaging from '../12Messaging/12_Messaging';
// import MessagingDetailModal from '../12Messaging/122_MessagingDetailModal';
// import Loading from '../../components/Loading/Loading';

class Profile extends Component {
  isMount = true;

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 'privateMessage',
      account: {
        email: '',
        phone: '',
        login: '',
        name: '',
        dob: '',
        currency: '',
      },
      detailsObj: [],
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
      samePassword: false,
      hiddenCurrent: false,
      hiddenNewPassword: true,
      hiddenConfirmPassword: true,
      messageList: [],
      isLoading: false,
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  identity = () => {
    const { account } = this.props.user;
    if (this.isMount && account) {
      let duplicateAccount = window.SPL_Other.deepCopy(account);
      if (duplicateAccount && duplicateAccount.phone) {
        duplicateAccount.phone = window.SPL_Other.censored(duplicateAccount.phone, 'phone');
      }
      if (duplicateAccount && duplicateAccount.email) {
        duplicateAccount.email = window.SPL_Other.censored(duplicateAccount.email, 'email');
      }
      this.setState({ account: duplicateAccount });
    }
  };

  componentWillUnmount() {
    this.isMount = false;
  }
  componentDidMount() {
    this.identity();
    if (this.props.newpassword) {
      this.setState({ newpassword: this.props.newpassword });
    }
    this.getMessages();

    if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/profile') {
      this.props.history.push('/myaccount/myprofile');
    }
  }

  handlePasswordCurrentChange = (e) => {
    this.setState({ password: e.target.value });
  };

  toggleShowCurrent = () => {
    this.setState({ hiddenCurrent: !this.state.hiddenCurrent });
  };

  handlePasswordNewChange = (e) => {
    this.setState({ password: e.target.value });
  };
  handleConfirmPasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  toggleShowNewPassword = () => {
    this.setState({ hiddenNewPassword: !this.state.hiddenNewPassword });
  };

  handleInputChangeCurrent = (e) => {
    const currentpassword = e.target.value;

    this.setState({
      currentpassword,
    });
  };

  handleInputChangeNewPassword = (e) => {
    const newpassword = e.target.value;

    this.setState({
      newpassword,
    });
  };

  handleInputChangeConfirmPassword = (e) => {
    const confirmpassword = e.target.value;

    this.setState({
      confirmpassword,
    });
  };

  onFlagChange = (index) => {
    let array = this.state.messageList;
    let msg = array[index];
    msg.isFlagChecked = !msg.isFlagChecked;
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: msg.status,
    };
    array[index] = msg;
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      this.setState({ messageList: array });
    });
  };

  onClickMessage = (index, page) => {
    let subIndex = index;
    let i = 1;
    if (page > i) {
      for (let j = 0; j < i; j++) {
        subIndex = subIndex + 10;
      }
    }

    let array = this.state.messageList;
    let msg = array[subIndex];
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: 'R',
    };
    array[subIndex] = msg;
    this.setState({ selectedMessage: msg, openMessageModal: true });
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        if (this.isMount) {
          this.getMessages();
        }
      }, 1000);
    });
  };

  getMessages() {
    window.SPL_Member.getMessages().then((msgObj) => {
      if (this.isMount) {
        let messageArr = msgObj.messages;
        for (let i = 0; i < messageArr.length; i++) {
          messageArr[i].isChecked = false;
          if (messageArr[i].status.includes('F')) {
            messageArr[i].isFlagChecked = true;
          } else {
            messageArr[i].isFlagChecked = false;
          }
        }
      }
    });
  }

  closeModal = () => {
    this.setState({ selectedMessage: null, openMessageModal: false });
  };

  isChecked = (index) => {
    return this.state.checkAll || this.state.messageList[index].isChecked;
  };

  isFlagChecked = (index) => {
    return this.state.messageList[index].isFlagChecked;
  };

  onFlagChange = (index) => {
    let array = this.state.messageList;
    let msg = array[index];
    msg.isFlagChecked = !msg.isFlagChecked;
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: msg.status,
    };
    array[index] = msg;
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      this.setState({ messageList: array });
    });
  };

  deleteMessageByBatch = () => {
    this.setState(
      { isLoading: true },
      () => {
        let listOfCheckedId = [];
        let messageList = this.state.messageList;
        for (let i = 0; i < messageList.length; i++) {
          const msg = messageList[i];
          if (msg.isChecked) {
            listOfCheckedId.push(msg.id);
          }
        }
        this.setState({ listOfCheckedId: listOfCheckedId }, () => {
          window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
            this.timeOut = setTimeout(() => {
              if (this.isMount) {
                this.getMessages();
              }
            }, 1000);
            this.setState({ listOfCheckedId: [] });
          });
        });
      },
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1800)
    );
  };

  render() {
    const { email, phone, login, name, dob, currency } = this.state.account || { email: '', phone: '', login: '', name: '', dob: '', currency: '' };

    return (
      <Translation>
        {(t) => (
          <div className='app-profile'>
            <Row className='d-none d-md-block'>
              <div className='account-col'>
                <div className='form-content d-flex justify-content-center'>
                  <Col xs={12} md={12} className='d-flex justify-content-center'>
                    <Col xs={12} md={8}>
                      <div>
                        <Row className='form-group'>
                          <Col xs={12} md={12} lg={12}>
                            <div className='d-flex'>
                              <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                                <div className='d-flex'>
                                  <Col xs={12} md={4} className='d-flex'>
                                    <label htmlFor='email' className='form-label'>
                                      {t('global:global.form.email')}
                                    </label>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <div className=''>:</div>
                                  </Col>
                                  <Col xs={12} md={7}>
                                    <div className='editProfile-content font-weight-bold'>{email}</div>
                                  </Col>
                                </div>
                              </Col>
                              <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                                <div className='d-flex'>
                                  <Col xs={12} md={4}>
                                    <label htmlFor='name' className='form-label'>
                                      {t('global:global.form.fullname')}
                                    </label>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <div className=''>:</div>
                                  </Col>
                                  <Col xs={12} md={7}>
                                    <div className='editProfile-content font-weight-bold'>{name}</div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <div className='d-flex'>
                              <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                                <div className='d-flex'>
                                  <Col xs={12} md={4}>
                                    <label htmlFor='phone' className='form-label'>
                                      {t('global:global.form.contact')}
                                    </label>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <div className=''>:</div>
                                  </Col>
                                  <Col xs={12} md={7}>
                                    <div className='editProfile-content font-weight-bold'>{phone}</div>
                                  </Col>
                                </div>
                              </Col>
                              <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                                <div className='d-flex'>
                                  <Col xs={12} md={4}>
                                    <label htmlFor='dob' className=' form-label'>
                                      {t('global:global.form.dob')}
                                    </label>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <div className=''>:</div>
                                  </Col>
                                  <Col xs={12} md={7}>
                                    <div className='editProfile-content font-weight-bold'>{dob}</div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col xs={12} md={12} lg={12}>
                            <div className='d-flex'>
                              <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                                <div className='d-flex'>
                                  <Col xs={12} md={4}>
                                    <label htmlFor='login' className=' form-label'>
                                      {t('global:global.form.username')}
                                    </label>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <div className=''>:</div>
                                  </Col>
                                  <Col xs={12} md={7}>
                                    <div className='editProfile-content font-weight-bold'>{login.toLowerCase()}</div>
                                  </Col>
                                </div>
                              </Col>
                              <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                                <div className='d-flex'>
                                  <Col xs={12} md={4}>
                                    <label htmlFor='currency' className=' form-label'>
                                      {t('global:global.form.currency')}
                                    </label>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <div className=''>:</div>
                                  </Col>
                                  <Col xs={12} md={7}>
                                    <div className='editProfile-content font-weight-bold'>{currency}</div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Col>
                </div>
              </div>
            </Row>
            <div className='d-block d-md-none content'>
              {' '}
              <div className='row d-flex justify-content-center'>
                <div className='mobile-content-width'>
                  <Col xs={12} md={12}>
                    <div>
                      <Row className='form-group p-t-10'>
                        <Col xs={12} md={12} lg={12}>
                          <Row className='d-flex'>
                            <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                              <Row className='d-flex'>
                                <Col xs={4} md={4}>
                                  <label htmlFor='email' className='form-label editProfile-text'>
                                    {t('global:global.form.email')}
                                  </label>
                                </Col>
                                <Col xs={1} md={1}>
                                  <div className='editProfile-text'>:</div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className='editProfile-content font-weight-bold'>{email}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                              <Row className='d-flex'>
                                <Col xs={4} md={4}>
                                  <label htmlFor='name' className='editProfile-text form-label'>
                                    {t('global:global.form.fullname')}
                                  </label>
                                </Col>
                                <Col xs={1} md={1}>
                                  <div className='editProfile-text'>:</div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className='editProfile-content font-weight-bold'>{name}</div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={12} lg={12}>
                          <Row className='d-flex'>
                            <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                              <Row className='d-flex'>
                                <Col xs={4} md={4}>
                                  <label htmlFor='phone' className='form-label editProfile-text'>
                                    {t('global:global.form.contact')}
                                  </label>
                                </Col>
                                <Col xs={1} md={1}>
                                  <div className='editProfile-text'>:</div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className='editProfile-content font-weight-bold'>{phone}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                              <Row className='d-flex'>
                                <Col xs={4} md={4}>
                                  <label htmlFor='dob' className='editProfile-text form-label'>
                                    {t('global:global.form.dob')}
                                  </label>
                                </Col>
                                <Col xs={1} md={1}>
                                  <div className='editProfile-text'>:</div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className='editProfile-content font-weight-bold'>{dob}</div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                          <Row className='d-flex'>
                            <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                              <Row className='d-flex'>
                                <Col xs={4} md={4}>
                                  <label htmlFor='login' className='editProfile-text form-label'>
                                    {t('global:global.form.username')}
                                  </label>
                                </Col>
                                <Col xs={1} md={1}>
                                  <div className='editProfile-text'>:</div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className='editProfile-content font-weight-bold'>{login.toLowerCase()}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} md={6} lg={6} className='form-spacing form-group'>
                              <Row className='d-flex'>
                                <Col xs={4} md={4}>
                                  <label htmlFor='currency' className='editProfile-text form-label'>
                                    {t('global:global.form.currency')}
                                  </label>
                                </Col>
                                <Col xs={1} md={1}>
                                  <div className='editProfile-text'>:</div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className='editProfile-content font-weight-bold'>{currency}</div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Profile));
