import PropTypes from 'prop-types';
import React, { lazy, Suspense, useLayoutEffect, useState, Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Loading from 'root/components/Loading/Loading';
import ScrollToTop from 'root/components/ScrollToTop/ScrollToTop';
import Home from 'root/pages/01Home/home/Home';
// import Home from 'root/page/home/Home';
import MyAccount from 'root/pages/16MyAccount/16_MyAccount';
import { screenAction } from 'root/redux/action';
import { mapStateToProps } from 'root/redux/selector/selector';
import Navigation from '../../MainNavigation/AppHeader/Navigation_01/Navigation_01';
// import SideBanner from '../../MainNavigation/AppSideBar/SideBanner';
import Sidebar from '../../MainNavigation/AppSideBar/SideBar_01/SideBar_01';
// import RouterChangeHandler from '../../MainNavigation/RouterChangeHandler/RouterChangeHandler';
import MainLayout from '../..//MainLayout';
import Funds from 'root/pages/wap/funds/Funds';
import Profile from 'root/pages/profile/funds/Funds';
import Reward from 'root/components/reward';
import { ToastContainer } from 'react-toastify';
import FloatingNotification from 'root/pages/Share/floating-notification/floating-notification';
import ForceChangePassword from 'root/components/maple/organisms/force-change-password/force-change-password';
import FloatingFreeSpin from 'root/components/maple/floating-freespin';

// Declare lazy laod
const Login = lazy(() => import('root/pages/02Login/021_Login'));
const ContactUs = lazy(() => import('root/pages/03ContactUs/03_Contact'));
const Download = lazy(() => import('root/components/Download'));
const RegisterDesktop = lazy(() => import('root/pages/05Registration/05_RegisterDesktop'));
const RegisterSuccess = lazy(() => import('root/pages/05Registration/054_Register_Success'));
const Maintenance = lazy(() => import('root/pages/02Login/Maintenance'));
const Language = lazy(() => import('root/pages/06Language/06_Language'));
// const LiveTV = lazy(() => import('root/pages/07LiveTV/07_LiveTV'));
const Deposit = lazy(() => import('root/pages/08Deposit/08_Deposit'));
const QuickPayRedirect = lazy(() => import('root/pages/08Deposit/083_QuickPayRedirect'));
const Promotion = lazy(() => import('root/pages/09Promotion/09_Promotion'));
const Transfer = lazy(() => import('root/pages/10Transfer/10_Transfer'));
const Withdraw = lazy(() => import('root/pages/11Withdraw/11_Withdraw'));
const Messaging = lazy(() => import('root/pages/12Messaging/12_Messaging'));
const ChangePassword = lazy(() => import('root/pages/13ChangePassword/13_ChangePassword'));
const ChangeGamePassword = lazy(() => import('root/pages/19ChangeGamePassword/19_ChangeGamePassword'));
const ResetPasswordRequest = lazy(() => import('root/pages/13ChangePassword/131_ResetPasswordRequest'));
const ResetPasswordFinish = lazy(() => import('root/pages/13ChangePassword/131_ResetPasswordFinish'));
const MyProfile = lazy(() => import('root/pages/14EditProfile/14_MyProfile'));
const History = lazy(() => import('root/pages/15History/15_History'));
const BankDetail = lazy(() => import('root/pages/17BankDetail/17_BankDetail'));
const Fund = lazy(() => import('root/pages/18Fund/18_Fund'));
// const Funds = lazy(() => import('root/pages/wap/funds/Funds'));
const Sports = lazy(() => import('root/pages/Provider/Sports/01Sports'));
const Casino = lazy(() => import('root/pages/Provider/Casino/01Casino'));
const Slot = lazy(() => import('root/pages/Provider/Slot/01Slot'));
// const Slot = lazy(() => import('root/page/slots/Slots'));
const Poker = lazy(() => import('root/pages/Provider/Poker/01Poker'));
const Fishing = lazy(() => import('root/pages/Provider/Fishing/01Fishing'));
const Lottery = lazy(() => import('root/pages/Provider/Lottery/01Lottery'));
const Scr = lazy(() => import('root/pages/Provider/Slot/02Scr'));
const Mega = lazy(() => import('root/pages/Provider/Slot/03Mega'));
const PSY8 = lazy(() => import('root/pages/Provider/Slot/Psy8'));
const Ultimate = lazy(() => import('root/pages/Provider/Slot/04Ultimate'));
const GameLauncher = lazy(() => import('root/pages/GameLauncher/01GameLauncher'));
const Score = lazy(() => import('root/pages/20Score/20_Score'));
// const RedirectPage = lazy(() => import('root/pages/RedirectPage/RedirectPage'));
const MaintenancePage = lazy(() => import('root/pages/02Login/MaintenancePage'));
const AboutUs = lazy(() => import('root/pages/01Home/AboutUs'));
const Affiliate = lazy(() => import('root/pages/22Aff/22_Affiliate'));
const Vip = lazy(() => import('root/pages/23Vip/23_Vip'));
const Terms = lazy(() => import('root/pages/024TermsandCondition/24_Terms_and_Condition'));
const OnlineTransfer = lazy(() => import('root/pages/08Deposit/085_OnlineTransfer'));
const QuickPay = lazy(() => import('root/pages/08Deposit/084_QuickPay'));
const CasinoIndex = lazy(() => import('root/pages/Provider/Casino/00CasinoIndex'));
const SlotIndex = lazy(() => import('root/pages/Provider/Slot/00SlotIndex_01'));
const GamesIndex = lazy(() => import('root/pages/Provider/Games/00GameIndex_01'));
const SportsIndex = lazy(() => import('root/pages/Provider/Sports/00SportIndex_01'));
const FishingIndex = lazy(() => import('root/pages/Provider/Fishing/00FishingIndex_01'));
const LotteryIndex = lazy(() => import('root/pages/Provider/Lottery/00LotteryIndex_01'));
const EsportIndex = lazy(() => import('root/pages/Provider/Esports/00EsportIndex_01'));
const Favourite = lazy(() => import('root/pages/027Favourite/027_Favourite'));
const RaffleDraw = lazy(() => import('root/pages/028RaffleDraw/028_RaffleDraw'));
const RaffleDrawHistory = lazy(() => import('root/pages/028RaffleDraw/028_RaffleDraw_History'));
const HowToPage = lazy(() => import('root/pages/025Howto/025_How_To_Page'));
const FAQ = lazy(() => import('root/pages/26FAQ/026_FAQ'));
const EmailVerificationLanding = lazy(() => import('root/pages/EmailVerification/email-verification'));
const CockFighting = lazy(() => import('root/pages/Provider/CockFight'));
const FourD = lazy(() => import('root/pages/Provider/4d/01FourD'));
const FourDIndex = lazy(() => import('root/pages/Provider/4d/00FourDIndex_01'));

const FastGames = lazy(() => import('root/pages/Provider/FastGames/00SlotIndex_01'));

class MerchantLayout extends React.Component {
    isMount = true;
    constructor(props) {
        super();
    }

    componentDidMount() {
        this.isMount = true;
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    LazyComponent = (Component) => {
        const { langPath } = this.props;
        return (props) => <Suspense fallback={<Loading></Loading>}>{this.isMount && <Component langPath={langPath} {...this.props} />}</Suspense>;
    };

    render() {
        const { showSidebar, sideBarSubMenuShow, initialize, langPath, user, screen, portal } = this.props;
        let useFloatingNotification = false;
        let forceChangePassword = false;

        //route protection
        let notAccessibleRoutes = { raffledraw: false };
        if (portal && portal.settings) {
            if (portal.settings.features) {
                let features = portal.settings.features;
                if (features.useFloatingNotification) {
                    useFloatingNotification = features.useFloatingNotification;
                }
            }
            if (portal.settings.replaceRaffleDrawFor && langPath) {
                const currentUrlCountry = langPath.split('-')[1].toLowerCase();
                const hideRaffleDraw = portal.settings.replaceRaffleDrawFor.find((item) => item.split('_')[1].toLowerCase() === currentUrlCountry);
                if (hideRaffleDraw) {
                    notAccessibleRoutes.raffledraw = true;
                }
            }
            if (portal.settings?.promptChangePassword) {
                if (user?.account?.requiredResetPassword) {
                    forceChangePassword = true;
                }
            }
        }
        if (langPath === '') {
            return <Loading></Loading>;
        } else {
            return (
                initialize && (
                    <Translation>
                        {(t) => (
                            <div className={`${!['redirect-error'].some((pathName) => this.props.history.location.pathname.includes(pathName)) && 'screen'}`}>
                                <Fragment>
                                    <ToastContainer
                                        position={screen.viewType === 'web' ? 'top-center' : 'bottom-center'}
                                        autoClose={10000}
                                        hideProgressBar={true}
                                        newestOnTop
                                        closeOnClick={true}
                                        rtl={false}
                                        pauseOnFocusLoss={false}
                                        draggable={false}
                                        pauseOnHover
                                    ></ToastContainer>

                                    <div className='App '>
                                        {/* <ShareComp /> */}
                                        {user.isLoading && <Loading></Loading>}
                                        <Router basename={langPath}>
                                            <ShowWindowDimensions parentProps={this.props} />
                                            <Reward />
                                            <FloatingFreeSpin />

                                            {useFloatingNotification && <FloatingNotification />}
                                            {user.isLogin && forceChangePassword && <ForceChangePassword />}
                                            {/* <RouterChangeHandler></RouterChangeHandler> */}
                                            {/* <Sidebar
                      showSidebar={showSidebar}
                      sideBar={handleSideBar}
                      sideBarSubMenuShow={sideBarSubMenuShow}
                      showSideBarSubMenuMenu={showSideBarSubMenuMenu}
                    />
                    <Navigation
                      isLogin={user.isLogin}
                      toggleSidebar={handleSideBar}
                      showSidebar={showSidebar}
                      sideBarSubMenuShow={sideBarSubMenuShow}
                    /> */}
                                            {/* <SideBanner /> */}
                                            <div
                                                id='mTopPadding'
                                                className={`app-content sidebar-transition  ${showSidebar ? 'screenMoveRight' : 'screenMoveLeft'} 
                      ${showSidebar && sideBarSubMenuShow && 'screenMoveRightWithSubmenu'}`}
                                                onClick={this.props.onClickFunc}
                                            >
                                                {/* <MainLayout> */}
                                                <ScrollToTop>
                                                    <Switch>
                                                        <Route exact path='/home' component={Home} />
                                                        <Route path='/home/:type' component={Home} />
                                                        <Route path='/login' component={Home} />
                                                        <Route path='/register' component={Home} />
                                                        <Route path='/resetpassword' component={Home} />
                                                        <Route path='/registersuccess' component={Home} />
                                                        <Route path='/maintenance' render={() => this.LazyComponent(Maintenance)()} />
                                                        <Route path='/contact' render={() => this.LazyComponent(ContactUs)()} />
                                                        <Route path='/download' render={() => this.LazyComponent(Download)()} />
                                                        <Route path='/language' render={() => this.LazyComponent(Language)()} />
                                                        {/* <Route path='/livetv' component={this.LazyComponent(LiveTV)} /> */}
                                                        <Route path='/promotion' render={() => this.LazyComponent(Promotion)()} />
                                                        {/* <Route path='/resetpassword' component={this.LazyComponent(ResetPasswordRequest)} /> */}
                                                        <Route path='/reset/finish' render={() => this.LazyComponent(ResetPasswordFinish)()} />
                                                        {/* <Route path='/slot/:provider' component={this.LazyComponent(Slot)} /> */}
                                                        <Route path='/casino/idn' render={() => this.LazyComponent(Poker)()} />
                                                        <Route path='/idn' render={() => this.LazyComponent(Poker)()} />
                                                        <Route path='/poker' render={() => this.LazyComponent(Poker)()} />
                                                        <Route
                                                            path='/sports/:provider'
                                                            render={() => {
                                                                return <Redirect to='/sports' />;
                                                            }}
                                                        />
                                                        <Route
                                                            path='/casino/:provider'
                                                            render={() => {
                                                                return <Redirect to='/casino' />;
                                                            }}
                                                        />
                                                        <Route path='/virtualsports-landing' render={() => this.LazyComponent(SportsIndex)()} />
                                                        <Route path='/cmd368' render={() => this.LazyComponent(SportsIndex)()} />
                                                        <Route path='/virtualsports' render={() => this.LazyComponent(SportsIndex)()} />
                                                        <Route path='/sbo' render={() => this.LazyComponent(Sports)()} />
                                                        <Route path='/ufa' render={() => this.LazyComponent(Sports)()} />
                                                        <Route path='/m8' render={() => this.LazyComponent(Sports)()} />
                                                        <Route path='/casino/:provider' render={() => this.LazyComponent(Casino)()} />
                                                        <Route path='/slots/:provider' render={() => this.LazyComponent(SlotIndex)()} />
                                                        <Route path='/games/:provider' render={() => this.LazyComponent(GamesIndex)()} />
                                                        {/* <Route path='/ultimate-slots' render={() => this.LazyComponent(Slot)} /> */}
                                                        <Route path='/fishing/:provider' render={() => this.LazyComponent(Fishing)()} />
                                                        <Route path='/gggaming' render={() => this.LazyComponent(Fishing)()} />
                                                        <Route path='/cashfish' render={() => this.LazyComponent(Fishing)()} />
                                                        <Route path='/sg-fishing' render={() => this.LazyComponent(Fishing)()} />
                                                        <Route path='/sa-fishing' render={() => this.LazyComponent(Fishing)()} />
                                                        <Route path='/sg-fishingwar' render={() => this.LazyComponent(Fishing)()} />
                                                        <Route path='/lottery/:provider' render={() => this.LazyComponent(Lottery)()} />
                                                        <Route path='/4d/:provider' render={() => this.LazyComponent(FourD)()} />
                                                        <Route path='/qqkeno' render={() => this.LazyComponent(Lottery)()} />
                                                        <Route path='/qqkeno-thai' render={() => this.LazyComponent(Lottery)()} />
                                                        <Route path='/918kiss' render={() => this.LazyComponent(Scr)()} />
                                                        <Route path='/scr888' render={() => this.LazyComponent(Scr)()} />
                                                        <Route path='/mega888' render={() => this.LazyComponent(Mega)()} />
                                                        <Route path='/psy8' render={() => this.LazyComponent(PSY8)()} />
                                                        <Route path='/ultimate-slots' render={() => this.LazyComponent(Ultimate)()} />
                                                        <Route path='/game' render={() => this.LazyComponent(GameLauncher)()} />
                                                        {/* <Route path='/redirect-liteapp' render={() => this.LazyComponent(RedirectPage)} /> */}
                                                        <Route path='/redirect-error' render={() => this.LazyComponent(MaintenancePage)()} />
                                                        <Route path='/aboutus' render={() => this.LazyComponent(AboutUs)()} />
                                                        {/* <Route path='/contactus' render={() => this.LazyComponent(ContactusWeb)} /> */}
                                                        <Route path='/vip' render={() => this.LazyComponent(Vip)()} />
                                                        <Route path='/affiliate' render={() => this.LazyComponent(Affiliate)()} />
                                                        <Route path='/terms' render={() => this.LazyComponent(Terms)()} />
                                                        <Route path='/casino' render={() => this.LazyComponent(CasinoIndex)()} />
                                                        <Route path='/slots' render={() => this.LazyComponent(SlotIndex)()} />
                                                        <Route path='/games' render={() => this.LazyComponent(GamesIndex)()} />
                                                        <Route path='/sports' render={() => this.LazyComponent(SportsIndex)()} />
                                                        <Route path='/fishing' render={() => this.LazyComponent(FishingIndex)()} />
                                                        <Route path='/lottery' render={() => this.LazyComponent(LotteryIndex)()} />
                                                        <Route path='/4d' render={() => this.LazyComponent(FourDIndex)()} />
                                                        <Route path='/esports' render={() => this.LazyComponent(EsportIndex)()} />
                                                        <Route path='/help/:page' render={() => this.LazyComponent(HowToPage)()} />
                                                        <Route path='/faq' render={() => this.LazyComponent(FAQ)()} />
                                                        <Route path='/favourite' render={() => this.LazyComponent(Favourite)()} />
                                                        {!notAccessibleRoutes.raffledraw && <Route path='/raffledraw' render={() => this.LazyComponent(RaffleDraw)()} />}
                                                        {!notAccessibleRoutes.raffledraw && (
                                                            <Route path='/raffledraw-history' render={() => this.LazyComponent(RaffleDrawHistory)()} />
                                                        )}
                                                        <Route path='/emailVerification' render={() => this.LazyComponent(EmailVerificationLanding)()} />
                                                        <Route path='/cockfight' render={this.LazyComponent(CockFighting)} />
                                                        <Route path='/fastgames' render={this.LazyComponent(FastGames)} />
                                                        <Route path='/m/mcasino/GD' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino/DG' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino/BMK' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino.gp/GP' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino.ag/AG' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino.pt/PT' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino.ug/UG' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino.idn/IDN' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/mcasino.ab/AB' render={() => <Redirect to='/casino' />} />
                                                        <Route path='/m/slot/SG' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/AG' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/GP' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/PT' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/MG' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/TTG' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/UL' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/m918kiss2' render={() => <Redirect to='/slots' />} />
                                                        <Route path='/m/slot/TTG' render={() => <Redirect to='/slots' />} />

                                                        <PrivateRoute isLogin={user.isLogin} path='/myaccount' component={MyAccount} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/myaccount/deposit' component={this.LazyComponent(Deposit)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/onlinetransfer' component={this.LazyComponent(OnlineTransfer)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/quickpay' component={this.LazyComponent(QuickPay)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/myaccount/transfer' component={this.LazyComponent(Transfer)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/myaccount/withdraw' component={this.LazyComponent(Withdraw)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/myaccount/history' component={this.LazyComponent(History)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/myaccount/messaging' component={this.LazyComponent(MyAccount)} />
                                                        <PrivateRoute
                                                            isLogin={user.isLogin}
                                                            path='/myaccount/changegamepassword'
                                                            component={this.LazyComponent(ChangeGamePassword)}
                                                        />
                                                        <PrivateRoute isLogin={user.isLogin} path='/quickpayredirect' component={this.LazyComponent(QuickPayRedirect)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/messaging' component={this.LazyComponent(Messaging)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/changepassword' component={this.LazyComponent(ChangePassword)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/profile' component={this.LazyComponent(MyProfile)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/bankdetail' component={this.LazyComponent(BankDetail)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/fund' component={this.LazyComponent(Fund)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/account' component={Funds} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/myprofile' component={Profile} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/deposit' component={this.LazyComponent(Deposit)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/transfer' component={this.LazyComponent(Transfer)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/withdraw' component={this.LazyComponent(Withdraw)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/history' component={this.LazyComponent(History)} />
                                                        <PrivateRoute isLogin={user.isLogin} path='/changegamepassword' component={this.LazyComponent(ChangeGamePassword)} />

                                                        <Redirect from='*' to='/home' />
                                                    </Switch>
                                                    {(window.pageInitialize = true)}
                                                </ScrollToTop>
                                                {/* </MainLayout> */}
                                            </div>
                                        </Router>
                                        {user.loading || (portal.isLoading && <Loading></Loading>)}
                                    </div>
                                </Fragment>
                            </div>
                        )}
                    </Translation>
                )
            );
        }
    }
}

const PrivateRoute = ({ component: Component, isLogin, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLogin ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                    }}
                />
            )
        }
    />
);

const useWindowSize = (parentProps) => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            if (
                ((parentProps.screen.isMobile && window.innerWidth > 768) || (!parentProps.screen.isMobile && window.innerWidth <= 768)) &&
                !window.location.pathname.includes('/vip')
            ) {
                parentProps.dispatch(screenAction.updateScreenSize(window.innerWidth, window.innerHeight, parentProps.screen.isMobile));
                setSize([window.innerWidth, window.innerHeight]);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [parentProps, parentProps.screen, parentProps.screen.isMobile]);
    return size;
};

const ShowWindowDimensions = ({ parentProps }) => {
    const [width, height] = useWindowSize(parentProps);
    return <span></span>;
};

MerchantLayout.propTypes = {
    showSideBarSubMenuMenu: PropTypes.func.isRequired,
    handleSideBar: PropTypes.func.isRequired,
    showSidebar: PropTypes.bool.isRequired,
    sideBarSubMenuShow: PropTypes.bool.isRequired,
    initialize: PropTypes.bool.isRequired,
    langPath: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(withTranslation(['home', 'global', 'transaction'])(withRouter(MerchantLayout)));
