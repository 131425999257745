import th from 'date-fns/locale/th';
import zhCN from 'date-fns/locale/zh-CN';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { AiOutlineCopy } from 'react-icons/ai';
import { IoIosAdd } from 'react-icons/io';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SmallLoading from '../../components/Loading/SmallLoading';
import { mapStateToProps } from '../../redux/selector/selector';
import BankDetailModal from '../17BankDetail/174_BankDetail_Modal';
import { Fragment } from 'react';
import SVGElements from 'root/components/SVGElements';
import TransactionNote from '../../pages/Share/transaction-note/component/transaction-note';
import ImageHelper from 'root/pages/Share/image-helper';
import vm from './085_OnlineTransfer.controller';
import BankIcon from '../../components/Molecules/bank-icon';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT } from '@constants';

registerLocale('th', th);
registerLocale('zhCN', zhCN);

class OnlineTransfer extends Component {
    props = null;

    constructor(props) {
        super(props);
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        vm.setDepositOptions();
        vm.loadDepositDetails();
        vm.loadMerchantBank();
        vm.loadBankAccounts();
        vm.loadBankAccountsForDeposit();
        vm.checkCountryLanguage();
        vm.loadAdditionalDepositSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps !== this.props) {
    //         vm.loadBankAccounts();
    //     }
    // }

    render() {
        const { language, user, screen, paymentGatewaySetting, portal } = this.props;
        const {
            showRefId,
            hideAddBankAccount,
            uploadReceipt,
            depositMinMaxLimit,
            merchantBankList,
            radioValue,
            customTranslationObj,
            showCustomDepositArticle,
            showCustomDepositArticleContent,
            customArticleIsJson,
            customContentStyle,
            showDefaultArticle,
            selectedDepositBank,
            depositamountError,
            amount,
            amountErrMsg,
            limitAmount,
            currency,
            showDepositChannel,
            depositOptions,
            selectedOption,
            selectedDepositChannel,
            showOnlineBankDetails,
            showOnlineBankingOnly,
            memberBankList,
            selectedBank,
            isOpenBankModal,
            unOwnedBankList,
            referenceCode,
            receiptImg,
            disabledDepositButton,
            status,
            message,
            getTncReferral,
            isShowNoticeBox,
            depositAmountOptions,
        } = this.state;
        const { country } = language;

        let transactionNoteProps = {
            showCustomArticle: showCustomDepositArticle,
            customArticleContent: showCustomDepositArticleContent,
            customArticleIsJson: customArticleIsJson,
            customContentStyle: customContentStyle,
            hideDefaultNote: [!showDefaultArticle, true, true],
        };

        if (!user.account) return null;
        return (
            <Translation>
                {(t) => (
                    <div className='app-onlinetransfer'>
                        <ul>
                            <li>
                                <div className='item onlinetransfer-bankacc'>
                                    <p>
                                        {customTranslationObj && customTranslationObj.bankAccount
                                            ? t(customTranslationObj.bankAccount)
                                            : t('transaction:transaction.deposit.cashdeposit.bankaccount')}{' '}
                                        <span className='text-danger asterisk'>*</span>
                                    </p>
                                    <div className='bank-selector'>
                                        {merchantBankList.length > 0 ? (
                                            <ul className='bank-list'>
                                                {merchantBankList.map((account, index) => {
                                                    const isActive = radioValue === index;
                                                    return (
                                                        <li key={index} className='small-bank bank-acc-list'>
                                                            <div key={index} className='member-bankacc'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name='bank-account'
                                                                        onChange={() => vm.onDepositBankAcc(index)}
                                                                        value={radioValue}
                                                                        checked={isActive}
                                                                    />
                                                                    <span className='checkmark'></span>

                                                                    <div key={account.id} className='banklist-group'>
                                                                        <div className='group-box'>
                                                                            <div className={`bank-img ${isActive ? 'bank-on' : ''}`}>
                                                                                <BankIcon bankCode={account.code} isActive={isActive} />

                                                                                {account.bankMaintenanceInfo && (
                                                                                    <div
                                                                                        className={`${
                                                                                            vm.isBankUnderMaintenanceCheck(
                                                                                                account.bankMaintenanceInfo.regularMaintenance.start,
                                                                                                account.bankMaintenanceInfo.regularMaintenance.end
                                                                                            )
                                                                                                ? 'bank-maintenance'
                                                                                                : 'bank-active'
                                                                                        }`}
                                                                                    ></div>
                                                                                )}
                                                                            </div>
                                                                            <div className={`bank-name ${radioValue === index ? 'bank-selected' : ''}`}>{account.name}</div>
                                                                        </div>

                                                                        {screen.isMobile && radioValue === index && (
                                                                            <Fragment>
                                                                                <div className='bank-details'>
                                                                                    <input disabled={true} value={account.accountHolder}></input>
                                                                                    <SVGElements
                                                                                        className='icon-copy'
                                                                                        name='copy-icon'
                                                                                        onClick={() => vm.copy(account.accountHolder, t)}
                                                                                    />
                                                                                </div>
                                                                                <div className='bank-details'>
                                                                                    <input disabled={true} value={account.accountNumber}></input>
                                                                                    <SVGElements
                                                                                        className='icon-copy'
                                                                                        name='copy-icon'
                                                                                        onClick={() => vm.copy(account.accountNumber, t)}
                                                                                    />
                                                                                </div>
                                                                            </Fragment>
                                                                        )}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <SmallLoading></SmallLoading>
                                        )}
                                    </div>
                                </div>

                                {selectedDepositBank ? (
                                    <>
                                        {!screen.isMobile && (
                                            <div className='item onlinetransfer-bankacc'>
                                                <p>
                                                    {customTranslationObj && customTranslationObj.bankAccount
                                                        ? t(customTranslationObj.bankAccount)
                                                        : t('transaction:transaction.deposit.cashdeposit.bankaccount')}{' '}
                                                    <span className='text-danger asterisk'>*</span>
                                                </p>
                                                <div className='bank-selector'>
                                                    <ul className='bank-list'>
                                                        <li className='small-bank bank-acc-list'>
                                                            <div className='member-bankacc'>
                                                                {selectedDepositBank ? (
                                                                    <div className='radio-form'>
                                                                        <div className='account-holder'>
                                                                            {selectedDepositBank.accountHolder}{' '}
                                                                            <AiOutlineCopy
                                                                                className='icon-copy'
                                                                                onClick={() => vm.copy(selectedDepositBank.accountHolder, t)}
                                                                            />
                                                                        </div>

                                                                        <div className='account-holder'>
                                                                            {selectedDepositBank.accountNumber}{' '}
                                                                            <AiOutlineCopy
                                                                                className='icon-copy'
                                                                                onClick={() => vm.copy(selectedDepositBank.accountNumber, t)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <SmallLoading></SmallLoading>
                                                                )}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}

                                        {(!paymentGatewaySetting ||
                                            (paymentGatewaySetting &&
                                                !paymentGatewaySetting.amountFieldNotRequired.includes(
                                                    merchantBankList.length > 0 && merchantBankList[radioValue]?.code
                                                ))) && (
                                            <Fragment>
                                                <div className='item'>
                                                    <p>
                                                        {t('transaction:transaction.deposit.cashdeposit.depositamount')} <span className='text-danger asterisk'>*</span>
                                                    </p>
                                                    <div className='deposit-amount'>
                                                        {depositMinMaxLimit ? (
                                                            <div className='deposit-amount-input'>
                                                                <input
                                                                    name='depositamount'
                                                                    id='name'
                                                                    className={`form-control-inner ${depositamountError ? 'is-invalid' : ''}`}
                                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                        min: window.SPL_Other.formatAmount(depositMinMaxLimit.min),
                                                                        max: window.SPL_Other.formatAmount(depositMinMaxLimit.max),
                                                                    })}
                                                                    min='0'
                                                                    value={amount}
                                                                    onChange={(e) => vm.handleInputFieldAmountChange(e.target.value)}
                                                                    onBlur={vm.validateDepositAmount}
                                                                />
                                                                {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton &&
                                                                    depositAmountOptions?.length > 0 && (
                                                                        <div className='deposit-amount-clear-button' onClick={vm.clearInputFieldAmount}>
                                                                            <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            <SmallLoading></SmallLoading>
                                                        )}
                                                        {country === 'VN' && <div className='invalid-feedback text-success'>1 = 1000 đồng</div>}
                                                        {amountErrMsg.map((errMsg, index) => {
                                                            return (
                                                                <div key={index} className='invalid-feedback text-success'>
                                                                    <Trans i18nKey={errMsg}></Trans> {`${limitAmount.length > 0 ? currency + ' ' + limitAmount : ''} `}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                                {/* var can be removed if needed, just want to load done tgt with minmax */}
                                                {depositMinMaxLimit ? (
                                                    <div className='item amount-select'>
                                                        <p className='deposit-options'></p>
                                                        <div className='amount-select-btn'>
                                                            {depositAmountOptions.map((item) => (
                                                                <button className='btn-amount' onClick={() => vm.handleButtonAmountChange(item)}>
                                                                    {item}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <SmallLoading></SmallLoading>
                                                )}
                                            </Fragment>
                                        )}

                                        {showDepositChannel && (
                                            <div className='item deposit-channel'>
                                                <p>
                                                    {t('transaction:transaction.deposit.cashdeposit.channel')} <span className='text-danger asterisk'>*</span>
                                                </p>
                                                <div className='deposit-amount'>
                                                    {depositOptions ? (
                                                        <Select
                                                            styles={{
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    padding: 5,
                                                                    borderRadius: 5,
                                                                    background: selectedOption.value,
                                                                }),
                                                            }}
                                                            placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                            options={depositOptions}
                                                            values={selectedDepositChannel}
                                                            onChange={(value) => vm.handleDepositChannelChange(value)}
                                                            searchable={false}
                                                        />
                                                    ) : (
                                                        <SmallLoading></SmallLoading>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {selectedDepositChannel.length > 0 && (showOnlineBankDetails || showOnlineBankingOnly) && (
                                            <div className='item select-bank'>
                                                {!this.props.screen.isMobile ? (
                                                    <p>
                                                        {' '}
                                                        {customTranslationObj && customTranslationObj.bank
                                                            ? t(customTranslationObj.bank)
                                                            : t('transaction:transaction.withdrawal.bank')}{' '}
                                                    </p>
                                                ) : (
                                                    <div className='label-and-infoIcon-container'>
                                                        <p>
                                                            {' '}
                                                            {customTranslationObj && customTranslationObj.bank
                                                                ? t(customTranslationObj.bank)
                                                                : t('transaction:transaction.withdrawal.bank')}{' '}
                                                        </p>
                                                        <div className='important-notice-container' onClick={() => vm.onImportantNoticeIconClick()}>
                                                            <ImageHelper displaySVG={'info-icon'} className={`info-icon`} svgType='SVGElements' isSVG={true} />
                                                        </div>

                                                        {isShowNoticeBox && (
                                                            <div className={`important-notice-box`}>
                                                                <div>
                                                                    {t(
                                                                        'transaction:transaction.deposit.bank-select-notify',
                                                                        'Add or select a bank account you wish to transfer the money from.'
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div className='select-member-bank'>
                                                    <Select
                                                        key='bankId'
                                                        valueField='bankId'
                                                        labelField='displayName'
                                                        placeholder={t('bank:bank.newBank.selectABank')}
                                                        options={memberBankList}
                                                        values={selectedBank}
                                                        onChange={(value) => vm.handleBankChange(value)}
                                                        searchable={false}
                                                    />

                                                    {!hideAddBankAccount && <AddBankDetailButton onClick={() => this.setState({ isOpenBankModal: true })} />}
                                                </div>

                                                {!this.props.screen.isMobile && (
                                                    <>
                                                        <div
                                                            className='important-notice-container'
                                                            onMouseEnter={() => vm.onImportantNoticeIconHover(true)}
                                                            onMouseLeave={() => vm.onImportantNoticeIconHover(false)}
                                                        >
                                                            <ImageHelper displaySVG={'info-icon'} className={`info-icon`} svgType='SVGElements' isSVG={true} />
                                                        </div>

                                                        {isShowNoticeBox && (
                                                            <div className={`important-notice-box`}>
                                                                <div>
                                                                    {t(
                                                                        'transaction:transaction.deposit.bank-select-notify',
                                                                        'Add or select a bank account you wish to transfer the money from.'
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        <BankDetailModal
                                            buttonText={t('transaction:transaction.deposit.addMemberBankDetails')}
                                            className='btn-secondary'
                                            isOpen={isOpenBankModal}
                                            closeBankModal={vm.closeBankModal}
                                            accountName={user && user.account && user.account.name}
                                            unOwnedBankList={unOwnedBankList}
                                            callback={vm.loadBankAccountsForDeposit}
                                            reloadDeposit={vm.loadBankAccounts}
                                        ></BankDetailModal>

                                        {showRefId && (
                                            <div className='item'>
                                                <p>{t('transaction:transaction.deposit.cashdeposit.referenceid')}</p>
                                                <div className='deposit-amount'>
                                                    <input
                                                        name='referenceCode'
                                                        className={`form-control-inner`}
                                                        id='reference'
                                                        placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid')}
                                                        value={referenceCode}
                                                        onChange={(value) => vm.handlereferenceCodeChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div className='item'>
                                            <p></p>
                                            <div className='deposit-amount'>
                                                {receiptImg.length > 0 && <LazyLoadImage src={`${receiptImg}`} className='w-100' alt='receipt' />}
                                            </div>
                                        </div>

                                        <span className='tnc-apply mb-only' onClick={() => vm.getTncReferral()}>
                                            * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                        </span>

                                        <div className='item button-row'>
                                            <p></p>
                                            <div className='online-transfer-button'>
                                                {uploadReceipt && (
                                                    <label className='btn-normal btn-receipt'>
                                                        {t('transaction:transaction.deposit.cashdeposit.upload').toLocaleLowerCase()}

                                                        <input
                                                            style={{ display: 'none' }}
                                                            name='receiptImg'
                                                            className={`form-control w-100`}
                                                            id='receiptImg'
                                                            type='file'
                                                            accept='image/*'
                                                            placeholder='receiptImg'
                                                            onChange={(event) => vm.handleReceiptImageChange(event)}
                                                        />
                                                    </label>
                                                )}
                                                <button
                                                    type='submit'
                                                    className='btn-normal btn-deposit'
                                                    disabled={disabledDepositButton}
                                                    onClick={() => vm.handleSubmit('online')}
                                                >
                                                    {t('transaction:transaction.deposit.onlinetransfer.depositbtn').toLowerCase()}
                                                </button>
                                            </div>
                                        </div>

                                        <div className='item note'>
                                            <p></p>
                                            <div className='invalid-feedback important-notice'>
                                                {screen.isMobile && (
                                                    <div>
                                                        <i className='icon-tip'></i>
                                                    </div>
                                                )}
                                                <div>{t(customTranslationObj?.receiptNote) || t('transaction:transaction.deposit.onlinetransfer.receiptNote')}</div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <p></p>
                                            <div>
                                                {status && (
                                                    <div className={`invalid-feedback ${status === 'F' ? 'text-danger' : 'text-success'}`}>
                                                        <Trans i18nKey={message}></Trans>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </li>

                            {!screen.isMobile && <TransactionNote {...transactionNoteProps} />}
                        </ul>

                        {getTncReferral && (
                            <li className='messaging-popup mobile-notice'>
                                <div className='popup notice-box'>
                                    <div className='popup-header'>
                                        <div className='popup-header-left'>
                                            <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                        </div>
                                        <div className='popup-header-right'>
                                            <SVGElements name='close-icon' onClick={() => vm.getTncReferral()} />
                                        </div>
                                    </div>

                                    <TransactionNote {...transactionNoteProps} />
                                </div>
                                <div className='popup-overlay notice-overlay' onClick={() => vm.getTncReferral()}></div>
                            </li>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(OnlineTransfer)));

export const AddBankDetailButton = ({ onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT?.BANK_DETAIL_BANK, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <button className='btn-addnewbank' onClick={onAddBtnClick}>
            <IoIosAdd />
        </button>
    );
};
