import 'rc-datetime-picker/dist/picker.css';
import React, { Component } from 'react';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../../redux/selector/selector';
import Select from 'react-dropdown-select';

import { Nav, NavLink, NavItem } from 'reactstrap';
import classnames from 'classnames';
import SmallLoading from '../../../components/Loading/SmallLoading';
import SVGElements from 'root/components/SVGElements';

import vm from './bank-qr.controller';
import TransactionNote from '../../Share/transaction-note/component/transaction-note';
import { gu_togglePopup } from '../../../utils/general-util';
import PaymentGatewayIcon from '../../../components/Molecules/payment-icon';
import BankIcon from '../../../components/Molecules/bank-icon';

class BankQr extends Component {
    isMount = true;
    props = null;

    constructor(props) {
        super(props);
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        if (this.state.depositAllSettings !== null) {
            vm.initCompSettings().then(() => {
                vm.getBankQRChannels().then(() => {
                    vm.getCorrectDepositMinMaxLimit();
                    vm.getSupportedBanks(true);
                });
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    render() {
        const { isLoading, channels, selectedChannel, banks, selectedBank, depositMinMaxLimit, fields, limitAmount, errors, popupCollection } = this.state;
        const { screen, language, user, paymentGatewaySetting } = this.props;
        const { country } = language;

        let transactionNoteProps = {
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
        };

        return (
            <Translation>
                {(t) => (
                    <div className='bqr'>
                        {!isLoading && screen.isMobile && (
                            <div className='item deposit-channel'>
                                <div className='title-item'>
                                    <span> {t('transaction:transaction.deposit.cashdeposit.channel')}</span>
                                    <span className='text-danger asterisk'>*</span>
                                </div>
                                <div className='deposit-amount'>
                                    {channels.length > 0 ? (
                                        <Select
                                            placeholder={t('settings:settings.dropdown.pleaseselect')}
                                            options={channels}
                                            values={[selectedChannel]} // react select need to be array
                                            labelField={t('displayName')}
                                            valueField='channel'
                                            key='channel'
                                            onChange={(value) => vm.onChannelChange(value)}
                                            searchable={false}
                                        />
                                    ) : (
                                        <SmallLoading></SmallLoading>
                                    )}
                                </div>
                            </div>
                        )}

                        <ul className='small-banklist'>
                            <li>
                                <div className='item'>
                                    {!isLoading && !screen.isMobile && (
                                        <p className='deposit-options'>
                                            {t('transaction:transaction.deposit.cashdeposit.depositChannel', 'Deposit Channel')}
                                            <span className='text-danger asterisk'>*</span>
                                        </p>
                                    )}
                                    <div className='bank-selector'>
                                        {!isLoading && !screen.isMobile && (
                                            <Nav tabs className='bqr-deposit-channel-tab'>
                                                <div className='display-flex'>
                                                    {channels.map((channel, index) => {
                                                        const isActive = selectedChannel.code === channel.code;
                                                        return (
                                                            <NavItem
                                                                key={index}
                                                                className='deposit-tab-click'
                                                                onClick={() => {
                                                                    vm.onChannelChange(channel);
                                                                }}
                                                            >
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: isActive,
                                                                        navlinkForm: true,
                                                                        depositLink: true,
                                                                    })}
                                                                >
                                                                    <div className='deposit-banktransfer'>
                                                                        <div className={isActive ? 'active' : ''}>
                                                                            <PaymentGatewayIcon pgCode={channel.code} isActive={isActive} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='deposit-options-text'>{channel.displayName}</div>
                                                                </NavLink>
                                                            </NavItem>
                                                        );
                                                    })}
                                                </div>
                                            </Nav>
                                        )}

                                        {!isLoading && (
                                            <ul className='bank-list'>
                                                {banks.map((bank, index) => {
                                                    const selectedBankCode = selectedBank && selectedBank.bankCode ? selectedBank.bankCode : selectedBank.code;
                                                    const bankCode = bank && bank.bankCode ? bank.bankCode : bank.code;
                                                    const isActive = selectedBankCode === bankCode;
                                                    return (
                                                        <li className='small-bank' key={index}>
                                                            <div className='banklist-group' onClick={() => vm.onBankChange(bank)}>
                                                                <input type='radio' name='bank-account' onChange={() => vm.onBankChange(bank)} checked={isActive} />
                                                                <span className='checkmark'></span>
                                                                <div className={`bank-img bank-${isActive ? 'on' : 'off'}`}>
                                                                    <div>
                                                                        <BankIcon bankCode={bankCode} isActive={isActive} />
                                                                    </div>
                                                                </div>

                                                                <div className={`bank-name ${isActive ? 'bank-selected' : ''}`}>{bank.displayName}</div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </li>

                            {!screen.isMobile && <TransactionNote {...transactionNoteProps} />}
                        </ul>

                        {selectedBank ? (
                            <ul className='deposit-bottom' id='deposit-bottom'>
                                {!paymentGatewaySetting ||
                                    (paymentGatewaySetting && !paymentGatewaySetting.amountFieldNotRequired.includes(selectedChannel.code) && (
                                        <>
                                            <li>
                                                <div className='item'>
                                                    <p className='deposit-options'>
                                                        {t('transaction:transaction.deposit.onlinetransfer.amount')} <span className='text-danger asterisk'>*</span>
                                                    </p>
                                                    <div className='deposit-amounts'>
                                                        {depositMinMaxLimit ? (
                                                            <input
                                                                name='amount'
                                                                className={`form-control-inner`}
                                                                id='amount'
                                                                min='0'
                                                                value={fields['amount']}
                                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit.min),
                                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit.max),
                                                                })}
                                                                onChange={(e) => vm.handleAmountChange(e.target.value)}
                                                                onBlur={() => vm.validateDepositAmount()}
                                                            />
                                                        ) : (
                                                            <SmallLoading></SmallLoading>
                                                        )}

                                                        {country === 'VN' && (
                                                            <div className='invalid-feedback important-notice'>
                                                                <span>1 = 1000 đồng</span>
                                                            </div>
                                                        )}

                                                        {errors && errors['amount'] && (
                                                            <div className='invalid-feedback important-notice'>
                                                                {screen.isMobile && <i className='icon-tip'></i>}
                                                                <Trans i18nKey={errors['amount']}></Trans> {`${user.account.currency} ${limitAmount}`}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='item amount-select'>
                                                    <p className='deposit-options'></p>
                                                    <div className='amount-select-btn'>
                                                        <button className='btn-amount' onClick={() => vm.handleAmountChange(100)}>
                                                            100
                                                        </button>
                                                        <button className='btn-amount' onClick={() => vm.handleAmountChange(500)}>
                                                            500
                                                        </button>
                                                        <button className='btn-amount' onClick={() => vm.handleAmountChange(1000)}>
                                                            1000
                                                        </button>
                                                        <button className='btn-amount' onClick={() => vm.handleAmountChange(2000)}>
                                                            2000
                                                        </button>
                                                        <button className='btn-amount' onClick={() => vm.handleAmountChange(5000)}>
                                                            5000
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ))}

                                <li>
                                    <div className='item'>
                                        <p className='deposit-options'></p>
                                        <div className='deposit-p2p-button'>
                                            <button type='submit' className='btn-normal btn-deposit' disabled={vm.isSubmitBtnDisabled()} onClick={() => vm.handleSubmit()}>
                                                <span>{t('transaction:transaction.deposit.onlinetransfer.depositbtn')}</span>
                                            </button>
                                            {screen.isMobile && (
                                                <span className='tnc-apply mb-only' onClick={() => gu_togglePopup(this, 'tnc')}>
                                                    * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </li>

                                {popupCollection && popupCollection['tnc'] && (
                                    <li className='messaging-popup mobile-notice'>
                                        <div className='popup notice-box'>
                                            <div className='popup-header'>
                                                <div className='popup-header-left'>
                                                    <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                                </div>

                                                <div className='popup-header-right'>
                                                    <SVGElements name='close-icon' onClick={() => gu_togglePopup(this, 'tnc')} />
                                                </div>
                                            </div>

                                            <TransactionNote {...transactionNoteProps} />
                                        </div>
                                        <div className='popup-overlay notice-overlay' onClick={() => gu_togglePopup(this, 'tnc')}></div>
                                    </li>
                                )}
                            </ul>
                        ) : null}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(BankQr)));
