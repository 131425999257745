import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { mapStateToProps } from '../../../redux/selector/selector';
import BankDetail from './BankDetails';
import ChangeGamePassword from './ChangeGamePass';
import ChangePassword from './ChangePass';
import Deposit from './Deposit';
import History from './History';
import Rebate from './instant-rebate/component/Rebate';
import MemberHeader from './MemberHeader';
import Messaging from './Messaging';
import Profile from './Profile';
import Referral from './Referral';
import Transfer from './Transfer';
import Withdraw from './Withdraw';
import Moment from 'moment';
import SVGElements from 'root/components/SVGElements';
import HomeAnnoucementModal from '../../01Home/HomeAnnoucementModal';
import Select from 'react-dropdown-select';
// import PhoneVerificationDialog from '../shared/dialog/phone-verification-dialog/phone-verification-dialog';
import SmsVerifyModal from '../../../components/SmsVerifyModal';
import EmailVerificationDialog from '../shared/dialog/email-verification-dialog/component/email-verification-dialog';
import ImageHelper from 'root/pages/Share/image-helper';
import SelectDropdown from 'root/pages/Share/select-dropdown/select-dropdown';
import Option from 'root/pages/Share/select-dropdown/sub-component/option';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';

import commonUtil from 'root/utils/common-util';
import { mu_getContactVerification, mu_getContactVerificationStatus, mu_getMemberVerifyOptions } from '../../../utils/member-util';
import { gu_getContentByCurrencyLanguage, gu_togglePopup } from '../../../utils/general-util';
import { userVerificationData } from '../161_MyAccount_Items_Data';
import { MODULE_TYPE } from '@utils/constants';

const bankRoutes = [
    { path: '/myaccount/deposit', key: 'deposit', name: 'global:global.menu.account.deposit', Component: Deposit },
    { path: '/myaccount/transfer', key: 'transfer', name: 'global:global.menu.account.transfer', Component: Transfer },
    { path: '/myaccount/withdraw', key: 'withdraw', name: 'global:global.menu.account.withdraw', Component: Withdraw },
    { path: '/myaccount/report', key: 'report', name: 'global:global.sidebar.funds.history', Component: History },
];

const userRoutes = [
    { path: '/myaccount/messaging', key: 'messaging', name: 'settings:settings.tabs.heading.messaging', Component: Messaging },
    { path: '/myaccount/myprofile', key: 'myprofile', name: 'settings:settings.profiletab.myprofile', Component: Profile },
    {
        path: '/myaccount/changepassword',
        key: 'changepassword',
        name: 'settings:settings.profiletab.changepass',
        Component: ChangePassword,
    },
    {
        path: '/myaccount/changegamepassword',
        key: 'changegamepassword',
        name: 'settings:settings.profiletab.changeproviderpass',
        Component: ChangeGamePassword,
    },
    { path: '/myaccount/bankdetail', key: 'bankdetail', name: 'settings:settings.profiletab.bank', Component: BankDetail },
    { path: '/myaccount/referral', key: 'referral', name: 'settings:settings.profiletab.referral', Component: Referral },
];

class Member extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        const mobile = this.initMobileData();
        const isMobile = mobile.device && !mobile.tablet;

        if (this.props.screen.viewType === 'mobile') {
            this.props.history.push('/account/deposit');
            setTimeout(() => {
                document.location.reload();
            }, 100);
            return;
        }

        const current = [...bankRoutes, ...userRoutes].find((it) => it.key == this.props.location.pathname.replace('/myaccount/', ''));

        this.state = {
            route: current,
            AnnoucementList: [],
            modall: false,
            dontShow: false,
            enableReferralProgram: false,
            progressBarPercentage: 0,
            memberGroupLevelMessages: '',
            userGroupImage: '',
            userReviewDate: '',
            isNotification: false,
            memberVerifyOptions: [],
            filteredVerifyOptions: [],
            selectedVerifyType: null,
            smsVerify: false,
            upgradeGroupName: '',
            compSettings: [],
            userRoutes,
            msgType: 'P',
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.isMount = true;
        commonUtil.loadCustomTranslation('accountGroupName', this);
        const current = [...bankRoutes, ...this.state.userRoutes].find((it) => it.key == this.props.location.pathname.replace('/myaccount/', ''));
        this.initCompSettings().then(() => {
            this.handleLink(current);
            this.checkInstantRebateShow();
            // this.getAnnoucementHome();
            this.getReferralEnabled();
            this.getProgressBarData();
            this.checkVerification();
            this.checkContactVerificationStatus();
            this.checkHideMenu();
        });

        if (this.props.location.state && this.props.location.state.isNotification) {
            this.setState({ isNotification: this.props.location.state.isNotification });
        }

        if (this.props.location.state && this.props.location.state.msgType) {
            this.setState({ msgType: this.props.location.state.msgType });
        }
    }

    checkHideMenu() {
        const { settingsConfiguration } = this.state;
        const hideReferral = !!gu_getContentByCurrencyLanguage(this, settingsConfiguration?.hideReferral);
        const userRoutes = this.state.userRoutes.filter((x) => x.key !== 'referral' || !hideReferral);

        this.setState({ userRoutes });
    }

    toggle() {
        this.setState({ modall: false });
        let check = this.state.dontShow;
        let list = this.state.AnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
    }

    getProgressBarData = () => {
        const { account } = this.props.user;
        const { key } = this.props.language;

        if (key) {
            let lang_key = key.toLowerCase();

            window.SPL_Member.loadMemberGroupAutomationSummary(false, account.currency, account.groupName, account.imagePath, lang_key).then((data) => {
                this.setState({
                    progressBarPercentage: data?.progressBarPercentage,
                    memberGroupLevelMessages: data?.memberGroupLevelMessages,
                    userGroupImage: data?.userGroupImage,
                    userReviewDate: data?.firstDayOfMonth,
                    upgradeGroupName: data?.upgradeGroupName,
                });
            });
        }
    };

    getReferralEnabled = () => {
        window.SPL_Referral.getReferralEnabled().then((res) => {
            let enableReferralProgram = res.data;
            if (this.isMount) {
                this.setState({ enableReferralProgram: enableReferralProgram });
            }
        });
    };

    // getAnnoucementHome() {
    //   const { countryLanguageKey } = this.props.language;
    //   const { isLogin } = this.props.user;

    //   let page = ''
    //   if (this.props.location.pathname.includes('/home')) {
    //     page = 'Home'
    //   } else if (this.props.location.pathname.includes('/deposit')) {
    //     page = 'Deposit'
    //   } else if (this.props.location.pathname.includes('/withdraw')) {
    //     page = 'Withdrawal'
    //   }
    //   window.SPL_Content.getAnnoucementList(isLogin, countryLanguageKey, page).then((data) => {
    //     let popUpList = data.announcementsWithPopUpDisplay;
    //     if (this.isMount) {
    //       if (popUpList.length > 0) {
    //         this.setState({ AnnoucementList: popUpList, modall: true });
    //       }
    //     }
    //   });
    // }

    dontShow = (e) => {
        let check = e.target.checked;
        if (check === 'true') {
            this.setState({ dontShow: !this.state.dontShow });
        } else {
            this.setState({ dontShow: !this.state.dontShow });
        }
    };

    checkDontShow() {
        this.setState({ modall: false });
        let check = this.state.dontShow;
        let list = this.state.AnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
        this.setState({ dontShow: false });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const current = [...bankRoutes, ...this.state.userRoutes].find((it) => it.key == this.props.location.pathname.replace('/myaccount/', ''));
            // this.getAnnoucementHome();
            this.state = { route: current };
            this.handleLink(current);
        }

        if (prevProps.language.key !== this.props.language.key) {
            this.getProgressBarData();
        }
    }

    initMobileData() {
        let ua = navigator.userAgent;
        let andriod = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
        let ipadPro = !!/macintosh/.test(ua.toLowerCase()) && 'ontouchend' in document;
        let ios = !!/iphone|ipad|ipod/.test(ua.toLowerCase()) || ipadPro;
        let tablet = /(?:iPad|PlayBook)/.test(ua) || (andriod && !/(?:Mobile)/.test(ua)) || (/(?:Firefox)/.test(ua) && /(?:Tablet)/.test(ua)) || ipadPro;
        let device = andriod || ios || tablet;

        return {
            ios: ios,
            andriod: andriod,
            tablet: tablet,
            device: device,
        };
    }

    handleLink(route) {
        this.setState({
            path: route.path,
        });
        this.props.history.push(route.path);
    }

    checkInstantRebateShow() {
        const { compSettings } = this.state;
        let checkRebate = bankRoutes.filter((e) => e.key === 'rebate');

        if (checkRebate.length === 0) {
            let res = {
                path: '/myaccount/rebate',
                key: 'rebate',
                name: 'global:global.sidebar.funds.rebate',
                Component: Rebate,
            };

            if (compSettings && compSettings.showInstantRebate) {
                bankRoutes.push(res);
            }
        }
    }

    initCompSettings() {
        return new Promise((resolve) => {
            const { user, t } = this.props;

            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'memberSettings',
            });

            let settingsConfiguration = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'settingsConfiguration',
                isCurrencyLang: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let _memberVerifyOptions = mu_getMemberVerifyOptions(this, settingsConfiguration);

            // Step 3: Initial all into compSettings
            let compSettings = {
                showInstantRebate: tempSettings.showInstantRebate,
            };

            this.setState({ compSettings, memberVerifyOptions: _memberVerifyOptions, settingsConfiguration }, () => {
                resolve();
            });
        });
    }

    onVerifyOptionChange(option) {
        if (option) {
            this.setState({ selectedVerifyType: option.key });
        }
    }

    handleVerify() {
        const { history } = this.props;
        const { selectedVerifyType, filteredVerifyOptions } = this.state;
        let defaultVerifyType = filteredVerifyOptions[0] && filteredVerifyOptions[0].key;
        let verifyType = selectedVerifyType ? selectedVerifyType : defaultVerifyType;

        switch (verifyType) {
            case 'birthday':
                history.push('/myaccount/myprofile');
                break;
            case 'email':
                this.togglePopup(verifyType);
                break;
            case 'phone':
                this.getSmsVerifyModal();
                break;
            case 'withdrawal':
                history.push('/myaccount/bankdetail');
                break;
            default:
                break;
        }
    }

    getSmsVerifyModal = () => {
        this.setState({ smsVerify: !this.state.smsVerify });
    };

    checkVerification() {
        mu_getContactVerification(this);
    }

    checkContactVerificationStatus() {
        mu_getContactVerificationStatus(this);
    }

    togglePopup(component) {
        gu_togglePopup(this, component);
    }

    render() {
        const {
            enableReferralProgram,
            memberVerifyOptions,
            filteredVerifyOptions,
            smsVerify,
            userFullyVerified,
            popupCollection,
            selectedVerifyType,
            userReviewDate,
            customTranslationObj,
        } = this.state;
        const { user } = this.props;
        const { verifications } = this.props.user;

        const styles = {
            progressbarWidth: {
                width: this.state.progressBarPercentage + '%',
            },
        };
        const { progressbarWidth } = styles;

        return (
            <Translation>
                {(t) => (
                    <div className='member'>
                        <AuthSettings module={MODULE_TYPE?.BIND_AUTHENTICATOR} isPublic={false} />
                        <AuthSettings module={MODULE_TYPE?.SIX_PIN} isPublic={false} />

                        <div className='content-container'>
                            <div className='content-section member-main'>
                                <MemberHeader />

                                <HomeAnnoucementModal />

                                {smsVerify && (
                                    <SmsVerifyModal
                                        isOpen={smsVerify}
                                        getSmsVerifyModal={this.getSmsVerifyModal.bind(this)}
                                        checkVerification={this.checkVerification.bind(this)}
                                    />
                                )}

                                {popupCollection && popupCollection.email && (
                                    <EmailVerificationDialog
                                        checkEmailStatus={this.state.checkEmailStatus}
                                        isOpen={popupCollection.email}
                                        togglePopup={this.togglePopup.bind(this)}
                                        checkVerification={this.checkVerification.bind(this)}
                                    />
                                )}

                                <div className='main-wallet-content'>
                                    <Router>
                                        <div className='main-wallet-nav'>
                                            <div className='info-box'>
                                                <div className='member-name-level'>
                                                    <LazyLoadImage src={this.state.userGroupImage} className='icon-sapphire' />
                                                    <div>
                                                        <span className='welcome-tip'>{this.props.user.account.login}</span>
                                                        <span>{customTranslationObj?.[user?.account?.groupName?.toUpperCase()] || user.account.groupName}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='line-out'>
                                                        <div className='line-inner' style={progressbarWidth}></div>
                                                        <span>{this.state.progressBarPercentage}%</span>
                                                    </div>
                                                    {this.state.memberGroupLevelMessages && (
                                                        <div
                                                            className='rank-condition'
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(this.state.memberGroupLevelMessages, this.state.memberGroupLevelMessages, {
                                                                    upgradeGroupName:
                                                                        customTranslationObj?.[this.state.upgradeGroupName?.toUpperCase()] || this.state.upgradeGroupName,
                                                                }),
                                                            }}
                                                        />
                                                    )}
                                                    {this.props.user.account.groupName.toUpperCase() !== 'NORMAL' && userReviewDate && userReviewDate !== 'INVALID DATE' && (
                                                        <span className='memberReview-date'>
                                                            {t('member:PortalApp.member.reviewDate', 'Review Date')}: {Moment(userReviewDate).format('DD/MM/YYYY')}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <p>{t('global:global.menu.account.banking', 'Banking')}</p>
                                                <ul>
                                                    {bankRoutes.map((route) => (
                                                        <li key={route.key} className={this.state.path === route.path ? 'on' : ''}>
                                                            <span className='icon-box'>
                                                                <SVGElements className={`icon-${route.key}`} name={route.key + '-icon'} />
                                                                {/* <i className={`icon-${route.key}`}></i> */}
                                                            </span>
                                                            <a onClick={this.handleLink.bind(this, route)}>{t(route.name)}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div>
                                                <p>{t('settings:settings.userProfile', 'User Profile')}</p>
                                                <ul>
                                                    {this.state.userRoutes.map((route) => {
                                                        if (enableReferralProgram && route.key === 'referral') {
                                                            return (
                                                                <li key={route.key} className={this.state.path === route.path ? 'on' : ''}>
                                                                    <span className='icon-box'>
                                                                        <SVGElements className={`icon-${route.key}`} name={route.key + '-icon'} />
                                                                        {/* <i className={`icon-${route.key}`}></i> */}
                                                                    </span>
                                                                    <a onClick={this.handleLink.bind(this, route)}>{t(route.name)}</a>
                                                                </li>
                                                            );
                                                        } else if (route.key !== 'referral') {
                                                            return (
                                                                <li key={route.key} className={this.state.path === route.path ? 'on' : ''}>
                                                                    <span className='icon-box'>
                                                                        <SVGElements className={`icon-${route.key}`} name={route.key + '-icon'} />
                                                                        {/* <i className={`icon-${route.key}`}></i> */}
                                                                    </span>
                                                                    <a onClick={this.handleLink.bind(this, route)}>{t(route.name)}</a>
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            </div>
                                            <div className='st-user-verification-container'>
                                                <p className='container-label'>{t('settings:settings.userVerification', 'User Verification')}</p>
                                                <div className='verification-icons-container'>
                                                    {(memberVerifyOptions || []).map((option, i) => {
                                                        const { key, svg, splKey } = option;
                                                        return (
                                                            <div className='icon-wrapper' key={i}>
                                                                <ImageHelper
                                                                    displaySVG={`${verifications && verifications[splKey] ? `${svg}-ov` : `${svg}`}`}
                                                                    className={`verify-icon ${
                                                                        verifications && verifications[splKey] ? `icon-verify-${key}-ov` : ` icon-verify-${key}`
                                                                    }`}
                                                                    svgType='SVGElements'
                                                                    isSVG={true}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {!userFullyVerified && (
                                                    <div className='verify-action-container'>
                                                        {/* <Select
                                                            className='verify-dropdown-select'
                                                            labelField={t('i18n')}
                                                            valueField='key'
                                                            key='key'
                                                            options={filteredVerifyOptions}
                                                            onChange={(value) => {
                                                                this.onVerifyOptionChange(value[0]);
                                                            }}
                                                            values={filteredVerifyOptions} // selected values, default: []
                                                            searchable={false}
                                                        /> */}
                                                        <div className='standard-form-field verify-field'>
                                                            <SelectDropdown
                                                                name={`verify-dropdow`}
                                                                selectedLabel={
                                                                    selectedVerifyType
                                                                        ? userVerificationData[selectedVerifyType] && userVerificationData[selectedVerifyType].i18n
                                                                        : filteredVerifyOptions[0] && filteredVerifyOptions[0].i18n
                                                                }
                                                            >
                                                                {(filteredVerifyOptions || []).map((a, i) => {
                                                                    const { key, i18n } = a;
                                                                    return (
                                                                        <Option
                                                                            key={i}
                                                                            className={`${key === selectedVerifyType ? 'active' : ''} `}
                                                                            optionLabel={t(i18n)}
                                                                            onClickFunction={() => {
                                                                                this.onVerifyOptionChange(a);
                                                                            }}
                                                                        />
                                                                    );
                                                                })}
                                                            </SelectDropdown>
                                                        </div>

                                                        <button className='standard-button standard-submit-button verify-button' onClick={() => this.handleVerify()}>
                                                            <span>{t('settings:settings.memberVerificationForm.verifyNow', 'Verify Now')}</span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Router>

                                    <div className='main-wallet-main'>
                                        <div className={['main-wallet-main-content', this.state.path ? 'page-' + this.state.path.slice(1) : null].join(' ')}>
                                            {bankRoutes.concat(this.state.userRoutes).map(({ path, Component }) => (
                                                <CSSTransition key={path} in={this.state.path === path} timeout={300} classNames='page' unmountOnExit>
                                                    <Component
                                                        isNotification={this.state.isNotification}
                                                        msgType={this.state.msgType}
                                                        checkVerification={this.checkVerification.bind(this)}
                                                    />
                                                </CSSTransition>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['settings'])(withRouter(Member)));
