import notification from 'root/utils/notification';
import { gu_getNestedValue } from '@utils/general-util';
import { MODULE_TYPE as MT, OTP_CONTACT_TYPE as OCT, OTP_VERIFICATION_METHOD as OVM, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import { ou_otpErrorHandler } from '@utils/otp-util';
import { mu_fetchAuthSettings } from '@utils/member-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            step: 'INPUT_EMAIL', // ['SUCCEED', 'FAILED', 'INPUT_EMAIL, 'ENTER_OTP]
            countdown: 0,
            allowResend: false,
            otpCode: null,
            maskedEmail: '',
            inputFields: {
                email: '',
                newEmail: '',
                otp: '',
            },
            errors: {},
            liveChatUrl: '',
            submitInProgress: false,
            sendOtpCount: 0,
            verifyAttemptCount: null,
        };
    },

    getMemberContact() {
        const { language, screen } = controller.vm.props;
        let countryLang = language.countryLanguageKey.replace('_', '-').toLowerCase();
        window.SPL_Content.getContact(countryLang, screen.viewType).then((data) => {
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].name === 'LiveChat') {
                        controller.vm.setState({ liveChatUrl: data[i].url });
                        break;
                    }
                }
            }
        });
    },

    getMemberEmail() {
        const { enterMemberEmail } = controller.vm.state;
        const { user } = controller.vm.props;
        if (!enterMemberEmail) {
            if (user.account.email) {
                let _maskedEmail = controller.maskEmail(user.account.email);
                controller.vm.setState({ maskedEmail: _maskedEmail });
            }
        }
    },

    checkEmailStatus() {
        const vm = controller.vm;
        const { checkEmailStatus } = vm.props;
        if (checkEmailStatus) {
            controller.goToStep(checkEmailStatus);
        }
    },

    maskEmail(base) {
        let maskEmail = '';
        if (base && base !== '') {
            let strArr = base.split('@');
            let maskedStr = '';

            if (strArr[0].length > 3) {
                maskedStr += controller.repeatStr('*', strArr[0].length - 3);
                maskedStr += strArr[0].substring(strArr[0].length - 3, strArr[0].length);
            } else {
                maskedStr += controller.repeatStr('*', strArr[0].length);
            }

            maskEmail = maskedStr + '@' + strArr[1];
        }
        return maskEmail;
    },

    repeatStr(character, length) {
        var result = '';
        for (var x = 0; x < length; x++) result += character;
        return result;
    },

    goToStep(step) {
        switch (step) {
            case 'ENTER_OTP':
                controller.startResendCountdownTimer();
                break;
            case 'SUCCEED':
            case 'FAILED':
                break;
            default:
                break;
        }
        controller.vm.setState({ step: step });
    },

    startResendCountdownTimer() {
        let vm = controller.vm;
        function onTimer() {
            vm.setState({ countdown: vm.state.countdown - 1 });
            if (vm.state.countdown <= 0) {
                clearInterval(vm.state.resendTimerId);

                vm.setState({ allowResend: true, resendTimerId: null });
            }
        }

        let resendTimerId = setInterval(onTimer, 1000);
        controller.vm.setState({
            allowResend: false,
            countdown: 60,
            resendTimerId: resendTimerId,
        });
    },

    handleFieldChange(inputValue, field) {
        let { inputFields } = controller.vm.state;

        inputFields[field] = inputValue;

        // if (field === 'newEmail') {
        //     inputFields['email'] = inputValue;
        // }

        controller.vm.setState({ inputFields }, () => {
            controller.handleValidation(inputValue, field);
        });
    },

    handleValidation(inputValue, field) {
        const { user } = controller.vm.props;
        const { inputFields } = controller.vm.state;
        let { errors } = controller.vm.state;

        if (field === 'email') {
            errors[field] = '';
            controller.vm.setState({ errors });
        } else if (field === 'newEmail') {
            // field === email is 2nd screen input
            window.SPL_Register.validateEmail(inputValue, window.merchantCode).then((errMsg) => {
                if (inputFields['newEmail'] === inputValue) {
                    controller.handleInputError(errMsg, field);
                } else if (user.account.email === inputValue) {
                    controller.handleInputError('', field);
                } else {
                    if (user.account.email !== inputValue) {
                        errMsg.push('global:global.messages.validate.email.invalid');
                    }
                    controller.handleInputError(errMsg, field);
                }
            });
        }
    },

    handleInputError(errMsg, field) {
        let { errors } = controller.vm.state;

        if (errMsg.length > 0) {
            errors[field] = errMsg[0];
        } else {
            errors[field] = '';
        }
        controller.vm.setState({ errors });
    },

    preSendVerificationCode(field) {
        let { user } = controller.vm.props;
        let { inputFields, errors } = controller.vm.state;
        const emailFormatRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // HQL is not caae sensitive when it comes to values in queries, hence manual convert, because this part can don't need purpose trigger API
        let inputFieldLC = inputFields[field] && inputFields[field].toLowerCase();
        let userEmailLC = gu_getNestedValue(user, 'account.email') && gu_getNestedValue(user, 'account.email').toLowerCase();
        if (!inputFieldLC.match(emailFormatRegex) || inputFieldLC !== userEmailLC) {
            errors[field] = 'global:global.messages.validate.email.invalid';
        } else {
            errors[field] = '';
        }

        controller.vm.setState({ errors }, () => {
            if (!errors[field]) {
                controller.sendVerificationCode();
            }
        });
    },

    async sendVerificationCode(isNewEmail) {
        let { errors } = controller.vm.state;
        const { inputFields, liveChatUrl } = controller.vm.state;
        const { language, systemFeatureReducer, t } = controller.vm.props;
        const msUserVerification = systemFeatureReducer?.systemFeature?.[MT?.USER_VERIFICATION];
        const _isEnableNewOtp = msUserVerification?.email_verification_feature && msUserVerification?.otp_feature;

        let domain = window.location.origin;
        let countryLang = language.countryLanguageKey.replace('_', '-').toLowerCase();
        let inputEmail = isNewEmail ? inputFields['newEmail'] : inputFields['email'];

        controller.vm.setState({ submitInProgress: true });

        const _otpApiParam = {
            contactType: OCT.EMAIL,
            email: inputEmail,
            verificationType: OVT.USER_VERIFICATION,
            verificationMethod: OCT.EMAIL,
            domain: domain,
            livechatUrl: liveChatUrl,
            language: countryLang,
        };

        const _splParams = {
            otpOptions: { otpType: OVT.USER_VERIFICATION, isPublic: false },
            data: _otpApiParam,
        };

        // new flow OTP
        if (_isEnableNewOtp) {
            try {
                const res = await window.SPL_Member.postRequestOTP(_splParams);
                controller.goToStep('ENTER_OTP');
                controller.vm.setState({ verifyAttemptCount: res?.data?.verifyAttempt });
            } catch (err) {
                const _errObj = ou_otpErrorHandler(err);
                const updatedErrors = {
                    ...errors,
                    email: t(`otp:otp.request.message.${_errObj?.key}`, _errObj?.message, { attemptCount: _errObj?.maxAttempt }),
                };
                controller.vm.setState({ errors: updatedErrors });
            } finally {
                controller.vm.setState({ submitInProgress: false });
            }
        } else {
            // exiting flow
            window.SPL_Member.triggerSendVerification('EMAIL', null, null, inputEmail, domain, domain, liveChatUrl, countryLang)
                .then(() => {
                    let stateToUpdate = {
                        submitInProgress: false,
                    };
                    if (isNewEmail && inputFields['newEmail']) {
                        let _maskedEmail = controller.maskEmail(inputFields['newEmail']);
                        stateToUpdate = {
                            ...stateToUpdate,
                            maskedEmail: _maskedEmail,
                        };
                    }
                    controller.vm.setState(stateToUpdate, () => {
                        controller.goToStep('ENTER_OTP');
                        controller.getPhoneAttemptsCount();
                    });
                })
                .catch((error) => {
                    if (error) {
                        const errorMsg = error.message.response.data;

                        let processEmailField = isNewEmail ? 'newEmail' : 'email'; //
                        if (errorMsg) {
                            if (errorMsg.message === 'Email Incorrect') {
                                errors[processEmailField] = 'global:global.verification.differentEmail';
                            } else if (errorMsg.message === 'Maximum Attempts Exceed' || errorMsg?.description === 'Maximum Attempts Exceed') {
                                errors[processEmailField] = 'global:global.verification.maxAttempts';
                            } else if (errorMsg.message === 'transaction.transfer.email.has.been.verified') {
                                errors[processEmailField] = 'global:global.verification.yourEmailVerified';
                            } else {
                                errors[processEmailField] = 'global:global.verification.generalError';
                            }

                            if (processEmailField === 'newEmail') {
                                errors['email'] = '';
                            }

                            let stateToUpdate = {
                                errors: errors,
                                submitInProgress: false,
                            };

                            if (isNewEmail && inputFields['newEmail']) {
                                let _maskedEmail = controller.maskEmail(inputFields['newEmail']);
                                stateToUpdate = {
                                    ...stateToUpdate,
                                    maskedEmail: _maskedEmail,
                                };
                            }

                            controller.vm.setState(stateToUpdate);
                        }
                    }
                });
        }
    },

    validateOtp() {
        const { t } = controller.vm.props;
        const { inputFields } = controller.vm.state;

        controller.vm.setState({ submitInProgress: true });
        window.SPL_Member.validateOtp(null, inputFields['otp'], null, inputFields['email'])
            .then((result) => {
                let { errors, step } = controller.vm.state;
                if (result) {
                    errors['otp'] = '';
                    step = 'SUCCEED';
                } else {
                    errors['otp'] = t('global:global.verification.codeIncorrect', 'OTP Code Incorrect');
                }
                controller.vm.setState({ errors, step, submitInProgress: false });
            })
            .catch(() => {
                notification.showNotification('error', t('global:global.verification.generalError'));
                controller.vm.setState({ submitInProgress: false });
            });
    },

    async submitEmailVerification() {
        const { systemFeatureReducer, t } = controller.vm.props;
        const { inputFields } = controller.vm.state;
        const msUserVerification = systemFeatureReducer?.systemFeature?.[MT?.USER_VERIFICATION];

        const _isEnableNewOtp = msUserVerification?.email_verification_feature && msUserVerification?.otp_feature;

        const _apiParams = {
            ...(!_isEnableNewOtp && {
                email: inputFields['email'],
                otpCode: inputFields.otp,
                method: OVM.EMAIL,
            }),
            ...(_isEnableNewOtp && {
                contactType: OCT.EMAIL,
                contact: inputFields['email'],
                verifyKey: inputFields.otp,
                verificationMethod: OCT.EMAIL,
            }),
        };

        if (_isEnableNewOtp) {
            try {
                const res = await window.SPL_Member.memberUserVerification(_apiParams);
                const { errors, step } = controller.vm.state;
                const updatedErrors = {
                    ...errors,
                    otp: res ? '' : t('global:global.verification.codeIncorrect', 'OTP Code Incorrect'),
                };
                const updatedStep = res ? 'SUCCEED' : step;
                controller.vm.setState({ errors: updatedErrors, step: updatedStep });
                controller.fetchBindAuthenticationSettings();
                // update
            } catch (err) {
                notification.showNotification('error', t('global:global.verification.generalError'));
            } finally {
                controller.vm.setState({ submitInProgress: false });
            }
        } else {
            // old flow, ultimate new flow stable then remove it.
            controller.validateOtp();
        }
    },

    // used to update GA again upon verify success
    fetchBindAuthenticationSettings() {
        const { dispatch, authSettingsReducer } = controller.vm.props;
        const msBindAuthenticatorEnabled = authSettingsReducer?.processedData?.[MT.BIND_AUTHENTICATOR]?.otp_feature;

        if (msBindAuthenticatorEnabled) {
            mu_fetchAuthSettings(dispatch, MT.BIND_AUTHENTICATOR, true);
        }
    },

    getPhoneAttemptsCount() {
        window.SPL_Member.getPhoneAttemptsCount().then((count) => {
            if (count) {
                controller.vm.setState({ sendOtpCount: count });
            }
        });
    },
};

export default controller;
