import { portalConstants } from '../constant';
export const portalAction = {
    setSettings,
    portalIsLoading,
    setIsSubmitting,
    fetchCloudFlareTurnstileSetting,
    clearSiteKey,
    resetSiteKey,
};

function setSettings(settings) {
    return (dispatch) => {
        dispatch({ type: portalConstants.SET_PORTAL_SETTINGS, payload: settings });
    };
}

function portalIsLoading(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.PORTAL_LOADING, payload: { isLoading: flag } });
    };
}

function setIsSubmitting(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.SET_IS_SUBMITTING, payload: { isSubmitting: flag } });
    };
}

function fetchCloudFlareTurnstileSetting() {
    return async (dispatch) => {
        try {
            const response = await window.SPL_Member.getCloudflareTurnstileSetting();
            const data = response?.site_key || '';
            dispatch({ type: portalConstants.CLOUDFLARE_TURNSTILE, payload: { siteKey: data } });
        } catch (error) {
            console.error('Error fetching cloudflare turnstile setting:', error);
        }
    };
}

function clearSiteKey() {
    return (dispatch) => {
        dispatch({ type: portalConstants.CLEAR_SITE_KEY });
    };
}

function resetSiteKey() {
    return (dispatch) => {
        dispatch({ type: portalConstants.RESET_SITE_KEY });
    };
}
