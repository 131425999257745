import React from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import vm from '../../Ewallet.controller';
import Select from 'react-dropdown-select';
import SVGElements from 'root/components/SVGElements';
import SmallLoading from 'root/components/Loading/SmallLoading';

// This component shows the items user checked out from the cart.
class Ewallet extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    vm.init(this);
  }

  componentDidMount() {
	vm.loadTransactionDetails();
	vm.checkFirstMemberBank();
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }


  render() {
    const { account, limit, amountOptions } = this.props;
    const { fields, disabledFields, transactionDetails, filteredBankList, showLoading } = this.state;
    const { key } = this.props.language;
    return (
        <Translation>
            {(t) => (
                <>
                    <div className='withdraw-item-box'>
                        <div className='title-item'>
                            <span>
                                {t('bank:bank.newBank.eWallet', 'Ewallet')} <span className='asterisk'>*</span>
                            </span>
                        </div>
                        <div className='banklist-dropdown'>
                            {showLoading ? (
                                <SmallLoading></SmallLoading>
                            ) : (
                                <Select
                                    className='form-control-inner'
                                    placeholder={t('bank:bank.newBank.selectABank')}
                                    options={filteredBankList}
                                    labelField={t('name')}
                                    valueField='name'
                                    key='name'
                                    values={[this.state.selectedMemberBank]}
                                    onChange={(values) => {
                                        vm.selectBank(null, values);
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div className='withdraw-item-box'>
                        <div className='title-item'>
                            <span>
                                {t('bank:bank.newBank.walletName', 'Wallet Name')} <span className='asterisk'>*</span>
                            </span>
                        </div>
                        <div className='withdraw-accname'>
                            <input name='account name' value={account.name} disabled={true} />
                        </div>
                    </div>

                    <div className='withdraw-item-box'>
                        <div className='title-item'>
                            <span>
                                {t('bank:bank.newBank.walletNumber', 'Wallet No')} <span className='asterisk'>*</span>
                            </span>
                        </div>
                        <div className='withdraw-accno'>
                            <input
                                name='accountnumber'
                                className={`${this.state.accountNumberErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                id='accountnumber'
                                type='number'
                                placeholder='Exp : 1234567'
                                value={fields['accountNumber']}
                                onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                onBlur={vm.validateAccountNumber}
                                disabled={disabledFields['accountNumber']}
                            />
                        </div>
                    </div>

                    <div className='withdraw-item-box'>
                        <div className='title-item'>
                            <span>
                                {t('transaction:transaction.withdrawal.amount')} <span className='asterisk'>*</span>
                            </span>
                        </div>
                        <div className='withdraw-amount'>
                            <input
                                name='amount'
                                className={`form-control-inner ${this.state.amountErrMsg.length > 0 ? 'form-control-inner is-invalid-custom' : ''}`}
                                id='amountInput'
                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                    min: window.SPL_Other.formatAmount(limit.minLimit),
                                    max: window.SPL_Other.formatAmount(limit.maxLimit),
                                })}
                                type='number'
                                min='0'
                                value={fields['amount']}
                                onChange={(e) => vm.handleFieldChange(e, 'amount')}
                                onBlur={vm.validateAmount}
                            />
                        </div>

                        {this.state.amountErrMsg.map((errMsg, index) => {
                            if (errMsg.includes('min_maxAmount')) {
                                return (
                                    <div key={index} className='invalid-feedback text-danger'>
                                        <Trans i18nKey={errMsg}></Trans> {account.currency} {limit.minLimit} - {account.currency} {limit.maxLimit}
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index} className='invalid-feedback text-danger'>
                                        <Trans i18nKey={errMsg}></Trans>
                                    </div>
                                );
                            }
                        })}

                        <div className='withdraw-amount-list'>
                            <p className='deposit-options'></p>
                            <div className='amount-select-btn'>
                                {amountOptions?.map((item) => (
                                    <button className='btn-amount' onClick={() => vm.handleAmountChange(item)}>
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <p className='daily-limit'>
                            <i className='icon-tip'></i>
                            <span>
                                <span>
                                    {t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')} : {window.SPL_Other.formatAmount(limit.dailyMaxAmount)}{' '}
                                    /{' '}
                                </span>
                                <span>
                                    {t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')} : {window.SPL_Other.formatAmount(limit.maxCount)}{' '}
                                </span>
                            </span>
                        </p>
                    </div>

                    {this.state.status !== '' && this.state.status === 'F' ? (
                        <div className='invalid-feedback text-danger m-l-5'>{this.state.message}</div>
                    ) : this.state.status !== '' && this.state.status === 'S' ? (
                        <div className='invalid-feedback text-success  m-l-5'>{this.state.message}</div>
                    ) : (
                        void 0
                    )}

                    <div className='withdraw-item-box'>
                        <div className='withdraw-submit'>
                            <button type='submit' className='btn-normal btn-withdraw' onClick={vm.handleSubmit} disabled={this.state.disabledButton}>
                                <span>{t('transaction:transaction.withdrawal.withdraw')}</span>
                            </button>

                            <span className='tnc-apply' onClick={() => vm.getTncReferral()}>
                                * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                            </span>
                        </div>
                    </div>

                    {this.state.getTncReferral && (
                        <li className='messaging-popup mobile-notice'>
                            <div className='popup notice-box'>
                                <div className='popup-header'>
                                    <div className='popup-header-left'>
                                        <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                    </div>
                                    <div className='popup-header-right'>
                                        <SVGElements name='close-icon' onClick={() => vm.getTncReferral()} />
                                        {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                    </div>
                                </div>

                                {transactionDetails && transactionDetails.withdrawal && transactionDetails.withdrawal[key.toLowerCase()] && (
                                    <div className='popup-body'>
                                        <div className='title'>
                                            <i className='icon-notice-important'></i>
                                            <span>{transactionDetails.withdrawal[key.toLowerCase()].noteTitle}</span>
                                        </div>
                                        <ul>
                                            {(transactionDetails.withdrawal[key.toLowerCase()].stepNote || []).map((step, stepIndex) => (
                                                <li key={step.id}>
                                                    <span>{stepIndex + 1}</span>
                                                    <p>{step.note}</p>
                                                </li>
                                            ))}
                                            <li>
                                                <p>{transactionDetails.withdrawal[key.toLowerCase()].noteText_1}</p>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className='popup-overlay notice-overlay' onClick={() => vm.getTncReferral()}></div>
                        </li>
                    )}
                </>
            )}
        </Translation>
    );
  }
}

export default connect(mapStateToProps, null)(withTranslation([''])(withRouter(Ewallet)));