import React, { Fragment } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { MdClose } from 'react-icons/md';
import core from 'root/utils/core';

import PopupWrapper from '../pages/Share/popup/popup-wrapper/popup-wrapper';
import PhoneOtpMethodSelection from '@components/molecules/phone-otp-method-selection/phone-otp-method-selection';
import { OtpNote } from '../pages/16MyAccount/shared/dialog/email-verification-dialog/component/email-verification-dialog';

import { gu_contactOnClick } from 'root/utils/general-util';
import { pu_getTelCodeFromPhoneNumber } from '../utils/phone-util';
import { MODULE_TYPE as MT, OTP_CONTACT_TYPE as OCT, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import { ou_getPhoneMethods, ou_otpErrorHandler } from '@utils/otp-util';
import { mu_fetchAuthSettings } from '@utils/member-util';
import { regionAction } from 'root/redux/action';

class SmsVerifyModal extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            displayPhone: '',
            step: 'INPUT_PHONE',
            errorMsg: '',
            attemptCount: 0,
            countdown: 0,
            allowResend: false,
            resendTimerId: null,
            otpCode: null,
            codeIncorrect: false,
            liveChatUrl: '',
            phoneVerify: '',
            enterOtp: false,
            sendOtpCount: 0,
            telCode: null,
            phoneVerificationMethods: null,
            preferMethod: null,
            submitInProgress: false,
            fullPhoneVerify: '',
            verifyAttemptCount: 0,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.props.dispatch(regionAction.fetchCountryJson());
        this.getMemberPhone();
        this.getMemberTelCode();
        this.getPhoneVerificationMethods();
        this.intervalId = setInterval(this.contactGet, 2000);
    }

    // componentDidUpdate(prevProps) {
    //   const prevAffUserVerificationData = prevProps?.authSettingsReducer?.processedData?.[MT?.USER_VERIFICATION];
    //   const currentUserVerificationData = this.props?.authSettingsReducer?.processedData?.[MT?.USER_VERIFICATION];
    //   if (prevAffUserVerificationData !== currentUserVerificationData) {
    //       this.initDefaultAuthMethod();
    //   }
    // }

    getPhoneVerificationMethods = () => {
        const _phoneOtpMethods = ou_getPhoneMethods(this.props.portal);
        const _processedPhoneOtpMethods = _phoneOtpMethods.map((method) => {
            return {
                ...method,
                onClick: () => {
                    this.setPreferMethod(method?.type);
                },
            };
        });

        this.setState({ phoneVerificationMethods: _processedPhoneOtpMethods });
    };

    componentWillUnmount() {
        this.isMount = false;
        clearInterval(this.intervalId);
    }

    getMemberPhone = () => {
        let phone = this.props.user.account.phone;
        let displayPhone = this.maskPhone(phone);

        this.setState({ phone: phone, displayPhone: displayPhone });
    };
    getMemberTelCode = async () => {
        const { phone, currency } = this.props.user.account;
        const { rawData } = this.props.regionReducer;

        if (!rawData?.countryJson) {
            await this.props.dispatch(regionAction.fetchCountryJson());
        }

        const updatedRawData = this.props.regionReducer.rawData;
        const telCodeKey = updatedRawData?.countryJson ? Object.keys(updatedRawData.countryJson).find((key) => updatedRawData.countryJson[key].currency === currency) : null;

        const telCode = pu_getTelCodeFromPhoneNumber(phone) || telCodeKey;

        this.setState({ telCode });
    };

    maskPhone = (base) => {
        if (base) {
            if (base.includes('+')) {
                base = base.substring(1, base.length);
            }

            if (base.length > 4) {
                return '+' + this.repeatStr('*', 4) + base.substring(base.length - 4);
            } else {
                return '+' + this.repeatStr('*', 4) + base;
            }
        }

        return base;
    };

    repeatStr = (character, length) => {
        let result = '';

        for (var x = 0; x < length; x++) result += character;

        return result;
    };

    handlePhoneChange = (event) => {
        let phoneNumber = event.target.value;
        let telCode = this.state.telCode;

        this.setState({ phoneVerify: phoneNumber, errorMsg: '', fullPhoneVerify: '+' + telCode + this.state.phoneVerify });
    };

    sendVerificationCode = async () => {
        const { systemFeatureReducer, t } = this.props;
        const _msUserVerification = systemFeatureReducer?.systemFeature?.[MT?.USER_VERIFICATION];
        const _isEnableNewOtp = _msUserVerification?.phone_verification_feature && _msUserVerification?.otp_feature;

        core.portalIsLoading(this.props, true);
        this.setState({ submitInProgress: true });

        const _otpApiParam = {
            contactType: OCT.PHONE,
            phone: this.state.fullPhoneVerify,
            verificationType: OVT.USER_VERIFICATION,
            verificationMethod: this.state.preferMethod,
        };
        const _splParams = {
            otpOptions: { otpType: OVT.USER_VERIFICATION, isPublic: false },
            data: _otpApiParam,
        };

        // new flow OTP
        if (_isEnableNewOtp) {
            try {
                const res = await window.SPL_Member.postRequestOTP(_splParams);
                this.setState({ errorMsg: '', enterOtp: true, otpCode: '', codeIncorrect: false, verifyAttemptCount: res?.data?.verifyAttempt }, () => {
                    this.startResendCountdownTimer();
                });
            } catch (err) {
                const _errObj = ou_otpErrorHandler(err);
                this.setState({ enterOtp: false, errorMsg: t(`otp:otp.request.message.${_errObj?.key}`, _errObj?.message, { attemptCount: _errObj?.maxAttempt }) });
            } finally {
                this.setState({ submitInProgress: false });
                core.portalIsLoading(this.props, false);
            }
        }
    };

    sendOldVerificationCode = () => {
        const { t } = this.props;
        window.SPL_Member.triggerSendVerification('PHONE', this.state.fullPhoneVerify)
            .then(() => {
                // this.goToStep('ENTER_OTP');
                this.setState({ errorMsg: '', enterOtp: true, otpCode: '', codeIncorrect: false }, () => {
                    this.startResendCountdownTimer();
                    this.getPhoneAttemptsCount();
                });
            })
            .catch((error) => {
                if (error) {
                    this.setState({ enterOtp: false });

                    let errorMsg = error.message.response.data;
                    if (this.state.fullPhoneVerify !== this.state.phone) {
                        this.setState({ errorMsg: t('global:global.verification.differentNumber') });
                    } else if (errorMsg.message === 'Maximum Attempts Exceed' || errorMsg?.description === 'Maximum Attempts Exceed') {
                        this.setState({ errorMsg: t('global:global.verification.maxAttempts') });
                    } else if (errorMsg.message === 'transaction.transfer.phone.has.been.verified') {
                        this.props.getSmsVerifyModal();
                    } else {
                        this.setState({ errorMsg: t('global:global.verification.generalError') });
                    }
                }

                // this.goToStep('FAILED');
            })
            .finally(() => {
                this.setState({ submitInProgress: false });
                core.portalIsLoading(this.props, false);
            });
    };

    getPhoneAttemptsCount = () => {
        window.SPL_Member.getPhoneAttemptsCount().then((count) => {
            if (count) {
                this.setState({ sendOtpCount: count });
            }
        });
    };

    goToStep = (step) => {
        switch (step) {
            case 'ENTER_OTP':
                this.startResendCountdownTimer();
                break;
            case 'SUCCEED':
                break;
            case 'FAILED':
                break;
            default:
                break;
        }

        this.setState({ step: step });
    };

    startResendCountdownTimer = () => {
        let resendTimerId = setInterval(() => {
            this.setState({ countdown: this.state.countdown - 1 });
            if (this.state.countdown <= 0) {
                clearInterval(this.state.resendTimerId);
                this.setState({ allowResend: true, resendTimerId: null });
            }
        }, 1000);

        this.setState({ allowResend: false, countdown: 60, resendTimerId: resendTimerId });
    };

    handleOtpChange = (event) => {
        let otpNumber = event.target.value;

        this.setState({ otpCode: otpNumber, codeIncorrect: false });
    };

    submitPhoneVerification = async () => {
        const { systemFeatureReducer, t } = this.props;
        const _msUserVerification = systemFeatureReducer?.systemFeature?.[MT?.USER_VERIFICATION];

        const _isEnableNewOtp = _msUserVerification?.phone_verification_feature && _msUserVerification?.otp_feature;

        const _apiParams = {
            contactType: 'PHONE',
            contact: this.state.fullPhoneVerify,
            verifyKey: this.state.otpCode,
            verificationMethod: this.state.preferMethod,
        };

        if (_isEnableNewOtp) {
            core.portalIsLoading(this.props, true);
            try {
                const res = await window.SPL_Member.memberUserVerification(_apiParams);
                if (res) {
                    this.goToStep('SUCCEED');
                    this.setState({ codeIncorrect: false });
                    this.fetchBindAuthenticationSettings();
                } else {
                    this.setState({ codeIncorrect: true });
                }
            } catch (err) {
                if (err) {
                    this.setState({ codeIncorrect: true });
                }
            } finally {
                this.setState({ submitInProgress: false });
                core.portalIsLoading(this.props, false);
            }
        } else {
            // old flow, ultimate new flow stable then remove it.
            this.validateOtp();
        }
    };

    validateOtp = () => {
        core.portalIsLoading(this.props, true);
        window.SPL_Member.validateOtp(this.state.phone, this.state.otpCode)
            .then((result) => {
                if (result) {
                    this.setState({ codeIncorrect: false });
                    this.goToStep('SUCCEED');
                } else {
                    this.setState({ codeIncorrect: true });
                }

                core.portalIsLoading(this.props, false);
            })
            .catch(() => {
                core.portalIsLoading(this.props, false);
                this.setState({ errorMsg: this.props.t('global:global.verification.generalError') });
                // this.goToStep('FAILED');
            });
    };

    // used to update GA again upon verify success
    fetchBindAuthenticationSettings = () => {
        const { dispatch, authSettingsReducer } = this.props;
        const msBindAuthenticatorEnabled = authSettingsReducer?.processedData?.[MT.BIND_AUTHENTICATOR]?.otp_feature;

        if (msBindAuthenticatorEnabled) {
            mu_fetchAuthSettings(dispatch, MT.BIND_AUTHENTICATOR, true);
        }
    };

    resendOtp = () => {
        this.sendVerificationCode();
    };

    contactGet = () => {
        const { language, screen } = this.props;

        window.SPL_Content.getContact(language.countryLanguageKey, screen.viewType).then((data) => {
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    const contact = data[i];

                    if (contact.name === 'LiveChat') {
                        this.setState({ liveChatUrl: contact.desc });
                    }
                }
            }
        });
    };

    setPreferMethod = (method) => {
        this.setState({ preferMethod: method });
    };

    isPhoneOtpMethodSelectionDisabled = () => {
        const isDisabled = !this.state.phoneVerify ? true : false;
        return isDisabled;
    };

    render() {
        // redux
        const { screen } = this.props;
        // passed props
        const { isOpen, systemFeatureReducer } = this.props;
        const _msUserVerification = systemFeatureReducer?.systemFeature?.[MT?.USER_VERIFICATION];
        const _isEnableNewOtp = _msUserVerification?.otp_feature && _msUserVerification?.phone_verification_feature;
        // passed props function
        const { requestTransfer, checkVerification, getSmsVerifyModal } = this.props;
        const { step, displayPhone, telCode, otpCode, allowResend, countdown, sendOtpCount, enterOtp, errorMsg, codeIncorrect } = this.state;

        // const telCodeOption = (props) => {
        //     return (
        //         <Fragment>
        //             <img src={props.imgTel} alt='flag' />
        //             <span>+{props.telCode}</span>
        //         </Fragment>
        //     );
        // };

        return (
            <Translation>
                {(t) => (
                    <PopupWrapper
                        isOpen={isOpen}
                        overlayClassName={`${screen.isMobile ? 'm-' : ''}sms-verify-popup-overlay`}
                        className={`sms-verify-popup-container`}
                        closeFunc={() => {
                            if (getSmsVerifyModal) {
                                getSmsVerifyModal();
                            }
                            if (step === 'SUCCEED' && requestTransfer) {
                                requestTransfer();
                            }
                            if (step === 'SUCCEED' && checkVerification) {
                                checkVerification();
                            }
                        }}
                    >
                        <div className='standard-popup-modal-header sms-verify-popup-header'>
                            <div className='standard-modal-title'>{t('settings:settings.memberVerificationForm.phoneFormHeader', 'Verify Phone Number')}</div>
                            <div
                                className='standard-modal-close'
                                onClick={() => {
                                    if (getSmsVerifyModal) {
                                        getSmsVerifyModal();
                                    }
                                    if (step === 'SUCCEED' && requestTransfer) {
                                        requestTransfer();
                                    }
                                    if (step === 'SUCCEED' && checkVerification) {
                                        checkVerification();
                                    }
                                }}
                            >
                                <MdClose />
                            </div>
                        </div>
                        <div className='standard-popup-modal-body sms-verify-popup-body'>
                            <div className='popup-body-content'>
                                {step === 'INPUT_PHONE' && (
                                    <Fragment>
                                        <div className='content-wrapper input-phone-content-wrapper'>
                                            <div className='modal-desc'>
                                                {t('global:global.verification.verifyDescription')} {displayPhone}
                                            </div>

                                            <div className='standard-form-field telcode-phone-field'>
                                                <input className='standard-input telcode-input' type='text' readOnly={true} value={`+${telCode}`} />
                                                <input
                                                    className='standard-input phone-input'
                                                    type='number'
                                                    name='phone'
                                                    id='phone'
                                                    autoComplete='off'
                                                    onChange={(e) => this.handlePhoneChange(e)}
                                                    onBlur={(e) => this.handlePhoneChange(e)}
                                                    placeholder={t('settings:settings.memberVerificationForm.mobileNumber', 'Mobile Number')}
                                                    value={this.state.phoneVerify}
                                                    readOnly={enterOtp} // when enterOtp state, input need to be disabled
                                                />
                                            </div>

                                            {!enterOtp && (
                                                <>
                                                    {console.log(this.state.preferMethod)}
                                                    <PhoneOtpMethodSelection
                                                        selectedLabel={this.state.preferMethod || t('otp:otp.pleaseSelect', 'Please Select')}
                                                        phoneVerificationMethods={this.state.phoneVerificationMethods}
                                                        preferMethod={this.state.preferMethod}
                                                        disabled={this.isPhoneOtpMethodSelectionDisabled()}
                                                    />

                                                    <div className='verify-phone-button-container'>
                                                        <button
                                                            className='standard-button standard-modal-submit-button'
                                                            onClick={() => this.sendVerificationCode()}
                                                            disabled={!this.state.phoneVerify || (_isEnableNewOtp && !this.state.preferMethod)}
                                                        >
                                                            <span> {t('settings:settings.memberVerificationForm.submit', 'Submit')}</span>
                                                        </button>
                                                    </div>
                                                </>
                                            )}

                                            {/* Error content when verify phone invalid */}
                                            {errorMsg && (
                                                <Fragment>
                                                    <div className='failed-container'>
                                                        <div className='icon-wrapper'>
                                                            <i className='icon-notice-important'></i>
                                                        </div>
                                                        <div className='standard-error-validation'>{t(errorMsg)}</div>
                                                        <div className='modal-desc'>
                                                            {t('global:global.verification.verifyPhoneContactCS', 'Please contact customer service to verify your number.')}
                                                        </div>
                                                        <div className='contact-button-container'>
                                                            <button className='standard-button contact-button' onClick={() => gu_contactOnClick(this.state.liveChatUrl)}>
                                                                <span>{t('global:global.verification.contactCS', 'Contact Customer Service')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>

                                        <div className='content-wrapper otp-content-wrapper'>
                                            {enterOtp && (
                                                <Fragment>
                                                    <div className='modal-desc'>{t('global:global.verification.enterOTPCodeDescription', { phone: displayPhone })}</div>

                                                    <div className='standard-form-field otp-field'>
                                                        <input
                                                            className='standard-input'
                                                            type='text'
                                                            placeholder={t('settings:settings.memberVerificationForm.enterOTPCode', 'Enter OTP Code')}
                                                            value={otpCode || ''}
                                                            onChange={(e) => this.handleOtpChange(e)}
                                                            onBlur={(e) => this.handleOtpChange(e)}
                                                        />
                                                    </div>

                                                    <div className='validate-resend-otp-button-container'>
                                                        <button
                                                            className='standard-button standard-modal-submit-button'
                                                            disabled={!otpCode}
                                                            onClick={() => this.submitPhoneVerification()}
                                                        >
                                                            <span>{t('global:global.verification.validateOTP', 'Validate OTP')}</span>
                                                        </button>
                                                        <button
                                                            className='standard-button standard-modal-submit-button'
                                                            disabled={!allowResend}
                                                            onClick={() => this.resendOtp()}
                                                        >
                                                            <span>
                                                                {t('global:global.verification.resendOTP', 'Resend OTP')}{' '}
                                                                {countdown > 0 ? <Fragment>{countdown}s</Fragment> : ''}
                                                            </span>
                                                        </button>
                                                    </div>

                                                    <OtpNote verifyAttemptCount={this.state.verifyAttemptCount} sendOtpCount={sendOtpCount} />

                                                    {codeIncorrect && (
                                                        <div className='standard-error-validation'>{t('global:global.verification.codeIncorrect', 'OTP is incorrect')}</div>
                                                    )}
                                                </Fragment>
                                            )}
                                        </div>
                                    </Fragment>
                                )}

                                {step === 'SUCCEED' && (
                                    <div className='content-wrapper success-content-wrapper'>
                                        <div className='standard-modal-title success-title'>{t('global:global.verification.yourNumberVerified')}</div>
                                        <span className='modal-desc'>
                                            <Trans i18nKey='global:global.verification.verifiedSuccessMsg' />
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </PopupWrapper>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(SmsVerifyModal)));
