// rush version =x, so code can be further optimized

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-dropdown-select';

// components
import SVGElements from 'root/components/SVGElements';

// logic / util / constant
import { CRYPTO_ADDRESS_FIELD as CAF, MODULE_TYPE, OTP_VERIFICATION_METHOD, OTP_VERIFICATION_TYPE } from '@constants';
import { useRequestOtp } from '@hooks/otp-hook';
import APIClient from '@utils/APIClient';

const AddCryptoAddressPopup = ({ onRequestClose, onSubmitSuccessCb }) => {
    const { t } = useTranslation(['transaction', 'otp', 'global']);

    // redux
    const language = useSelector((state) => state.language);
    const portal = useSelector((state) => state.portal);
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MODULE_TYPE.CRYPTO_ADDRESS];
    const _addCryptoAddressRequireOTP = _msCryptoAddressModule?.gaEnabled ? false : portal?.settings?._addCryptoAddressRequireOTP;

    // state
    const getInitialFields = () => ({
        [CAF.COIN]: { value: '', isMandatory: true, errMsg: '' },
        [CAF.NETWORK]: { value: '', isMandatory: true, errMsg: '' },
        [CAF.ADDRESS]: { value: '', isMandatory: true, errMsg: '' },
        ...(!_msCryptoAddressModule?.gaEnabled && {
            [CAF.OTP]: { value: '', isMandatory: true, errMsg: '' },
        }),
        ...(_msCryptoAddressModule?.gaEnabled && {
            [CAF.GA]: { value: '', isMandatory: true, errMsg: '' },
        }),
    });

    const [addressData, setAddressData] = useState(null);
    const [addressOption, setAddressOption] = useState([]);
    const [selectedCryptoOptions, setSelectedCryptoOptions] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState([]);
    const [networkAndCoin, setNetworkAndCoin] = useState([]);
    const [networkOption, setNetworkOption] = useState([]);
    const [fields, setFields] = useState(getInitialFields);
    const [changedField, setChangedField] = useState(null);
    const [addAddressErrMsg, setAddAddressErrMsg] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCurrencyAndCoin, setShowCurrencyAndCoin] = useState(false);

    // hooks
    // ===== hook useRequestOtp config =====
    const onRequestOtpSuccess = () => {
        setFields((prevFields) => ({
            ...prevFields,
            [CAF.OTP]: { ...prevFields[CAF.OTP], errMsg: '' },
        }));
    };
    const onRequestOtpFail = (err) => {
        const _errMsg = t(`otp:otp.request.message.${err?.key}`, err?.message, { attemptCount: err?.maxAttempt });

        setFields((prevFields) => ({
            ...prevFields,
            [CAF.OTP]: { ...prevFields[CAF.OTP], errMsg: _errMsg },
        }));
    };
    const { requestOtp, isRequestingOtp, isOtpRequested, otpCountdownTimer } = useRequestOtp({
        arrayParams: ['CRYPTO', null, fields?.[CAF.ADDRESS]?.value],
        onRequestOtpSuccess, // Callback on successful OTP request
        onRequestOtpFail, // Callback on failed OTP request
        isOldFlow: !_addCryptoAddressRequireOTP?.otp_feature,
    });
    // ===== useRequestOtp config end =====

    useEffect(() => {
        loadCryptoWithdraw();
        getMerchantBankGroupWithOption();
    }, []);

    // functions
    const loadCryptoWithdraw = async () => {
        try {
            const data = await window.SPL_Transaction.getCryptWithdrawBank();
            if (data) {
                let _addressData = data.coinAndSupportNetwork,
                    _addressOption = [];

                if (_addressData) {
                    for (let i = 0; i < Object.keys(_addressData).length; i++) {
                        _addressOption.push({
                            displayName: Object.keys(_addressData)[i],
                        });
                    }

                    setAddressData(_addressData);
                    setAddressOption(_addressOption);
                }
            }
        } catch (err) {
            if (err) {
                //
            }
        }
    };

    const getMerchantBankGroupWithOption = async () => {
        let params = {
            purpose: 'W',
            option: 'CT',
        };

        try {
            const data = await window.SPL_Transaction.getMerchantBankGroupWithOption(params);
            if (data && data.channelOptions && data.options) {
                for (let i = 0; i < data.channelOptions.length; i++) {
                    data.channelOptions[i].displayName = t(data.channelOptions[i].displayName);
                }
                setSelectedCryptoOptions([data.channelOptions[0]]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const loadCryptoDisplaySettings = () => {
            window.SPL_Transaction.loadCryptoDisplaySettings(language?.countryLanguageKey).then((data) => {
                if (data) {
                    setShowCurrencyAndCoin(data.showCurrencyAndCoin);
                    setNetworkAndCoin(data.networkAndCoin);
                }
            });
        };

        if (selectedCryptoOptions) {
            loadCryptoDisplaySettings();
        }
    }, [selectedCryptoOptions]);

    const handleCryptoCurrencyChange = (value) => {
        setSelectedCurrency(value);
        setSelectedNetwork([]);
        getNetworks(value);
    };

    const getNetworks = (selectedCurrency) => {
        let networkFilter = '',
            _networkOption = [],
            addressFilter = [];

        if (selectedCurrency && selectedCurrency.length > 0) {
            networkFilter = networkAndCoin?.[selectedCurrency?.[0]?.displayName];
            addressFilter = addressData?.[selectedCurrency?.[0]?.displayName];
            if (addressFilter?.length > 0) {
                for (let j = 0; j < addressFilter.length; j++) {
                    for (let i = 0; i < networkFilter.length; i++) {
                        if (addressFilter[j].indexOf(networkFilter[i]) !== -1) {
                            _networkOption.push({ displayName: networkFilter[i] });
                        }
                    }
                }
            }
        }
        setNetworkOption(_networkOption);
    };

    const handleCryptoNetworkChange = (value) => {
        setSelectedNetwork(value);
    };

    const onInputFieldChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: { ...prevFields?.[name], value: value },
        }));
        setChangedField(name);
    };
    useEffect(() => {
        if (!fields?.[changedField]) {
            return;
        }
        switch (changedField) {
            case CAF.OTP:
                validateOtp();
                break;
            default:
                break;
        }
    }, [changedField, fields?.[changedField]?.value]);

    const validateOtp = () => {
        if (fields?.[CAF.OTP]?.length === 6) {
            window.SPL_Member.validateOtp(null, fields?.[CAF.OTP]?.value, fields?.[CAF.ADDRESS]?.value)
                .then((result) => {
                    if (result) {
                        //
                    } else {
                        if (!fields?.[CAF.OTP]?.errMsg) {
                            setAddAddressErrMsg('global:global.verification.codeIncorrect');
                        }
                    }
                })
                .catch(() => {
                    if (!fields?.[CAF.OTP]?.errMsg) {
                        setAddAddressErrMsg('gglobal:global.verification.generalError');
                    }
                });
        }
    };

    const isOtpButtonDisabled = () => {
        const isDisabled = fields?.[CAF.ADDRESS]?.value || isRequestingOtp || otpCountdownTimer > 0;
        return isDisabled;
    };

    const handleCryptoAddressSubmit = () => {
        setIsSubmitting(true);
        const params = {
            address: fields?.[CAF.ADDRESS]?.value,
            cryptoCoin: selectedCurrency?.[0]?.displayName,
            cryptoNetwork: selectedNetwork?.[0]?.displayName,
            ...(_msCryptoAddressModule?.gaEnabled && {
                verificationType: OTP_VERIFICATION_TYPE.CRYPTO_ADDRESS,
                verificationMethod: OTP_VERIFICATION_METHOD.GA, // future expect preferMethod, currently new MFA UI only will got GA
                verifyKey: fields?.[CAF.GA]?.value, // future expect otp field
            }),
        };

        const onSuccess = () => {
            onSubmitSuccessCb && onSubmitSuccessCb();
            setIsSubmitting(false);
        };

        const onError = (errMsg) => {
            setAddAddressErrMsg(errMsg);
            setIsSubmitting(false);
        };

        APIClient.createMemberCryptoAddress(params, onSuccess, onError);
    };

    const isSubmitBtnDisabled = () => {
        const isGaFieldValid = _msCryptoAddressModule?.gaEnabled ? (fields?.[CAF.GA]?.value ? true : false) : true;
        const isOtpFieldValid = (_addCryptoAddressRequireOTP && fields?.[CAF.OTP]?.value) || (!_addCryptoAddressRequireOTP && !fields?.[CAF.OTP]?.value);

        if (!isSubmitting && selectedCurrency?.length > 0 && selectedNetwork?.length > 0 && fields?.[CAF.ADDRESS]?.value && isOtpFieldValid && isGaFieldValid) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <div className='withdraw'>
                <div className='withdraw-tab-content'>
                    <div className='crypto-deposit'>
                        <li className='messaging-popup form-popup address-popup'>
                            <div className='popup'>
                                <div className='popup-header'>
                                    <div className='popup-header-left'>
                                        <div className='popup-title'>{t('transaction:transaction.addAddress', 'Add Address')}</div>
                                    </div>
                                    <div className='popup-header-right'>
                                        <SVGElements name='close-icon' onClick={onRequestClose} />
                                    </div>
                                </div>

                                <div className='popup-body'>
                                    <div className='name-popup'>
                                        <p>
                                            {showCurrencyAndCoin
                                                ? t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')
                                                : t('transaction:transaction.deposit.crypto.cryptoCoin', 'Coin')}{' '}
                                            <span className='text-danger asterisk'>*</span>
                                        </p>

                                        <div className='popup-field'>
                                            <Select
                                                key='cryptoCurrency'
                                                placeholder={t(
                                                    `${
                                                        showCurrencyAndCoin
                                                            ? 'transaction:transaction.deposit.crypto.selectCryptoCurrency'
                                                            : 'transaction:transaction.deposit.crypto.selectCryptoCoin'
                                                    }`
                                                )}
                                                options={addressOption}
                                                valueField='cryptoCurrency'
                                                labelField='displayName'
                                                values={selectedCurrency}
                                                onChange={(values) => handleCryptoCurrencyChange(values)}
                                                searchable={false}
                                            />
                                        </div>
                                    </div>

                                    <div className='name-popup'>
                                        <p>
                                            {t('transaction:transaction.deposit.crypto.network', 'Network')} <span className='text-danger asterisk'>*</span>
                                        </p>
                                        <div className='popup-field'>
                                            <Select
                                                key='cryptoNetwork'
                                                placeholder={t('transaction:transaction.deposit.crypto.selectNetwork')}
                                                options={networkOption}
                                                valueField='cryptoNetwork'
                                                labelField='displayName'
                                                values={selectedNetwork}
                                                onChange={(values) => handleCryptoNetworkChange(values)}
                                                searchable={false}
                                            />
                                        </div>
                                    </div>

                                    <div className='name-popup'>
                                        <p>
                                            {t('transaction:transaction.address', 'Address')} <span className='text-danger asterisk'>*</span>
                                        </p>
                                        <div className='popup-field address-field'>
                                            <input
                                                className='text-area'
                                                value={fields?.[CAF.ADDRESS]?.value}
                                                name={CAF.ADDRESS}
                                                type='text'
                                                placeholder={t('transaction:transaction.address', 'Address')}
                                                onChange={onInputFieldChange}
                                            />
                                        </div>
                                    </div>

                                    {!_msCryptoAddressModule?.gaEnabled && _addCryptoAddressRequireOTP && (
                                        <div className='name-popup'>
                                            <p>
                                                OTP <span className='text-danger asterisk'>*</span>
                                            </p>
                                            <div className='popup-field otp-field'>
                                                <input
                                                    id='otp'
                                                    name={CAF.OTP}
                                                    type='number'
                                                    autoComplete='off'
                                                    onKeyDown={(evt) => ['e', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                                                    debounceTimeout={1000}
                                                    placeholder={t('global:global.verification.enterOTPCode')}
                                                    value={fields?.[CAF.OTP]?.value}
                                                    onChange={onInputFieldChange}
                                                />
                                                <button onClick={requestOtp} disabled={isOtpButtonDisabled()}>
                                                    {isOtpRequested === true ? (
                                                        otpCountdownTimer > 0 ? (
                                                            <span>{otpCountdownTimer}s</span>
                                                        ) : (
                                                            <span>{t('global:global.verification.resend')}</span>
                                                        )
                                                    ) : (
                                                        <span>{t('register:register.form.getCode')}</span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {_msCryptoAddressModule?.gaEnabled && (
                                        <div className='name-popup'>
                                            <p>
                                                {t('googleAuthenticator:googleAuthenticator.field.gaCode.label', 'Google Authentication Code')}{' '}
                                                <span className='text-danger asterisk'>*</span>
                                            </p>
                                            <div className='popup-field address-field'>
                                                <input className='text-area' value={fields?.[CAF.GA]?.value} name={CAF.GA} type='text' onChange={onInputFieldChange} />
                                            </div>
                                        </div>
                                    )}

                                    {addAddressErrMsg && (
                                        <div className='name-popup'>
                                            <p></p>
                                            <div className='popup-field address-field'>
                                                <p className={`text-red refill-box crypto-address-alert ${fields?.[CAF.OTP]?.errMsg && 'otp-errMsg'}`}>
                                                    {t(addAddressErrMsg)}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='popup-footer'>
                                    <button onClick={handleCryptoAddressSubmit} disabled={isSubmitBtnDisabled()} className='btn-normal btn-submit-popup'>
                                        {t('transaction:transaction.deposit.localPay.submit', 'Submit')}
                                    </button>
                                </div>
                            </div>
                            <div className='popup-overlay notice-overlay' onClick={onRequestClose}></div>
                        </li>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddCryptoAddressPopup;
