import commonUtil from '../../utils/common-util';
import { trans_handlePGDepositCountDown, trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { gu_getNestedValue } from 'root/utils/general-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { scrollToDepositStep2 } from './deposit-util';

// used for 089_P2P.js
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;

        controller.vm.state = {
            isLoading: true,
            methodList: [],
            bankList: [],
            selectedMethod: '',
            selectedBank: null,
            amount: '',
            limitAmount: '',
            amountErrMsg: [],
            getTncReferral: false,
            disabledDepositButton: true,

            depositAllSettings: controller.vm.props.depositAllSettings,
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositMinMaxLimit: null,

            compSettings: {},
            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            depositSubmitCountdown: null,
            submitRequiredBank: true,
            depositAmountOptions: trans_getDepositAmountOptionByMethod(controller.vm.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.P2P),
        };
    },

    initCompSettings() {
        const { filteredDepositApiJson } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            // let tempDepositSettings = depositAllSettings;

            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'p2pTransferSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // let depositNotice = null;
            // if (
            //     tempDepositSettings &&
            //     tempDepositSettings.depositJson &&
            //     tempDepositSettings.depositJson.quickPay &&
            //     tempDepositSettings.depositJson.quickPay.custom_article
            // ) {
            //     depositNotice = tempDepositSettings.depositJson.quickPay.custom_article;
            // }

            let compSettings = {
                //depositNotice,
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                showOnlineTransactionMode: tempSettings.showOnlineTransactionMode,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                customCountdown: gu_getNestedValue(this.props, 'parentvm.state.compSettings.depositPagesSettings.customCountdown'),
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(controller.vm, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'p2p' : '';
        commonUtil.readCustomDepositArticle(controller.vm, customFilename);
    },

    async loadP2Pbank() {
        const { filteredDepositApiJson } = controller.vm.state;
        // let _bankList = [];
        let _methodList = [];
        let _selectedMethod = null;
        let _selectedBank = null;
        // if enableJsonAPi true from deposit-landing
        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            // For loop the channel/method list
            // from api only got method, hence [0] is method data le.
            for (let method in filteredDepositApiJson.channel) {
                let channel = filteredDepositApiJson.channel[method][0];

                window.SPL_Content.getBankName(channel, 'channel');
                _methodList.push(channel);
            }
            if (_methodList.length > 0) {
                // _bankList = Object.values(filteredDepositApiJson.channel)[0];
                _selectedMethod = _methodList[0];
                // _selectedBank = _bankList[0];
            }
            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            controller.vm.setState(
                {
                    methodList: _methodList,
                    // bankList: _bankList,
                    selectedMethod: _selectedMethod,
                    selectedBank: disablePreselectBank ? null : _selectedBank,
                    isLoading: false,
                },
                () => {
                    controller.getCorrectDepositMinMaxLimit();
                    controller.getMemberThirdPartyBank(true);
                }
            );
        }
    },

    getMemberThirdPartyBank(isInit) {
        const { user, paymentGatewaySetting } = controller.vm.props;
        const { currency } = user.account;
        const { selectedMethod } = controller.vm.state;

        if (paymentGatewaySetting && selectedMethod) {
            const { memberBankNotRequired, memberBankNotRequiredByCurrencies } = paymentGatewaySetting;
            if (
                memberBankNotRequiredByCurrencies &&
                memberBankNotRequiredByCurrencies[currency] &&
                memberBankNotRequiredByCurrencies[currency].includes(selectedMethod.code)
            ) {
                controller.vm.setState({ submitRequiredBank: false });
            } else {
                if (memberBankNotRequired && !memberBankNotRequired.includes(selectedMethod.code)) {
                    window.SPL_Transaction.getMemberThirdPartyBank(selectedMethod.id, controller.vm.props).then((data) => {
                        window.SPL_Content.getBankName(data, 'bank');
                        let stateToUpdate = {
                            bankList: data,
                            submitRequiredBank: true,
                        };
                        if (isInit) {
                            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                            stateToUpdate = {
                                ...stateToUpdate,
                                selectedBank: disablePreselectBank ? null : data && data[0],
                            };
                        }

                        controller.vm.setState(stateToUpdate);
                    });
                } else {
                    controller.vm.setState({ submitRequiredBank: false });
                }
            }
        }
    },

    // getCorrectDepositMinMaxLimit() {
    //     const { depositAllSettings, bankList, selectedBank } = controller.vm.state;
    //     const currency = controller.vm.props.user.account.currency;
    //     let _depositMinMaxLimit = { min: 0, max: 0 };

    //     if (bankList.length > 0 && depositAllSettings.depositJson.quickPay[selectedBank.code]) {
    //         _depositMinMaxLimit = depositAllSettings.depositJson.quickPay[selectedBank.code][currency];
    //     }

    //     // after get new minmax, validate the amount again
    //     controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit }, () => {
    //         controller.validateDepositAmount();
    //     });
    // },

    getCorrectDepositMinMaxLimit() {
        commonUtil.getDepositMinMax(
            controller.vm,
            'P2P',
            controller.vm.props.user.account.currency,
            controller.vm.state.selectedMethod.code,
            null,
            null,
            controller.validateDepositAmount
        );
    },

    handleMethodChanged(method) {
        const { filteredDepositApiJson } = controller.vm.state;
        const { screen } = controller.vm.props;
        // mobile's select Tag make it become array
        method = screen.isMobile ? method[0] : method;

        if (controller.vm.state.selectedMethod !== method) {
            let methodData = filteredDepositApiJson.channel;
            let newBankList = methodData && methodData[method.code] ? methodData[method.code] : [];
            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            controller.vm.setState(
                {
                    selectedMethod: method,
                    bankList: newBankList,
                    selectedBank: disablePreselectBank ? null : newBankList[0],
                },
                () => {
                    controller.getCorrectDepositMinMaxLimit();
                    controller.getMemberThirdPartyBank();
                    controller.checkError();
                }
            );
        }
    },

    handleBankChange(bank) {
        if (bank !== controller.vm.state.selectedBank) {
            const oldSelectedBank = controller.vm.state.selectedBank;
            controller.vm.setState(
                {
                    selectedBank: bank,
                    amount: '',
                },
                () => {
                    const {
                        enableScrollToDepositStep2 = false,
                        scrollToDepositStep2Attempts = 15,
                        scrollToDepositStep2Delay = 200,
                    } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

                    scrollToDepositStep2(enableScrollToDepositStep2, oldSelectedBank, bank, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);

                    // controller.getCorrectDepositMinMaxLimit(); // minmax based on method or called as channel
                    controller.checkError();
                }
            );
        }
    },

    handleAmountChange(value) {
        if (value) {
            let regex = '';
            // if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.P2P]) {
            //     regex = /^[0-9]+$/;
            // } else {
            regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            // }
            if (value.toString().match(regex)) {
                controller.vm.setState({ amount: value }, () => {
                    controller.validateDepositAmount();
                });
            }
        } else {
            controller.vm.setState({ amount: '' }, () => {
                controller.validateDepositAmount();
            });
        }
    },

    validateDepositAmount() {
        const { amount, depositMinMaxLimit } = controller.vm.state;
        const depositLimitObj = { minLimit: depositMinMaxLimit && depositMinMaxLimit.min, maxLimit: depositMinMaxLimit && depositMinMaxLimit.max };
        window.SPL_Transaction.validateDepositAmount(amount, depositLimitObj)
            .then((errMsg) => {
                if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.P2P] && !this.state.amount.toString().match(/^[0-9]+$/)) {
                    const newErrMsg = window.SPL_Other.deepCopy(errMsg);
                    newErrMsg.push('transaction:transaction.withdrawal.disableDecimalError');
                    return newErrMsg;
                }

                return errMsg;
            })
            .then((errMsg) => {
                let errMsgLimitAmount = controller.getLimitAmountForErrMsg(errMsg[0]);
                controller.vm.setState({ amountErrMsg: errMsg, limitAmount: errMsgLimitAmount.limitAmount }, () => {
                    controller.checkError();
                });
            });
    },

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                limitAmount = window.SPL_Other.formatAmount(controller.vm.state.depositMinMaxLimit.min);
            } else if (errMsg.includes('invalidMaxAmount')) {
                limitAmount = window.SPL_Other.formatAmount(controller.vm.state.depositMinMaxLimit.max);
            }
        }

        return {
            limitAmount: limitAmount,
        };
    },

    checkError() {
        const { amount, amountErrMsg, selectedBank, submitRequiredBank } = controller.vm.state;
        if (!amount || amountErrMsg.length > 0 || (submitRequiredBank && !selectedBank)) {
            controller.vm.setState({ disabledDepositButton: true });
        } else {
            controller.vm.setState({ disabledDepositButton: false });
        }
    },

    disableDot(e) {
        if (e.keyCode == '46' || e.charCode == '46') {
            e.preventDefault();
        }
    },

    getTncReferral() {
        controller.vm.setState({ getTncReferral: !controller.vm.state.getTncReferral });
    },

    handleSubmit() {
        const vm = controller.vm;
        const { amountErrMsg, selectedBank, amount, selectedMethod, filteredDepositApiJson, depositSubmitCountdown, compSettings } = controller.vm.state;
        const { language, depositSubmission, t } = controller.vm.props;
        const { features } = controller.vm.props.portal.settings;
        const { customCountdown } = compSettings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            if (amountErrMsg.length > 0 || !selectedMethod) {
                return;
            }

            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }

            let stateUrl = '';
            const langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
            }

            const methodParam = {
                id: selectedBank && selectedBank.id,
                code: selectedBank ? selectedBank.code : null,
            };
            const callbackUrl = `/${langPath}/myaccount/deposit`;

            const customParam = {
                amount,
                merchantBankId: selectedMethod && selectedMethod.id,
                merchantCode: window.merchantCode,
                methodCode: selectedMethod && selectedMethod.code,
                name: selectedMethod && selectedMethod.name,
                portalLanguage: language.countryLanguageKey,
                preferredWallet: null,
                returnUrl: callbackUrl,
            };

            window.SPL_Transaction.requestDepositOnlineTransfer(
                methodParam,
                methodParam.code,
                amount,
                null,
                null,
                stateUrl,
                language.key,
                callbackUrl,
                null,
                selectedBank,
                customParam
            ).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(vm, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                }
            });
        }
    },
};

export default controller;
