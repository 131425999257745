import React, { Fragment } from 'react';
import Select from 'react-dropdown-select';
import { CSSTransition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../../redux/selector/selector';
import Loading from 'root/components/Loading/Loading';
import SmsVerifyModal from 'root/components/SmsVerifyModal';
import SVGElements from 'root/components/SVGElements';
import { IoMdArrowRoundDown } from 'react-icons/io';
import { walletAction } from 'root/redux/action/wallet.action';
import notification from 'root/utils/notification';
import commonUtil from 'root/utils/common-util';
class Transfer extends React.Component {
    isMount = true;
    defaultPromo = {
        id: 0,
        bonusName: <Trans i18nKey={'settings:settings.dropdown.pleaseselect'}></Trans>,
        code: '',
        bonusType: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            showWeekly: false,
            showFrom: false,
            showTo: false,
            showSpecial: false,
            showPromo: false,
            active: 0,
            providerList: [],
            toProviderList: [],
            totalvalidturnoveramount: 0,
            fromProvider: [],
            toProvider: [],
            fromProviderBalance: '',
            toProviderBalance: '',
            transferAmount: '',
            selectedPromotionCode: [],
            promoCodeList: [],
            sportbook: [
                { key: 'cmd368', value: 'CMD' },
                { key: 'maxbet', value: 'IBC' },
                { key: 'betrader', value: 'BR' },
                { key: 'bti', value: 'BTI' },
                { key: 'm8', value: 'M8' },
                { key: 'im', value: 'IME' },
                { key: 'tfg', value: 'TF' },
            ],
            casinoSlotFishing: [
                { key: 'dreamgaming', value: 'DG' },
                { key: 'ae_sexy', value: 'AES' },
                { key: 'ae_sexy', value: 'AES2' },
                { key: 'ag_gaming', value: 'AG2' },
                { key: 'mg', value: 'MGP' },
                { key: 'ebet', value: 'EBET' },
                { key: 'pt_logo', value: 'PT' },
                { key: 'pt_logo', value: 'PT2' },
                { key: 'ybl', value: 'YBL' },
                { key: 'sa', value: 'SA' },
                { key: 'allbet', value: 'AB' },
                { key: 'spade', value: 'SG' },
                { key: 'ultimate', value: 'UL' },
                { key: 'PP', value: 'PP' },
                { key: 'ttg', value: 'TTG' },
                { key: 'pngo', value: 'PG' },
                { key: 'mega888', value: 'MEGA' },
                { key: 'gg_gaming', value: 'GG' },
                { key: '918kiss', value: 'SCR2' },
                { key: 'ns', value: 'NS' },
                { key: 'evo', value: 'EVO' },
                { key: 'wm', value: 'WM' },
                { key: 'hb', value: 'HB' },
                { key: 'PPL', value: 'PPL' },
                { key: 'gd', value: 'GD' },
                { key: 'jkr', value: 'JKR' },
                { key: 'hongchow', value: 'HC' },
                { key: 'jili', value: 'JL' },
                { key: 'bg', value: 'BG' },
                { key: 'nt', value: 'NT' },
                { key: 'rt', value: 'RT' },
                { key: 'psy8', value: 'PSY8' },
                { key: 'funkygames', value: 'FG' },
                { key: 'pgsoft', value: 'PGS' },
                { key: 'yesgetrich', value: 'YGR' },
                { key: 'cq9', value: 'CQ9' },
                { key: 'fachai', value: 'FC' },
                { key: 'binl2', value: 'BINL2' },
                { key: 'bins2', value: 'BINS2' },
                { key: 'jdb', value: 'JDB' },
                { key: 'allbet2', value: 'AB2' },
                { key: 'rg', value: 'RG' },
                { key: 'ps', value: 'PS' },
                { key: 'ygg', value: 'YGG' },
                { key: 'spx', value: 'SPX' },
                { key: 'ap', value: 'AP' },
                { key: 'drg', value: 'DRG' },
                { key: 'pt_logo', value: 'PTL' },
                { key: 'pt_logo', value: 'PT3' },
                { key: 'w', value: 'W' },
                { key: 'we', value: 'WE' },
                { key: 'ds', value: 'DS' },
                { key: 'ep', value: 'EP' },
                { key: 'exs', value: 'EXS' },
                { key: 'vp', value: 'VP' },
                { key: 'vc', value: 'VC' },
                { key: 'fs', value: 'FS' },
                { key: 'sw', value: 'SW' },
                { key: 'r88', value: 'R88' },
                { key: 'weslt', value: 'WESLT' },
            ],
            others: [
                { key: 'idn_poker', value: 'IDN' },
                { key: 'qq', value: 'QQK' },
                { key: 'winwinlottery', value: 'WWL' },
                { key: 'v8poker', value: 'V8' },
                { key: 'kingmaker', value: 'KM' }, // TODO: remove this after SUP-70770 go LIVE
                { key: 'kingmaker', value: 'KM2' },
                { key: 'minited', value: 'MT' },
                { key: 'gameplay', value: 'GP' },
                { key: 'granddragon', value: 'GDL' },
                { key: 'funkygames', value: 'FG' },
                { key: 'shicai', value: 'GW' },
                { key: 'digmaan', value: 'DIG' },
                { key: 'tcg', value: 'TCG' },
                { key: 'tcga', value: 'TCGA' },
                { key: 'ws', value: 'WS' },
                { key: 'spb', value: 'SPB' },
                { key: 't1g', value: 'T1G' },
                { key: 'mtv', value: 'MTV' },
            ],
            mainWallet: '',
            promoSelected: false,
            bonusRate: '',
            maxBonus: '',
            specialCode: '',
            specialCodeId: '',
            disablePromoSelect: '',
            isValidPromo: '',
            calculateRate: 0.0,
            selectedFrom: this.props.t('settings:settings.mywallet'),
            selectedTo: this.props.t('settings:settings.mywallet'),
            specialPromo: null,
            status: '',
            message: '',
            mtransferDisable: false,
            smsVerify: false,
            transferToPopUp: false,
            getPromoDetail: true,
            getPromoCodeDetail: false,
            bonus_title: '',
            bonus_rate: '',
            turnover_multiplier: '',
            min_deposit: '',
            max_deposit: '',
            max_bonus: '',
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.loadProviders(true);
        this.loadMainWallet();
        commonUtil.loadCustomTranslation(['Rebate', 'Transfer'], this, function () {});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                this.loadMainWallet();
                this.refreshAllProviderAndBalance(false);

                setTimeout(() => {
                    this.props.dispatch(walletAction.updateLoadWallet(false));
                }, 500);
            }
        }
    }

    onReload(target) {
        TweenLite.fromTo(
            target,
            1,
            {
                rotate: 0,
            },
            {
                rotate: 360,
            }
        );

        this.loadProviders();
    }

    initEvent() {
        document.querySelector('#root').addEventListener('click', (e) => {
            ['showWeekly', 'showFrom', 'showTo', 'showSpecial', 'showPromo'].forEach((key) => {
                if (this.state[key]) {
                    let stopEventEl = [this.refs.el.querySelector(`.${key}-btn`), this.refs.el.querySelector(`.${key}-item`)];
                    for (let index = 0; index < stopEventEl.length; index++) {
                        let child = stopEventEl[index];
                        child = child && child.$el ? child.$el : child;
                        if (child && child.contains(e.target)) return;
                    }

                    this.onShow(key);
                }
            });
        });
    }

    onShow(key) {
        if (key) {
            this.setState({ [key]: !this.state[key] });
        } else {
            this.setState({ showFrom: false, showTo: false, showPromo: false });
        }
    }

    changeProvider = (item, type) => {
        let arr = null;
        let provider = null;
        if (type === 'from') {
            if (item === '') {
                arr = this.state.providerList.filter((e) => e.provider === null);
                this.setState({ selectedFrom: arr[0].displayName });
                provider = arr[0];
            } else {
                arr = [item];
                this.setState({ selectedFrom: item.provider });
                provider = item;
            }

            if (provider) {
                this.getMinimumAmountForTransfer(provider.provider, this.state.toProvider[0].provider, null);
                this.setState({ fromProvider: arr, transferAmount: provider.balance, fromProviderBalance: provider.balance, showFrom: false });
            }
        } else if (type === 'to') {
            if (item === '') {
                arr = this.state.providerList.filter((e) => e.provider === null);
                this.setState({ selectedTo: arr[0].displayName });
                provider = arr[0];
            } else {
                arr = [item];
                this.setState({ selectedTo: item.provider });
                provider = item;
            }

            if (provider) {
                this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, provider.provider, null);
                this.setState({ toProvider: arr, toProviderBalance: provider.balance, showTo: false }, () => {
                    if (!(provider.provider === null || provider.provider === '')) {
                        if (this.state.specialCode) {
                            this.validateSpecialCode();
                        } else {
                            this.loadPromotions(provider.provider);
                        }
                    } else {
                        this.loadPromotions(provider.provider);
                    }
                });
            }
        } else {
            arr = this.state.providerList.filter((e) => e.provider === null);

            this.setState({ selectedFrom: arr[0].displayName, selectedTo: arr[0].displayName });
        }
    };

    getTransferToPopUp = (info, key) => {
        this.setState({ transferToPopUp: !this.state.transferToPopUp, toProviderInfo: info, toProviderKey: key });
    };

    renderProviderItemByCategoryList(type, categoryList, includeMainWallet = false) {
        const { t } = this.props;
        let providerList = this.state.providerList;
        if (type === 'to') {
            providerList = this.state.toProviderList;
        }
        let foundProviderList = [];
        for (let i = 0; i < categoryList.length; i++) {
            let item = categoryList[i];
            let title = providerList.filter((a) => a.provider === item.value);
            if (title && title.length > 0) {
                let foundProvider = {
                    title: title[0],
                    item: item,
                    includeMainWallet: false,
                };
                foundProviderList.push(foundProvider);
            }
        }

        if (type !== 'weeklyTurn' && includeMainWallet) {
            foundProviderList.push({ includeMainWallet: true });
        }

        if (foundProviderList.length === 0) {
            return null;
        } else {
            return foundProviderList.map((providerObj, index) => {
                const { title, item, includeMainWallet } = providerObj;
                return (
                    <div className='category-provider' key={index}>
                        {includeMainWallet === false ? (
                            <Fragment>
                                <div className='item' data- group={index % 3} onClick={() => this.changeProvider(title, type)}>
                                    <div className='icon-box'>
                                        <i className={`icon-transfer-${item.key} icon-custom-transfer-${title.name?.replaceAll(' ', '')}`}></i>
                                    </div>
                                    <div>
                                        <span>
                                            {['SCR2', 'SCR', 'M8'].includes(item.value)
                                                ? title.name
                                                : this.state.customTranslationObj && this.state.customTranslationObj[item.value]
                                                ? t(this.state.customTranslationObj[item.value])
                                                : item.value}
                                        </span>
                                        <span>{title.balance ? window.SPL_Other.formatAmount(title.balance) : window.SPL_Other.formatAmount(0)}</span>
                                    </div>
                                </div>

                                <div className='turnover-box'>
                                    <span>{t('transaction:transaction.transfer.turnover', 'Turn over')}</span>
                                    <span className='turnover-amount'>
                                        {title.turnover ? window.SPL_Other.formatAmount(title.turnover) : window.SPL_Other.formatAmount(0)}
                                    </span>
                                </div>

                                <div className='btn-box'>
                                    <span
                                        className='btn-all-in'
                                        onClick={() => {
                                            this.onShow();
                                            this.handlePromotionCodeChange();
                                            this.handleSpecialCodeChange('');
                                            this.handleAmountChange(this.state.mainWallet);
                                            this.changeProvider(title, 'to');
                                            this.requestTransfer();
                                        }}
                                    >
                                        {t('transaction:transaction.transfer.allIn', 'All In')}
                                    </span>
                                    <span
                                        className='btn-plus'
                                        onClick={() => {
                                            this.getTransferToPopUp(title, item);
                                            this.changeProvider(title, type);
                                        }}
                                    >
                                        +
                                    </span>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className='item' data- group={index % 3} onClick={() => this.changeProvider('', type)}>
                                    <div className='icon-box'>
                                        <i className=''></i>
                                    </div>
                                    <div>
                                        <span>{t('settings:settings.mywallet', 'My Wallet')}</span>
                                        <span>{window.SPL_Other.formatAmount(this.state.mainWallet)}</span>
                                    </div>
                                </div>

                                <div className='btn-box'></div>
                            </Fragment>
                        )}
                    </div>
                );
            });
        }
    }

    transferList(list) {
        return (
            <Translation>
                {(t) => (
                    <ul className='provider-list'>
                        <div className='provider-category-container'>
                            <div className='category-title'>
                                <span>{t('global:global.menu.sportsbook2') + ' / ' + t('global:global.menu.esport2')}</span>
                            </div>
                            <div className='category-provider-list'>{this.renderProviderItemByCategoryList(list, this.state.sportbook)}</div>
                        </div>

                        <div className='provider-category-container'>
                            <div className='category-title'>
                                <span>{t('global:global.menu.casino2') + ' / ' + t('global:global.menu.slot2') + ' / ' + t('global:global.menu.fishing2')}</span>
                            </div>
                            <div className='category-provider-list'>{this.renderProviderItemByCategoryList(list, this.state.casinoSlotFishing)}</div>
                        </div>

                        <div className='provider-category-container'>
                            <div className='category-title'>
                                <span>{t('global:global.others')}</span>
                            </div>

                            <div className='category-provider-list'>{this.renderProviderItemByCategoryList(list, this.state.others, true)}</div>
                        </div>
                    </ul>
                )}
            </Translation>
        );
    }

    refreshAllProviderAndBalance = (toEmptyProvider) => {
        if (toEmptyProvider !== false) {
            this.setState({ providerList: [] });
        }
        this.loadProviders(true);
        this.loadPromotions();
    };

    loadProviders = (isLoadAsyncProviderWallet) => {
        this.setState({ iconLoading: true });
        window.SPL_Provider.getAllProviderWalletList(null, false, this.props)
            .then((data) => {
                if (this.isMount) {
                    let providerList = data.providerList;
                    let fromProvider;
                    let toProvider;
                    const { currency } = this.props.user.account || { currency: '' };
                    const { t } = this.props;
                    for (let i = 0; i < providerList.length; i++) {
                        let balance = providerList[i].balance;
                        if (balance) {
                            balance = window.SPL_Other.formatAmount(providerList[i].balance);
                        } else {
                            balance = 0;
                        }
                        // providerList[i].provider = `${providerList[i].provider === null ? null : providerList[i].provider}`;
                        providerList[i].displayName = `${
                            providerList[i].provider === null
                                ? t('settings:settings.mywallet')
                                : this.state.customTranslationObj && this.state.customTranslationObj[providerList[i].provider]
                                ? this.state.customTranslationObj[providerList[i].provider]
                                : providerList[i].provider
                        }`;

                        providerList[i].name = `${
                            providerList[i].provider === null
                                ? t('settings:settings.mywallet')
                                : this.state.customTranslationObj && this.state.customTranslationObj[providerList[i].provider]
                                ? t(this.state.customTranslationObj[providerList[i].provider])
                                : providerList[i].name
                        }`;

                        providerList[i].display = `${providerList[i].name ? providerList[i].name : t('settings:settings.mywallet')} (${
                            window.SPL_Other.formatAmount(balance) || window.SPL_Other.formatAmount('0')
                        } ${currency})
                                      ${t(providerList[i].serverMaintenanceStr)}`;

                        if (providerList[i].provider === null) {
                            fromProvider = [providerList[i]];
                            toProvider = [providerList[i]];
                        }
                    }

                    this.setState(
                        {
                            providerList: data.providerList,
                            toProviderList: data.providerList,
                            totalvalidturnoveramount: data.totalvalidturnoveramount,
                            transferAmount: fromProvider[0].balance || window.SPL_Other.formatAmount('0'),
                            fromProvider: fromProvider,
                            toProvider: toProvider,
                            iconLoading: false,
                            fromProviderBalance: window.SPL_Other.formatAmount(fromProvider[0].balance) || window.SPL_Other.formatAmount('0'),
                            toProviderBalance: window.SPL_Other.formatAmount(toProvider[0].balance) || window.SPL_Other.formatAmount('0'),
                            status: '',
                            message: '',
                            specialPromo: null,
                        },
                        () => {
                            this.updateToProviderList();
                        }
                    );
                }
            })
            .then(() => {
                if (isLoadAsyncProviderWallet) {
                    this.loadAsyncProviderWallet();
                }
            });

        this.props.onReload();
    };

    loadAsyncProviderWallet = () => {
        if (this.isMount) {
            this.setState({ isLoad: true }, () => {
                window.SPL_Provider.loadAsyncProviderWallet(null, this.props).then((providerWallet) => {
                    let providerList = this.state.providerList;
                    for (let i = 0; i < providerList.length; i++) {
                        let results = providerWallet.providerList.filter(function (e) {
                            return e.provider === providerList[i].provider;
                        })[0];

                        if (results) {
                            providerList[i].balance = results.balance ? results.balance : 0;
                            providerList[i].serverIsUnderMaintenance = results.serverIsUnderMaintenance;
                            providerList[i].isdisplay = true;
                            providerList[i].serverMaintenanceStr = results.serverIsUnderMaintenance ? 'error:error.underMaintainance' : '';
                        } else {
                            providerList[i].serverMaintenanceStr = '';
                        }
                        if (this.isMount) {
                            this.setState({ providerList: providerList }, () => {
                                this.updateToProviderList();
                            });
                        }
                    }
                });
            });
        }
    };

    loadMainWallet = () => {
        window.SPL_Member.getMainWallet().then((mainWallet) => {
            this.setState({ mainWallet: parseInt(mainWallet.data.balance) });
        });
    };

    loadPromotions = (provider) => {
        const { countryLanguageKey } = this.props.language;
        const { portal, screen } = this.props;

        if (provider) {
            let platform = null;

            if (portal && portal.settings && portal.settings.getPromoCodeByPlatform) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    platform = 'A';
                } else if (screen.viewType === 'web') {
                    platform = 'W';
                } else {
                    platform = 'M';
                }
            }

            window.SPL_Provider.loadPromotions(provider, null, countryLanguageKey, platform).then((promoCodeList) => {
                if (this.isMount) {
                    promoCodeList.unshift(this.defaultPromo);
                    let pl = this.state.promoCodeList;
                    pl = [];
                    for (let i = 0; i < promoCodeList.length; i++) {
                        if (promoCodeList[i].id !== 0) {
                            pl.push(promoCodeList[i]);
                        }
                    }

                    pl.unshift({ bonusName: this.props.t('settings:settings.dropdown.pleaseselect') });

                    this.setState({ promoCodeList: pl }, () => {
                        this.setState({ selectedPromotionCode: [this.defaultPromo] });
                    });
                }
            });
        } else {
            let promoCodeList = [];
            promoCodeList.unshift(this.defaultPromo);
            if (this.isMount) {
                let pl = this.state.promoCodeList;
                pl = [];
                for (let i = 0; i < promoCodeList.length; i++) {
                    if (promoCodeList[i].id !== 0) {
                        pl.push(promoCodeList[i]);
                    }
                }

                pl.unshift({ bonusName: this.props.t('settings:settings.dropdown.pleaseselect') });

                this.setState({ promoCodeList: pl }, () => {
                    this.setState({ selectedPromotionCode: [this.defaultPromo] });
                });
            }
        }
    };

    handleFromProviderChange = (value) => {
        let provider = value[0];
        if (provider) {
            this.getMinimumAmountForTransfer(provider.provider, this.state.toProvider[0].provider, null);
            this.setState({ fromProvider: value, transferAmount: provider.balance, fromProviderBalance: provider.balance });
        }
    };

    handleToProviderChange = (value) => {
        let provider = value[0];
        if (provider) {
            this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, provider.provider, null);
            this.setState({ toProvider: value, toProviderBalance: provider.balance }, () => {
                if (!(provider.provider === null || provider.provider === '')) {
                    if (this.state.specialCode) {
                        this.validateSpecialCode();
                    } else {
                        this.loadPromotions(provider.provider);
                    }
                } else {
                    this.loadPromotions(provider.provider);
                }
            });
        }
    };

    handlePromotionCodeChange = (value) => {
        let rate = '';
        let maxBonus = '';

        if (value && value[0]) {
            rate = value[0].rate;
            maxBonus = value[0].maxBonus;

            if (value[0].code) {
                this.setState({ selectedPromotionCode: value, promoSelected: true, bonusRate: rate, maxBonus: maxBonus, specialCode: null, getPromoDetail: false }, () => {
                    this.calPromoDetails(value[0]);
                    this.calculateRate();
                    if (value.length > 0 && value[0].id !== 0) {
                        this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, this.state.toProvider[0].provider, value[0]);
                    } else {
                        this.setState({ promoSelected: false, calculateRate: 0.0 });
                    }
                });
            } else {
                this.setState({
                    selectedPromotionCode: value,
                    promoSelected: false,
                    bonusRate: rate,
                    maxBonus: maxBonus,
                    specialCode: null,
                    calculateRate: 0.0,
                    getPromoDetail: true,
                });
            }
        } else {
            this.setState({
                selectedPromotionCode: [],
                promoSelected: false,
                bonusRate: rate,
                maxBonus: maxBonus,
                specialCode: null,
                calculateRate: 0.0,
                getPromoDetail: true,
            });
        }
    };

    calPromoDetails = (value) => {
        if (value) {
            let bonus_title = value.bonusName,
                bonus_rate = value.rate * 100,
                turnover_multiplier = value.targetMultiplier,
                min_deposit = value.minDeposit,
                max_deposit = value.maxDeposit,
                max_bonus = value.maxBonus;

            this.setState({
                bonus_title: bonus_title,
                bonus_rate: bonus_rate,
                turnover_multiplier: turnover_multiplier,
                min_deposit: min_deposit,
                max_deposit: max_deposit,
                max_bonus: max_bonus,
            });
        }
    };

    getPromoCodeDetail = () => {
        this.setState({ getPromoCodeDetail: !this.state.getPromoCodeDetail });
    };

    handleSpecialCodeChange = (value) => {
        if (value) {
            this.setState({ disablePromoSelect: 'true' });
            this.setState({ specialCode: value }, () => {
                if (this.state.providerList.length > 0) {
                    this.validateSpecialCode();
                }
            });
        } else {
            this.setState(
                { disablePromoSelect: '', isValidPromo: '', specialCodeId: '', specialCode: '', specialPromo: null, calculateRate: 0.0, maxBonus: 0, mtransferDisable: false },
                () => {
                    this.loadProviders(true);
                    this.changeProvider('');
                }
            );
        }
    };

    validateSpecialCode = () => {
        const { providerList, specialCode, fromProvider, toProvider, transferAmount } = this.state;
        const { user } = this.props;
        let from = fromProvider[0].provider;
        if (from === 'My Wallet') {
            from = null;
        }
        let to = toProvider[0].provider;
        if (to === 'My Wallet') {
            to = null;
        }

        if (specialCode && specialCode !== '' && specialCode.length > 0) {
            window.SPL_Transaction.verifyPromotionCode(providerList, user.account.login, specialCode, from, to, transferAmount).then((data) => {
                if (data.specialPromo !== null) {
                    const { isValidPromo, isValidSpecialPromoProvider, isValidSpecialPromoAmount, specialPromo } = data;
                    this.setState(
                        {
                            isValidPromo: isValidPromo,
                            isValidSpecialPromoProvider: isValidSpecialPromoProvider,
                            isValidSpecialPromoAmount: isValidSpecialPromoAmount,
                            bonusRate: specialPromo.rate,
                            specialCodeId: specialPromo.id,
                            specialPromo: specialPromo,
                            maxBonus: specialPromo.maxBonus,
                            mtransferDisable: false,
                        },
                        () => {
                            this.calculateRate();
                            this.updateToProviderList();
                        }
                    );
                } else {
                    this.setState({ isValidPromo: data.isValidPromo, specialPromo: null, mtransferDisable: true }, () => {
                        this.updateToProviderList();
                    });
                }
            });
        }
    };

    updateToProviderList = () => {
        // update to provider list
        const { specialPromo, providerList, toProvider } = this.state;
        let toProviderList = providerList;
        let toProviderSelected = toProvider;
        if (specialPromo && specialPromo.providers) {
            let specialPromoProviderList = specialPromo.providers.map((pvd) => pvd.provider);
            toProviderList = providerList.filter((p) => specialPromoProviderList.includes(p.provider) || p.provider === null);
            // if selected provider is in the list then remain selected provider else get first provider from special promo list
            toProviderSelected = toProvider.filter((p) => specialPromoProviderList.includes(p.provider) || p.provider === null);
            if (toProviderSelected.length <= 0) {
                toProviderSelected = [toProviderList[0]];
            }
        }
        this.setState({ toProviderList: toProviderList, toProvider: toProviderSelected });
    };

    handleAmountChange = (value) => {
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                this.setState({ transferAmount: value }, () => {
                    const { specialPromo } = this.state;
                    this.calculateRate();
                    if (specialPromo) {
                        this.checkAmountWithSpecialCode();
                    }
                });
            }
        } else {
            this.setState({ transferAmount: value }, () => {
                this.calculateRate();
            });
        }
    };

    checkAmountWithSpecialCode = () => {
        const { specialPromo, transferAmount } = this.state;
        let isValidSpecialPromoAmount = window.SPL_Transaction.checkAmountWithSpecialCode(specialPromo, transferAmount, specialPromo.minDeposit);
        this.setState({ isValidSpecialPromoAmount: isValidSpecialPromoAmount });
    };

    calculateRate = () => {
        const { bonusRate, transferAmount, maxBonus } = this.state;

        let total = 0;
        if (bonusRate === 0) {
            total = maxBonus;
        } else {
            total = bonusRate * transferAmount;
        }

        if (total > maxBonus) {
            total = maxBonus;
        }

        this.setState({ calculateRate: total.toFixed(2) });
    };

    getMinimumAmountForTransfer = (fromProvider, toProvider, promo) => {
        let minimumAmount = window.SPL_Transaction.getMinimumAmountForTransfer(fromProvider, toProvider, promo);
        this.setState({ minimumAmount: minimumAmount });
    };

    checkPhoneVerification = (provider, all_in_provider) => {
        const { selectedPromotionCode } = this.state;
        if (selectedPromotionCode && selectedPromotionCode.length > 0 && selectedPromotionCode[0].isPhoneVerification) {
            window.SPL_Member.getPhoneRequiredVerification().then((isReqVerification) => {
                if (isReqVerification) {
                    this.getSmsVerifyModal();
                    this.setState({ iconLoading: false });
                } else {
                    this.requestTransfer(provider, all_in_provider);
                }
            });
        } else {
            this.requestTransfer(provider, all_in_provider);
        }
    };

    requestTransfer = (provider) => {
        this.setState(
            { iconLoading: true },
            () => {
                const {
                    transferAmount,
                    selectedPromotionCode,
                    toProvider,
                    fromProvider,
                    promoSelected,
                    specialCodeId,
                    specialPromo,
                    isValidPromo,
                    isValidSpecialPromoAmount,
                    isValidSpecialPromoProvider,
                    specialCode,
                } = this.state;
                if (specialPromo) {
                    specialPromo.isValidPromo = isValidPromo;
                    specialPromo.isValidSpecialPromoAmount = isValidSpecialPromoAmount;
                    specialPromo.isValidSpecialPromoProvider = isValidSpecialPromoProvider;
                }

                if (specialCode !== '' && isValidPromo === false) {
                    return;
                }

                const { t } = this.props;
                let promotionId = null;

                if (promoSelected) {
                    promotionId = selectedPromotionCode[0].id;
                } else if (specialCodeId !== '') {
                    promotionId = specialCodeId;
                } else {
                    promotionId = 0;
                }
                // if provider is not null, transfer is from balance tab, and only applicable for mainwallet to provider
                let toProviderValue = provider ? provider.provider : toProvider[0].provider;
                let fromProviderValue = provider ? 'My Wallet' : fromProvider[0].provider;
                let remark = fromProviderValue === 'My Wallet' ? 'Member Deposit' : 'Member Withdraw';

                if (toProviderValue === 'My Wallet') {
                    toProviderValue = null;
                }

                if (fromProviderValue === 'My Wallet') {
                    fromProviderValue = null;
                }

                let from = fromProvider;
                let to = toProvider;

                if (fromProvider[0].provider === 'My Wallet') {
                    from[0].provider = null;
                }

                if (toProvider[0].provider === 'My Wallet') {
                    to[0].provider = null;
                }

                this.setState({ showLoading: true }, () => {
                    let mainWalletBalance = this.state.providerList.filter((e) => e.provider === null)[0].balance;
                    //  mainWalletBalance, transferAmount, fromProvider, toProvider, promotion, blockProvidersFlag
                    window.SPL_Transaction.validateTransferFormV2(mainWalletBalance, transferAmount, from[0], to[0], selectedPromotionCode[0], false, specialPromo).then(
                        (validateRes) => {
                            if (validateRes.status === 'F') {
                                notification.showNotification(
                                    'error',
                                    t(validateRes.msg, { amount: validateRes.amount || validateRes.amount === 0 ? validateRes.amount : '' })
                                );
                                this.setState({ iconLoading: false });
                            } else if (validateRes.status === 'V') {
                                this.getSmsVerifyModal();
                                this.setState({ iconLoading: false });
                            } else {
                                window.SPL_Transaction.requestTransfer(transferAmount, promotionId, remark, toProviderValue, fromProviderValue).then((data) => {
                                    this.setState({ iconLoading: false });
                                    if (data.status === 'F') {
                                        if (data.msg && data.msg.data && data.msg.data.detail) {
                                            notification.showNotification('error', t(data.msg.data.detail));
                                        } else {
                                            notification.showNotification('error', t(data.msg));
                                        }
                                        this.refreshAllProviderAndBalance(false);
                                    } else {
                                        if (this.isMount) {
                                            if (this.state.promoCodeList.length > 0) {
                                                this.setState({
                                                    transferAmount: '',
                                                    selectedPromotionCode: [this.state.promoCodeList[0]],
                                                    refreshMainWallet: true,
                                                    status: data.status,
                                                    message: 'transaction:transaction.transfer.success',
                                                });
                                            } else {
                                                this.setState({
                                                    transferAmount: '',
                                                    refreshMainWallet: true,
                                                    status: data.status,
                                                    message: 'transaction:transaction.transfer.success',
                                                });
                                            }

                                            setTimeout(() => {
                                                // for desktop need to use parent get main wallet to update amount for parent component also
                                                // this.loadMainWallet();
                                                this.refreshAllProviderAndBalance(false);
                                                this.props.dispatch(walletAction.updateLoadWallet(true));
                                                this.changeProvider('');
                                            }, 500);
                                        }
                                    }
                                });
                            }
                        }
                    );
                });
            },
            () => {
                this.setState({ iconLoading: false });
            }
        );
    };

    swapTransfer = () => {
        let from = this.state.fromProvider;
        let to = this.state.toProvider;

        let fromB = this.state.fromProviderBalance;
        let toB = this.state.toProviderBalance;

        this.setState(
            {
                fromProvider: to,
                toProvider: from,
                fromProviderBalance: toB,
                toProviderBalance: fromB,
                selectedFrom: to[0].displayName,
                selectedTo: from[0].displayName,
                specialCode: '',
                calculateRate: 0.0,
                maxBonus: 0,
            },
            () => {
                this.handleFromProviderChange(to);
                this.handleToProviderChange(from);
                this.loadPromotions();
            }
        );
    };

    customNoDataRenderer = ({ props, state, methods }) => (
        <Translation>{(t) => <div className='select-noData'> {t('reports:reports.noDataAvailable', 'No Data Available')}</div>}</Translation>
    );

    getSmsVerifyModal = () => {
        this.setState({ smsVerify: !this.state.smsVerify });
    };

    handleRangeChange = (e) => {
        this.setState({ transferAmount: e });
    };

    renderCorrectName = (name, t) => {
        let newName = '';
        switch (name) {
            case 'M8':
                newName = 'M8 Sports';
                break;

            case 'IBC':
                newName = 'Saba-Sports';
                break;

            case 'HC':
                newName = 'GoldenBay';
                break;

            case 'BTI':
                newName = 'Maxim88 Sports';
                break;

            default:
                newName = this.state.customTranslationObj && this.state.customTranslationObj[name] ? t(this.state.customTranslationObj[name]) : name;
                break;
        }
        return newName;
    };

    render() {
        const { toProviderInfo, toProviderKey } = this.state;

        return (
            <Translation>
                {(t) => (
                    <div className='wap-transfer-page'>
                        {this.state.iconLoading && <Loading />}

                        {this.state.smsVerify && <SmsVerifyModal getSmsVerifyModal={this.getSmsVerifyModal} requestTransfer={this.requestTransfer} />}

                        {/* Transfer To Pop Up */}
                        {this.state.transferToPopUp && (
                            <div className='messaging-popup providerTransferTo-popUp'>
                                <div className='popup'>
                                    <div className='popup-body'>
                                        <div className='providerInfo-box'>
                                            <span>{t('transaction:transaction.transfer.transferTo', 'Transfer to')}</span>
                                            <IoMdArrowRoundDown className='promo-icon-arrow' />
                                            <div className='provider-to'>
                                                <div className='provider-balance'>
                                                    <i className={`icon-transfer-${toProviderKey.key} icon-custom-transfer-${toProviderInfo.name?.replaceAll(' ', '')}`}></i>
                                                    <div>
                                                        <span>{this.renderCorrectName(toProviderKey.value, t)}</span>
                                                        <span>
                                                            {toProviderInfo.balance ? window.SPL_Other.formatAmount(toProviderInfo.balance) : window.SPL_Other.formatAmount(0)}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className='provider-turover'>
                                                    <span>{t('transaction:transaction.transfer.turnover', 'Turn over')}</span>
                                                    <span className='turnover-amount'>
                                                        {toProviderInfo.turnover ? window.SPL_Other.formatAmount(toProviderInfo.turnover) : window.SPL_Other.formatAmount(0)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='providerTransfer-box'>
                                            <span>
                                                {t('transaction:transaction.transfer.transfer', 'Transfer')} {t('transaction:transaction.transfer.amount', 'Amount')}
                                            </span>
                                            <div className='amount-box'>
                                                <input
                                                    name='transferAmount'
                                                    id='transferAmount'
                                                    type='number'
                                                    min='0'
                                                    value={this.state.transferAmount}
                                                    onChange={(e) => {
                                                        this.handleAmountChange(e.target.value);
                                                    }}
                                                />
                                                <span className='btn-all-in' onClick={() => this.handleAmountChange(this.state.mainWallet)}>
                                                    {t('transaction:transaction.transfer.allIn', 'All In')}
                                                </span>
                                            </div>
                                        </div>

                                        <input
                                            type='range'
                                            className='range-box'
                                            min={0}
                                            max={this.state.mainWallet}
                                            value={this.state.transferAmount}
                                            onChange={(e) => this.handleRangeChange(e.target.value)}
                                        />

                                        <div className='specialPromo-box'>
                                            <span>{t('transaction:transaction.transfer.specialCode', 'Special Code')}</span>
                                            <div className='specialCode-box'>
                                                <input
                                                    name='specialCode'
                                                    id='specialCode'
                                                    type='text'
                                                    placeholder={t('transaction:transaction.transfer.specialCode', 'Special Code')}
                                                    value={this.state.specialCode ? this.state.specialCode : ''}
                                                    onChange={(e) => {
                                                        this.handleSpecialCodeChange(e.target.value);
                                                    }}
                                                    disabled={this.state.promoSelected}
                                                />
                                            </div>
                                            {this.state.specialCode && this.state.isValidPromo === true ? (
                                                <span className='specialCodeValid'>{t('transaction:transaction.transfer.promocode-valid', 'Promo Code Valid')}</span>
                                            ) : (
                                                this.state.specialCode &&
                                                this.state.isValidPromo === false && (
                                                    <span className='specialCodeInvalid'>{t('transaction:transaction.transfer.promocode-invalid', 'Promo Code Invalid')}</span>
                                                )
                                            )}
                                        </div>

                                        <div className='promoCode-box'>
                                            <span>{t('transaction:transaction.transfer.promocode', 'Promo Code')}</span>
                                            <div className='showPromo-btn' onClick={() => this.onShow('showPromo')}>
                                                <Select
                                                    noDataRenderer={this.customNoDataRenderer}
                                                    className={`form-control-inner ${this.state.promoSelected ? '' : 'dropdown-please-select'}`}
                                                    placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                    options={this.state.promoCodeList}
                                                    labelField='bonusName'
                                                    valueField='code'
                                                    values={this.state.selectedPromotionCode}
                                                    onChange={(value) => this.handlePromotionCodeChange(value)}
                                                    searchable={false}
                                                    disabled={this.state.disablePromoSelect}
                                                />
                                            </div>
                                        </div>

                                        <div className='btn-box'>
                                            <span
                                                className='btn-reset'
                                                onClick={() => {
                                                    this.loadMainWallet();
                                                    this.loadPromotions(toProviderInfo.provider);
                                                    this.handleSpecialCodeChange();
                                                }}
                                            >
                                                {t('register:register.form.reset', 'Reset')}
                                            </span>
                                            <button
                                                className='btn-transfer'
                                                disabled={this.state.mtransferDisable}
                                                onClick={() => {
                                                    this.checkPhoneVerification();
                                                    this.onShow();
                                                    this.getTransferToPopUp();
                                                }}
                                            >
                                                {t('transaction:transaction.transfer.transfer', 'Transfer')}
                                            </button>
                                        </div>

                                        <p className='deposit-text'>
                                            {t('transaction:transaction.transfer.notEnoughCredit')}{' '}
                                            <span onClick={() => this.props.history.push('/account/deposit')}> {t('transaction:transaction.transfer.depositNow')} </span>
                                        </p>
                                    </div>
                                </div>

                                <div className='popup-overlay' onClick={() => this.getTransferToPopUp()}></div>
                            </div>
                        )}

                        {this.state.getPromoCodeDetail && (
                            <div className='messaging-popup promoCodeDetail-popup'>
                                <div className='popup'>
                                    <div className='popup-body'>
                                        <div className='body-header'>
                                            <span>{this.state.bonus_title}</span>
                                            <SVGElements name='close-icon' onClick={() => this.getPromoCodeDetail()} />
                                        </div>

                                        <table>
                                            <tr>
                                                <td>Bonus %</td>
                                                <td>{this.state.bonus_rate} %</td>
                                            </tr>
                                            <tr>
                                                <td>Turnover Multiplier</td>
                                                <td>{this.state.turnover_multiplier}x</td>
                                            </tr>
                                            <tr>
                                                <td>Min Deposit</td>
                                                <td>
                                                    {(this, this.props.user.account.currency)} {this.state.min_deposit}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Max Deposit</td>
                                                <td>
                                                    {(this, this.props.user.account.currency)} {this.state.max_deposit}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Max Bonus</td>
                                                <td>
                                                    {(this, this.props.user.account.currency)} {this.state.max_bonus}
                                                </td>
                                            </tr>
                                        </table>

                                        <span className='tnc-apply'>* {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</span>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.getPromoCodeDetail()}></div>
                            </div>
                        )}

                        <div className='page-content'>
                            <div className='transfer-item-box'>
                                <div className='title-item'>
                                    <span>{t('transaction:transaction.transfer.transferFrom', 'Transfer from')}</span>
                                </div>

                                <div className='transfer-from-box showFrom-btn' onClick={() => this.onShow('showFrom')}>
                                    <span>
                                        {t(
                                            this.state.customTranslationObj && this.state.customTranslationObj[this.state.selectedFrom]
                                                ? this.state.customTranslationObj[this.state.selectedFrom]
                                                : this.state.selectedFrom
                                        )}
                                    </span>
                                    <CSSTransition classNames='rotate180' in={this.state.showFrom} timeout={300}>
                                        <SVGElements className='icon-wap-arrow' name='arrow-down-icon' />
                                    </CSSTransition>
                                    <CSSTransition classNames='spread' in={this.state.showFrom} timeout={300} unmountOnExit>
                                        <div
                                            className='showFrom-item'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            {this.transferList('from')}
                                        </div>
                                    </CSSTransition>
                                </div>
                            </div>
                            <div className='transfer-item-box'>
                                <div className='title-item'>
                                    <span>{t('transaction:transaction.transfer.transferTo', 'Transfer to')}</span>
                                    <button className='icon-wap-transfer_btn' onClick={() => this.swapTransfer()} disabled={this.state.specialCode}></button>
                                </div>

                                <div
                                    className='transfer-to-box showTo-btn'
                                    onClick={() => {
                                        this.onShow('showTo');
                                    }}
                                >
                                    <span>
                                        {t(
                                            this.state.customTranslationObj && this.state.customTranslationObj[this.state.selectedTo]
                                                ? this.state.customTranslationObj[this.state.selectedTo]
                                                : this.state.selectedTo
                                        )}
                                    </span>
                                    <CSSTransition classNames='rotate180' in={this.state.showTo} timeout={300}>
                                        <SVGElements className='icon-wap-arrow' name='arrow-down-icon' />
                                        {/* <i className="icon-wap-arrow"></i> */}
                                    </CSSTransition>
                                    <CSSTransition classNames='spread' in={this.state.showTo} timeout={300} unmountOnExit>
                                        <div
                                            className='showTo-item'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            {this.transferList('to')}
                                        </div>
                                    </CSSTransition>
                                </div>
                            </div>

                            <div className='transfer-item-box code-box special-code'>
                                <div className='title-item'>
                                    <span>{t('transaction:transaction.transfer.specialCode', 'Special Code')}</span>
                                </div>
                                <div>
                                    <input
                                        name='specialCode'
                                        id='specialCode'
                                        type='text'
                                        value={this.state.specialCode ? this.state.specialCode : ''}
                                        onChange={(e) => {
                                            this.handleSpecialCodeChange(e.target.value);
                                        }}
                                        disabled={this.state.promoSelected}
                                    />
                                </div>
                                <div className='bg-transparent'>
                                    {this.state.specialCode && this.state.isValidPromo === true ? (
                                        <span className='specialCodeValid'>{t('transaction:transaction.transfer.promocode-valid', 'Promo Code Valid')}</span>
                                    ) : this.state.specialCode && this.state.isValidPromo === false ? (
                                        <span className='specialCodeInvalid'>{t('transaction:transaction.transfer.promocode-invalid', 'Promo Code Invalid')}</span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            </div>
                            <div className='transfer-item-box code-box promo-code'>
                                <div className='title-item'>
                                    <span>
                                        {t('transaction:transaction.transfer.promocode', 'Promo Code')} <span className='text-danger asterisk'>*</span>
                                    </span>
                                    <button onClick={() => this.getPromoCodeDetail()} disabled={this.state.getPromoDetail}>
                                        {t('transaction:transaction.transfer.promoDetailTxt')}
                                    </button>
                                </div>

                                <div
                                    className={'showPromo-btn'}
                                    onClick={() => {
                                        this.onShow('showPromo');
                                    }}
                                >
                                    <Select
                                        noDataRenderer={this.customNoDataRenderer}
                                        className={`form-control-inner ${this.state.promoSelected ? '' : 'dropdown-please-select'}`}
                                        placeholder={t('settings:settings.dropdown.pleaseselect')}
                                        options={this.state.promoCodeList}
                                        labelField='bonusName'
                                        valueField='code'
                                        values={this.state.selectedPromotionCode}
                                        onChange={(value) => this.handlePromotionCodeChange(value)}
                                        searchable={false}
                                        disabled={this.state.disablePromoSelect}
                                    />
                                </div>
                                <div>
                                    {this.state.calculateRate > 0 ? <input type='text' disabled value={this.state.calculateRate} /> : <input type='text' disabled value={0} />}
                                </div>
                            </div>

                            <div className='status-box'>
                                {this.state.status === 'S' && (
                                    <div className='invalid-feedback text-success'>
                                        <Trans i18nKey={this.state.message}></Trans>
                                    </div>
                                )}
                            </div>

                            <div className='transfer-amount-box'>
                                <div className='top'>
                                    <span>
                                        {t('transaction:transaction.transfer.transfer', 'Transfer')} {t('transaction:transaction.transfer.amount', 'Amount')}
                                    </span>
                                </div>
                                <div className='center'>
                                    <input
                                        name='transferAmount'
                                        id='transferAmount'
                                        type='number'
                                        min='0'
                                        value={this.state.transferAmount}
                                        onChange={(e) => {
                                            this.handleAmountChange(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='btns-box'>
                                    <span
                                        className='btn-reset'
                                        onClick={() => {
                                            this.loadMainWallet();
                                            this.changeProvider('');
                                            this.loadPromotions(null);
                                            this.handleSpecialCodeChange();
                                        }}
                                    >
                                        {t('register:register.form.reset', 'Reset')}
                                    </span>
                                    <button
                                        className='btn-transfer'
                                        disabled={this.state.mtransferDisable}
                                        onClick={() => {
                                            this.checkPhoneVerification();
                                        }}
                                    >
                                        {t('transaction:transaction.transfer.transfer', 'Transfer')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'transaction', 'settings', 'reports', 'register', 'popupTransfer', 'providerName'])(withRouter(Transfer)));
