import commonUtil from '../../utils/common-util';
import { trans_handlePGDepositCountDown, trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { gu_getNestedValue } from 'root/utils/general-util';
import { sumUp } from './deposit-method';
import { scrollToDepositStep2 } from './deposit-util';

import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
// used for 088_QRPayV2.js
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;

        controller.vm.state = {
            isLoading: true,
            methodList: [],
            bankList: [],
            selectedMethod: '',
            selectedBank: null,
            amount: '',
            limitAmount: '',
            amountErrMsg: [],
            getTncReferral: false,
            disabledDepositButton: true,

            depositAllSettings: controller.vm.props.depositAllSettings,
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositMinMaxLimit: null,

            compSettings: {},
            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            depositSubmitCountdown: null,
            depositAmountOptions: trans_getDepositAmountOptionByMethod(controller.vm.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QR),
        };
    },

    initCompSettings() {
        const { depositAllSettings, filteredDepositApiJson } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempDepositSettings = depositAllSettings;

            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'qrPaySettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // let depositNotice = null;
            // if (
            //     tempDepositSettings &&
            //     tempDepositSettings.depositJson &&
            //     tempDepositSettings.depositJson.quickPay &&
            //     tempDepositSettings.depositJson.quickPay.custom_article
            // ) {
            //     depositNotice = tempDepositSettings.depositJson.quickPay.custom_article;
            // }

            let compSettings = {
                //depositNotice,
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                showOnlineTransactionMode: tempSettings.showOnlineTransactionMode,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                customCountdown: gu_getNestedValue(controller.vm.props, 'parentvm.state.compSettings.depositPagesSettings.customCountdown'),
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(controller.vm, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    },

    readCustomDepositArticle() {
        const { compSettings, filteredDepositApiJson } = controller.vm.state;
        let paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode ? filteredDepositApiJson.optionCode : 'qr';
        const customFilename = compSettings && compSettings.isCustomArticlePath ? paymentCode : '';
        commonUtil.readCustomDepositArticle(controller.vm, customFilename);
    },

    async loadQRbank() {
        const { filteredDepositApiJson } = controller.vm.state;
        let _bankList = [];
        let _methodList = [];
        let _selectedMethod = null;
        let _selectedBank = null;
        // if enableJsonAPi true from deposit-landing
        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            // For loop the channel/method list
            for (let method in filteredDepositApiJson.channel) {
                let methodObj = {};
                methodObj.channel = method;
                methodObj.displayName = window.SPL_Content.getBankName(method, 'channel', window.SPL_Translation.getTranslatedTransactionMsg(method, 'depositChannel'));

                _methodList.push(methodObj);

                filteredDepositApiJson.channel[method].map(async (e) => {
                    e.name = window.SPL_Content.getBankName(e.code, 'bank', window.SPL_Translation.getBankChannelTranslation(e, 'channelCode'));
                });
            }
            if (_methodList.length > 0) {
                _bankList = Object.values(filteredDepositApiJson.channel)[0];
                _selectedMethod = _methodList[0];
                _selectedBank = _bankList[0];
            }
            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            controller.vm.setState(
                {
                    methodList: _methodList,
                    bankList: _bankList,
                    selectedMethod: _selectedMethod,
                    selectedBank: disablePreselectBank ? null : _selectedBank,
                    isLoading: false,
                },
                () => {
                    controller.getCorrectDepositMinMaxLimit();
                }
            );
        }
    },

    // getCorrectDepositMinMaxLimit() {
    //     const { depositAllSettings, bankList, selectedBank } = controller.vm.state;
    //     const currency = controller.vm.props.user.account.currency;
    //     let _depositMinMaxLimit = { min: 0, max: 0 };

    //     if (bankList.length > 0 && depositAllSettings.depositJson.quickPay[selectedBank.code]) {
    //         _depositMinMaxLimit = depositAllSettings.depositJson.quickPay[selectedBank.code][currency];
    //     }

    //     // after get new minmax, validate the amount again
    //     controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit }, () => {
    //         controller.validateDepositAmount();
    //     });
    // },

    getCorrectDepositMinMaxLimit() {
        const { filteredDepositApiJson } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        commonUtil.getDepositMinMax(
            controller.vm,
            paymentCode ? paymentCode : 'QR',
            controller.vm.props.user.account.currency,
            controller.vm.state.selectedBank?.code,
            null,
            null,
            controller.validateDepositAmount
        );
    },

    handleMethodChanged(method) {
        const { filteredDepositApiJson } = controller.vm.state;
        const { screen } = controller.vm.props;
        // mobile's select Tag make it become array
        method = screen.isMobile ? method[0] : method;

        if (controller.vm.state.selectedMethod !== method) {
            let methodData = filteredDepositApiJson.channel;
            let newBankList = methodData[method.channel];
            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            controller.vm.setState(
                {
                    selectedMethod: method,
                    bankList: newBankList,
                    selectedBank: disablePreselectBank ? null : newBankList[0],
                },
                () => {
                    controller.getCorrectDepositMinMaxLimit();
                    controller.checkError();
                }
            );
        }
    },

    handleBankChange(bank) {
        if (bank !== controller.vm.state.selectedBank) {
            const oldSelectedBank = controller.vm.state.selectedBank;
            controller.vm.setState(
                {
                    selectedBank: bank,
                    amount: '',
                },
                () => {
                    const {
                        enableScrollToDepositStep2 = false,
                        scrollToDepositStep2Attempts = 15,
                        scrollToDepositStep2Delay = 200,
                    } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

                    scrollToDepositStep2(enableScrollToDepositStep2, oldSelectedBank, bank, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);

                    controller.getCorrectDepositMinMaxLimit();
                    controller.checkError();
                }
            );
        }
    },

    handleAmountChange(value) {
        if (value) {
            let regex = '';
            // if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QA]) {
            // regex = /^[0-9]+$/;
            // } else {
            regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            // }
            if (value.toString().match(regex)) {
                controller.vm.setState({ amount: value }, () => {
                    controller.validateDepositAmount();
                });
            }
        } else {
            controller.vm.setState({ amount: '' }, () => {
                controller.validateDepositAmount();
            });
        }
    },

    handleButtonAmountChange(value) {
        const { enableAccValueButton } = controller.vm.props.portal.settings.features.depositPagesSettings;
        const totalAmount = enableAccValueButton ? sumUp(controller.vm.state.amount, value) : value;
        controller.vm.setState(
            {
                amount: totalAmount,
            },
            () => {
                controller.validateDepositAmount();
            }
        );
    },

    clearInputFieldAmount() {
        controller.vm.setState({ amount: '' });
    },

    validateDepositAmount() {
        const { amount, depositMinMaxLimit } = controller.vm.state;
        const depositLimitObj = { minLimit: depositMinMaxLimit && depositMinMaxLimit.min, maxLimit: depositMinMaxLimit && depositMinMaxLimit.max };
        window.SPL_Transaction.validateDepositAmount(amount, depositLimitObj)
            .then((errMsg) => {
                if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QR] && !this.state.amount.toString().match(/^[0-9]+$/)) {
                    const newErrMsg = window.SPL_Other.deepCopy(errMsg);
                    newErrMsg.push('transaction:transaction.withdrawal.disableDecimalError');
                    return newErrMsg;
                }

                return errMsg;
            })
            .then((errMsg) => {
                let errMsgLimitAmount = controller.getLimitAmountForErrMsg(errMsg[0]);
                controller.vm.setState({ amountErrMsg: errMsg, limitAmount: errMsgLimitAmount.limitAmount }, () => {
                    controller.checkError();
                });
            });
    },

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                limitAmount = window.SPL_Other.formatAmount(controller.vm.state.depositMinMaxLimit.min);
            } else if (errMsg.includes('invalidMaxAmount')) {
                limitAmount = window.SPL_Other.formatAmount(controller.vm.state.depositMinMaxLimit.max);
            }
        }

        return {
            limitAmount: limitAmount,
        };
    },

    checkError() {
        const { amount, amountErrMsg, selectedBank } = controller.vm.state;
        if (!amount || amountErrMsg.length > 0 || !selectedBank) {
            controller.vm.setState({ disabledDepositButton: true });
        } else {
            controller.vm.setState({ disabledDepositButton: false });
        }
    },

    disableDot(e) {
        if (e.keyCode == '46' || e.charCode == '46') {
            e.preventDefault();
        }
    },

    getTncReferral() {
        controller.vm.setState({ getTncReferral: !controller.vm.state.getTncReferral });
    },

    handleSubmit() {
        const vm = controller.vm;
        const { amountErrMsg, selectedBank, amount, selectedMethod, filteredDepositApiJson, depositSubmitCountdown, compSettings } = controller.vm.state;
        const { language, depositSubmission, t } = controller.vm.props;
        const { features } = controller.vm.props.portal.settings;
        const { customCountdown } = compSettings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            if (amountErrMsg.length > 0 || !selectedMethod) {
                return;
            }

            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }

            let stateUrl = '';
            const langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
            }

            const methodParam = {
                id: selectedBank.id,
                code: selectedBank ? selectedBank.code : null,
            };
            const callbackUrl = `/${langPath}/myaccount/deposit`;
            window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(vm, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                }
            });
        }
    },
};

export default controller;
