import th from 'date-fns/locale/th';
import zhCN from 'date-fns/locale/zh-CN';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component, Fragment } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SmallLoading from '../../components/Loading/SmallLoading';
import { mapStateToProps } from '../../redux/selector/selector';
import { IoIosAdd } from 'react-icons/io';
import SVGElements from 'root/components/SVGElements';
import QRCode from 'qrcode.react';
import { EmailIcon, EmailShareButton, LineIcon, LineShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import FlagsSelect from 'root/pages/05Registration/052_Flag_Select';
import APIClient from '../../utils/APIClient';
import notification from 'root/utils/notification';
import commonUtil from '../../utils/common-util';
import TransactionNote from '../../pages/Share/transaction-note/component/transaction-note';
import { trans_getDepositAmountOptionByMethod, trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { CryptoBankIcon } from '../../components/Molecules/bank-icon';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE } from '@utils/constants';
import { portalAction, authSettingsAction } from '@redux/action';
import { OTP_VERIFICATION_METHOD as OVM, OTP_VERIFICATION_TYPE as OVT, POPUP_TYPE } from '@constants';
import { popupAction } from '@redux/action/popup.action';
import AddCryptoAddressPopup from '@components/organisms/add-crypto-address-popup/add-crypto-address-popup';
import { scrollToDepositStep2 } from './deposit-util';

registerLocale('th', th);
registerLocale('zhCN', zhCN);

class Crypto extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSubLoading: true,
            defaultCryptoOption: [],
            cryptoOptions: [],
            cryptoChain: '',
            selectedCryptoOptions: [],
            selectedCryptoChain: [],
            defaultChain: '',
            selectedChain: '',
            cryptoQrCode: '',
            selectedChainMin: '',
            depositNotice: '',
            getShareInfo: false,
            getDepositTncReferral: false,
            getWithdrawTncReferral: false,
            exchangeRate: '',
            chainBranch: '',
            chainRemark: [],
            memberVerifyInfo: [],
            memberVerifyPopup: false,
            showEmail: false,
            showName: false,
            showPhone: false,
            name: '',
            email: '',
            phone: '',
            address: '',
            telCodeList: [],
            telcode: '+60',
            activeQrCode: true,
            memberVerifyDisable: true,
            requestRefill: false,
            addressOption: [],
            addressModal: false,
            selectedCurrency: [],
            memberAddressOption: [],
            selectedAddress: [],
            networkOption: [],
            selectedNetwork: [],
            cryptoAddressDisable: true,
            addAddressErrMsg: '',
            showCurrencyAndCoin: false,
            cryptoAmount: '',
            currencyAmount: '',
            cryptoExchangeRate: 0,
            cryptoWithdrawFee: '',
            remindMinAmount: '',
            guaranteedUntilTime: '',
            withdrawErrMsg: '',
            transactionDetails: '',
            getlatestRate: false,
            disabledWithdrawCryptoButton: true,
            reload: false,
            cryptoFeeCharge: '',
            otpCode: '',
            disableOtpBtn: true,
            submittedOTP: false,
            countdownTimer: 0,
            networkAndCoin: {},

            depositAllSettings: this.props.depositAllSettings,
            filteredDepositApiJson: this.props.filteredDepositApiJson,
            depositMinMaxLimit: null,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: [],
            otpErrMsg: false,
            amount: '',
            isCoinAmount: [],
            disabledDepositButton: true,
            showSubmitForm: false,
            CCaccountNumber: null,
            CCExchangeRate: '',
            depositSubmitCountdown: null,
            depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.CT),
            amountFieldNotRequired: [],
            amountErrMsg: [],
            fields: {
                gaCode: { value: '', isMandatory: true, errMsg: '' },
            },
            isSubmitting: false,
        };
    }

    componentDidMount() {
        this.getPaymentGatewaySetting();
        this.initCompSettings();
        this.loadCryptoBankGroup();

        if (this.props.type === 'deposit') {
            this.getCountryInfo();
        } else if (this.props.type === 'withdraw') {
            this.loadTransactionDetails();
            this.loadCryptoWithdraw();
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    loadCryptoBankGroup = async () => {
        let purposeType = 'D';

        if (this.props.type === 'withdraw') {
            purposeType = 'W';
        }

        let params = {
            purpose: purposeType,
            option: 'CT',
        };

        const { filteredDepositApiJson } = this.state;
        const { t } = this.props;
        let cryptoOptions = [];
        let cryptoChain = {};
        let count = 1;

        // new deposit method
        if (filteredDepositApiJson && filteredDepositApiJson.channel && purposeType === 'D') {
            // For loop the channel list
            for (let key in filteredDepositApiJson.channel) {
                let option = {};
                option.key = key;
                option.code = key;
                option.name = '{channel}';
                option.displayName = t('transaction:transaction.deposit.crypto.channelName', 'Channel') + t(' ') + count;
                cryptoOptions.push(option);
                count++;
            }

            window.SPL_Content.getBankName(cryptoOptions, 'channel');

            cryptoOptions.map((option) => {
                if (option.name !== '{channel}') {
                    option.displayName = option.name;
                }

                return option;
            });

            cryptoChain = filteredDepositApiJson.channel;

            for (let key in cryptoChain) {
                for (let i in cryptoChain[key]) {
                    // add key for having same key with old method
                    const chain = cryptoChain[key][i];
                    chain.displayName = window.SPL_Translation.getBankChannelTranslation(chain, 'channelCode');
                    chain.province = chain.coin;
                    chain.svgData = await window.SPL_Other.renderTemplateSVG(
                        `${commonUtil.getDepositImgPath()}${chain.coin}_${chain.network}.svg`,
                        `${chain.coin}_${chain.network}`
                    );
                }
            }

            const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            this.setState(
                {
                    defaultChain: disablePreselectBank ? '' : cryptoChain[cryptoOptions[0].key][0],
                    cryptoOptions: cryptoOptions,
                    cryptoChain: cryptoChain,
                    defaultCryptoOption: [cryptoOptions[0]],
                    selectedCryptoOptions: [cryptoOptions[0]],
                },
                () => {
                    this.getDepositDisplaySetting();
                    this.checkChainOptions();
                }
            );
        } else {
            this.getMerchantBankGroupWithOption(params);
        }
    };

    getMerchantBankGroupWithOption = (params) => {
        window.SPL_Transaction.getMerchantBankGroupWithOption(params).then((data) => {
            if (data && data.channelOptions && data.options) {
                for (let i = 0; i < data.channelOptions.length; i++) {
                    data.channelOptions[i].displayName = this.props.t(data.channelOptions[i].displayName);
                }

                const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                this.setState(
                    {
                        defaultChain: disablePreselectBank ? '' : data.options[data.channelOptions[0].key][0],
                        cryptoOptions: data.channelOptions,
                        cryptoChain: data.options,
                        defaultCryptoOption: [data.channelOptions[0]],
                        selectedCryptoOptions: [data.channelOptions[0]],
                    },
                    () => {
                        if (this.props.type === 'deposit') {
                            this.getDepositDisplaySetting();
                            this.checkChainOptions();
                        } else if (this.props.type === 'withdraw') {
                            this.getCryptoAddress();
                            // this.getCryptoExchangeRate();
                            this.loadCryptoDisplaySettings();
                            this.checkChainOptions();
                        }
                    }
                );
            }
        });
    };

    getDepositDetails = () => {
        window.SPL_Transaction.loadDepositRelatedSettings().then((data) => {
            if (data) {
                this.setState({
                    depositNotice: data.depositJson.onlinetransfer.custom_article,
                });
            }
        });
    };

    getDepositDisplaySetting = () => {
        this.setState({ chainRemark: [] }, () => {
            const { countryLanguageKey } = this.props.language;
            const { defaultChain, selectedChain } = this.state;
            let bankCode = '';

            if (defaultChain) {
                bankCode = defaultChain.bankCode ? defaultChain.bankCode : defaultChain.code;
            } else {
                bankCode = selectedChain.bankCode ? selectedChain.bankCode : selectedChain.code;
            }

            window.SPL_Transaction.getDepositDisplaySetting().then((data) => {
                if (data && data.remarkNotice && data.remarkNotice[countryLanguageKey] && data.remarkNotice[countryLanguageKey][bankCode]) {
                    this.setState({
                        chainRemark: data.remarkNotice[countryLanguageKey][bankCode],
                    });
                }
            });
        });
    };

    handleCryptoChannelChange = (value) => {
        const { cryptoChain } = this.state;
        let selectedChannelCode = '';
        let selectedChain = '';

        if (value && value[0]) {
            selectedChannelCode = value[0].code || value[0].key;
            selectedChain = cryptoChain && cryptoChain[selectedChannelCode] && cryptoChain[selectedChannelCode][0];
        }

        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        this.setState(
            {
                defaultCryptoOption: '',
                selectedCryptoOptions: value,
                defaultChain: disablePreselectBank ? '' : selectedChain,
                selectedChain: '',
                isSubLoading: true,
                selectedAddress: [],
                cryptoAmount: '',
                currencyAmount: '',
                remindMinAmount: '',
                withdrawErrMsg: '',
                cryptoFeeCharge: '',
                cryptoQrCode: '',
                amount: '',
            },
            () => {
                this.checkChainOptions();
            }
        );
    };

    checkChainOptions = () => {
        const { selectedCryptoOptions, cryptoChain, cryptoOptions, selectedChain, defaultChain } = this.state;
        const { account } = this.props.user;
        let chain = null;

        if (this.props.type === 'deposit' && account) {
            for (let i = 0; i < selectedCryptoOptions.length; i++) {
                if (cryptoChain[selectedCryptoOptions[i].key]) {
                    if (selectedChain) {
                        chain = selectedChain;
                    } else {
                        chain = defaultChain;
                    }
                    this.setState(
                        {
                            selectedCryptoChain: cryptoChain[selectedCryptoOptions[i].key],
                            isLoading: false,
                            isSubLoading: false,
                        },
                        () => {
                            commonUtil.getDepositMinMax(this, 'CT', this.props.user.account.currency, chain.code, chain.coin, chain.bankType);
                        }
                    );
                }
            }

            let userValidate = window.SPL_Member.toShowMemberVerification(account);

            if (userValidate) {
                let showDialog = userValidate.showDialog,
                    showEmail = userValidate.showEmail,
                    showName = userValidate.showName,
                    showPhone = userValidate.showPhone;

                if (showDialog) {
                    this.setState({
                        isLoading: false,
                        isSubLoading: false,
                        memberVerifyPopup: showDialog,
                        showEmail: showEmail,
                        showName: showName,
                        showPhone: showPhone,
                    });
                } else {
                    this.getQrCode();
                }
            }
        } else if (this.props.type === 'withdraw') {
            if (selectedCryptoOptions && selectedCryptoOptions.length > 0) {
                for (let i = 0; i < selectedCryptoOptions.length; i++) {
                    if (cryptoChain[selectedCryptoOptions[i].key]) {
                        this.setState({
                            selectedCryptoChain: cryptoChain[selectedCryptoOptions[i].key],
                            isLoading: false,
                        });
                    }
                }
            } else if (cryptoOptions && cryptoOptions.length > 0) {
                this.setState({
                    selectedCryptoChain: cryptoChain[cryptoOptions[0].key],
                    isLoading: false,
                });
            }
        }
    };

    handleChainChange = (value) => {
        const oldSelectedChain = this.state.selectedChain;
        this.setState(
            {
                defaultChain: '',
                selectedChain: value,
                isSubLoading: true,
                selectedAddress: [],
                cryptoAmount: '',
                currencyAmount: '',
                remindMinAmount: '',
                withdrawErrMsg: '',
                cryptoFeeCharge: '',
                amount: '',
            },
            () => {
                const {
                    enableScrollToDepositStep2 = false,
                    scrollToDepositStep2Attempts = 15,
                    scrollToDepositStep2Delay = 200,
                } = this.props.portal?.settings?.features?.depositSettings || {};

                scrollToDepositStep2(enableScrollToDepositStep2, oldSelectedChain, value, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);

                this.checkChainOptions();

                if (this.props.type === 'deposit') {
                    this.getDepositDisplaySetting();
                } else if (this.props.type === 'withdraw') {
                    this.getCryptoAddress();
                    // this.getCryptoExchangeRate();
                    this.loadCryptoDisplaySettings();
                }
            }
        );
    };

    getQrCode = () => {
        const { countryLanguageKey } = this.props.language;
        const { currency } = this.props.user.account;
        const { defaultChain, selectedChain, amountFieldNotRequired, isCoinAmount } = this.state;
        let option = '';

        if (defaultChain) {
            option = defaultChain;
        } else {
            option = selectedChain;
        }

        let qrParams = {
            bank: option.bankCode ? option.bankCode : option.code,
            methodCode: option.bankCode ? option.bankCode : option.code,
            merchantCode: window.merchantCode,
            portalLanguage: countryLanguageKey,
            merchantBankId: option.id,
        };

        let checkingCodeValue = isCoinAmount.find((isCoin) => isCoin === option.code);

        if (checkingCodeValue) {
            let params = {
                merchantBankId: option.id,
                purpose: 'D',
            };
            window.SPL_Transaction.getCcExchangeRate(params, option.province).then((data) => {
                if (data) {
                    const updateData = {
                        ...this.state,
                        showSubmitForm: true,
                        CCaccountNumber: option.bankType === 'CC' && option.accountNumber ? option.accountNumber : null,
                        ccMinAmount: data.min,
                        CCExchangeRate: data.exchangeRate,
                        isLoading: false,
                        isSubLoading: false,
                        restoreOnLoad: false,
                    };
                    this.setState(updateData);
                }
            });
        } else {
            if (amountFieldNotRequired.includes(option.code)) {
                window.SPL_Transaction.getDepositQr(qrParams, currency).then((data) => {
                    if (data && data.cryptoDetail) {
                        const updateData = {
                            ...this.state,
                            cryptoQrLink: data.cryptoDetail.link,
                            cryptoQrCode: data.cryptoDetail.address,
                            selectedChainMin: (this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min) || data.min,
                            exchangeRate: data.cryptoDetail.exchangeRate,
                            chainBranch: option.branch,
                            isLoading: false,
                            isSubLoading: false,
                        };
                        this.setState(updateData);
                    } else if (data && data.errorMsg) {
                        notification.showNotification('error', data.errorMsg);
                    }
                });
            }
        }
    };

    qrcode = () => {
        const { cryptoQrCode } = this.state;
        return cryptoQrCode;
    };

    getCryptoQrCode = (param) => {
        window.open(param);
    };

    getChainName = () => {
        const { defaultChain, selectedChain } = this.state;
        let chainName = '';

        if (defaultChain) {
            chainName = defaultChain.province;
        } else {
            chainName = selectedChain.province;
        }

        return chainName;
    };

    downloadQrCode = () => {
        const chainName = this.getChainName();

        const qrCodeURL = document.getElementById('cryptoQR').toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let aEl = document.createElement('a');
        aEl.href = qrCodeURL;
        aEl.download = chainName + '.png';
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    };

    getShareInfo = () => {
        this.setState({ getShareInfo: !this.state.getShareInfo });
    };

    copy(text) {
        const { t } = this.props;
        let copied = 'transaction:transaction.withdrawal.copied';
        window.SPL_Other.copyToClipboard(text);
        try {
            let successful = document.execCommand('copy');
            if (successful) {
                notification.showNotification('success', t(copied));
            }
        } catch (err) {
            console.log('Oops, unable to copy');
        }
    }

    getDepositTncReferral = () => {
        this.setState({
            getDepositTncReferral: !this.state.getDepositTncReferral,
        });
    };

    getWithdrawTncReferral = () => {
        this.setState({
            getWithdrawTncReferral: !this.state.getWithdrawTncReferral,
        });
    };

    getMemberVerifyPopup = () => {
        this.setState({ memberVerifyPopup: !this.state.memberVerifyPopup }, () => {
            this.setState({ activeQrCode: false });
        });
    };

    handleNameChange = (e) => {
        this.setState({ name: e.target.value });
    };

    validateFullname = () => {
        const { name } = this.state;
        let thisObj = this;

        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateFullName(name, window.merchantCode).then((errMsg) => {
                thisObj.setState({ nameError: errMsg, requestRefill: false }, () => {
                    thisObj.checkBtnDisable();
                });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    handlePhoneChange = (e) => {
        if (this.isNumber(e)) {
            this.setState({ phone: e.target.value });
        }
    };

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    validatePhone = () => {
        const { phone, telcode } = this.state;
        let thisObj = this;

        return new Promise(function (resolve, reject) {
            let amendPhone = phone.toString();
            if (amendPhone.startsWith('0')) {
                amendPhone = amendPhone.substring(1, amendPhone.length);
            }
            let tempTelCode = telcode.toString();
            if (tempTelCode.startsWith('+')) {
                tempTelCode = tempTelCode.substring(1, tempTelCode.length);
            }

            window.SPL_Register.validatePhone(phone, window.merchantCode, tempTelCode).then((errMsg) => {
                thisObj.setState({ phoneError: errMsg, requestRefill: false }, () => {
                    thisObj.checkBtnDisable();
                });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    getCountryInfo() {
        const { user } = this.props;

        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            if (this.isMount) {
                const { language } = this.props;
                let selectedObj = {};
                let phone = user.account.phone;

                if (phone && phone.length > 0) {
                    let telCode = phone.substring(0, 3);
                    selectedObj = this.getCountryCode(telCodeList, telCode);

                    if (selectedObj === null) {
                        selectedObj = this.getSelectedObj(telCodeList, language.country);
                    }
                } else {
                    selectedObj = this.getSelectedObj(telCodeList, language.country);
                }

                this.setState({
                    telCodeList: telCodeList,
                    telCode: selectedObj.telCode,
                    countryCode: selectedObj.countryCode,
                    currency: selectedObj.currency,
                });
            }
        });
    }

    selectCode = (countryCode) => {
        let selectedObj = this.getSelectedObj(this.state.telCodeList, countryCode);
        this.setState({
            telCode: selectedObj.telCode,
            countryCode: selectedObj.countryCode,
            currency: selectedObj.currency,
        });
    };

    getCountryCode = (telCodeList, telCode) => {
        if (telCode.length > 0) {
            let telCodeNum = telCode.split('+')[1];

            for (let i = 0; i < telCodeList.length; i++) {
                const telCodeObj = telCodeList[i];

                if (telCodeObj.telCode === telCodeNum + '') {
                    let returnObj = {
                        telCode: `+${telCodeObj.telCode}`,
                        countryCode: telCodeObj.country,
                        currency: telCodeObj.currency,
                    };

                    return returnObj;
                }
            }

            return null;
        }
        return null;
    };

    getSelectedObj(telCodeList, country) {
        let returnObj = {};

        for (let i = 0; i < telCodeList.length; i++) {
            const telCodeObj = telCodeList[i];

            if (telCodeObj.country === country) {
                returnObj = {
                    telCode: `+${telCodeObj.telCode}`,
                    countryCode: telCodeObj.country,
                    currency: telCodeObj.currency,
                };
            }
        }

        return returnObj;
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    validateEmail = () => {
        const { email } = this.state;
        let thisObj = this;

        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                thisObj.setState({ emailError: errMsg, requestRefill: false }, () => {
                    thisObj.checkBtnDisable();
                });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    isError = (errMsg) => {
        return errMsg.length > 0;
    };

    checkBtnDisable = () => {
        const { nameError, phoneError, emailError } = this.state;

        if ((nameError && nameError.length > 0) || (phoneError && phoneError.length > 0) || (emailError && emailError.length > 0)) {
            this.setState({ memberVerifyDisable: true });
        } else {
            this.setState({ memberVerifyDisable: false });
        }
    };

    handleMemberVerifySubmit = () => {
        const { id, login } = this.props.user.account;
        const { name, telcode, phone, email } = this.state;
        let params = {
            id: id,
            login: login,
            name: name,
            phone: telcode + phone,
            email: email,
        };

        window.SPL_Member.updateMemberDetails(params).then((data) => {
            if (data) {
                if (data.status === 'S') {
                    this.setState({ memberVerifyPopup: false, isSubLoading: true }, () => {
                        this.getQrCode();
                    });
                } else {
                    this.setState({
                        requestRefill: true,
                        memberVerifyDisable: true,
                    });
                }
            }
        });
    };

    loadTransactionDetails = () => {
        window.SPL_Transaction.loadTransactionDetails().then((data) => {
            if (data) {
                this.setState({ transactionDetails: data });
            }
        });
    };

    getAddressModal(value) {
        this.setState({ addressModal: value });
    }

    // bank_v2 replace to group_with_option
    loadCryptoWithdraw = () => {
        window.SPL_Transaction.getCryptWithdrawBank().then((data) => {
            if (data) {
                let addressData = data.coinAndSupportNetwork,
                    addressOption = [];

                if (addressData) {
                    for (let i = 0; i < Object.keys(addressData).length; i++) {
                        addressOption.push({
                            displayName: Object.keys(addressData)[i],
                        });
                    }

                    this.setState({
                        addressData: addressData,
                        addressOption: addressOption,
                    });
                    this.checkChainOptions();
                }
            }
        });
    };

    loadCryptoDisplaySettings = () => {
        const { countryLanguageKey } = this.props.language;
        const { defaultChain, selectedChain } = this.state;
        let bankCode = '';

        if (defaultChain) {
            bankCode = defaultChain.displayBankCode;
        } else {
            bankCode = selectedChain.displayBankCode;
        }

        window.SPL_Transaction.loadCryptoDisplaySettings(countryLanguageKey).then((data) => {
            if (data) {
                if (data.showCurrencyAndCoin) {
                    this.setState({ showCurrencyAndCoin: true });
                }

                if (data.cryptoWithdrawFee) {
                    this.setState({
                        cryptoWithdrawFee: data.cryptoWithdrawFee,
                    });
                }

                if (data.cryptoWithdrawTimeOut) {
                    let guaranteedUntilTime = window.SPL_Transaction.calculateExpireDate(data.cryptoWithdrawTimeOut);

                    this.setState({ guaranteedUntilTime: guaranteedUntilTime }, () => {
                        setTimeout(() => {
                            this.setState({ getlatestRate: true }, () => {
                                this.validateWithdrawCryptoSubmitBtn();
                            });
                        }, data.cryptoWithdrawTimeOut);
                    });
                }

                if (data.withdrawNotice) {
                    let withdrawChainRemark = data.withdrawNotice[bankCode];

                    this.setState({ withdrawChainRemark: withdrawChainRemark });
                }

                if (data.networkAndCoin) {
                    this.setState({ networkAndCoin: data.networkAndCoin });
                }
            }
        });
    };

    handleCryptoCurrencyChange = (value) => {
        this.setState({ selectedCurrency: value, selectedNetwork: [] }, () => {
            this.filterAddressData();
            this.checkAddressBtnDisable();
            this.checkOtpBtnDisable();
        });
    };

    filterAddressData = () => {
        const { networkAndCoin, selectedCurrency, addressData } = this.state;
        let networkFilter = '',
            networkOption = [],
            addressFilter = [];

        if (selectedCurrency && selectedCurrency.length > 0) {
            networkFilter = networkAndCoin[selectedCurrency[0].displayName];
            addressFilter = addressData[selectedCurrency[0].displayName];
            for (let j = 0; j < addressFilter.length; j++) {
                for (let i = 0; i < networkFilter.length; i++) {
                    if (addressFilter[j].indexOf(networkFilter[i]) !== -1) {
                        networkOption.push({ displayName: networkFilter[i] });
                    }
                }
            }
        }

        this.setState({ networkOption: networkOption });
    };

    handleCryptoNetworkChange = (value) => {
        this.setState({ selectedNetwork: value }, () => {
            this.checkAddressBtnDisable();
            this.checkOtpBtnDisable();
        });
    };

    handleAddressChange = (e) => {
        if (e.target.value.match(/^([^`~!@#$%^&*()\-_=+[{\]}\\\|;:',<.>\/? ]*)$/)) {
            this.setState({ address: e.target.value }, () => {
                this.checkAddressBtnDisable();
                this.checkOtpBtnDisable();
            });
        }
    };

    checkAddressBtnDisable = () => {
        const { selectedCurrency, selectedNetwork, address, otpCode, fields } = this.state;

        const { authSettingsReducer, portal } = this.props;
        const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MODULE_TYPE.CRYPTO_ADDRESS];
        const _addCryptoAddressRequireOTP = _msCryptoAddressModule?.gaEnabled ? false : portal?.settings?._addCryptoAddressRequireOTP;
        const isGaFieldValid = _msCryptoAddressModule?.gaEnabled ? (fields?.gaCode?.value ? true : false) : true;

        if (
            selectedCurrency &&
            selectedCurrency.length > 0 &&
            selectedNetwork &&
            selectedNetwork.length > 0 &&
            address &&
            // remain existing otp code flow, but if GA enabled, will make addCryptoAddressRequireOTP become false.
            ((_addCryptoAddressRequireOTP && otpCode) || (!_addCryptoAddressRequireOTP && !otpCode)) &&
            isGaFieldValid
        ) {
            this.setState({ cryptoAddressDisable: false });
        } else {
            this.setState({ cryptoAddressDisable: true });
        }
    };

    onAddCryptoAddressSuccessCb = () => {
        this.setState(
            {
                addressModal: false,
                isSubLoading: true,
                otpCode: '',
                address: '',
                countdownTimer: 0,
                submittedOTP: false,
                addAddressErrMsg: '',
                cryptoAddressDisable: true,
                isSubmitting: false,
            },
            () => {
                this.getCryptoAddress();
            }
        );
    };

    handleCryptoAddressSubmit = () => {
        this.setState({ isSubmitting: true });

        const { authSettingsReducer } = this.props;
        const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MODULE_TYPE.WITHDRAWAL_CRYPTO];

        const { selectedCurrency, selectedNetwork, address, fields } = this.state;

        const params = {
            address: address,
            cryptoCoin: selectedCurrency[0].displayName,
            cryptoNetwork: selectedNetwork[0].displayName,
            ...(_msCryptoAddressModule?.gaEnabled && {
                verificationType: OVT.CRYPTO_ADDRESS,
                verificationMethod: OVM.GA, // future expect preferMethod, currently new MFA UI only will got GA
                verifyKey: fields?.gaCode?.value, // future expect otp field
            }),
        };

        const onError = (errMsg) => {
            this.setState({
                addressModal: true,
                addAddressErrMsg: errMsg,
                isSubmitting: false,
            });
        };

        APIClient.createMemberCryptoAddress(params, this.onAddCryptoAddressSuccessCb, onError);
    };

    getCryptoAddress = () => {
        const { defaultChain, selectedChain } = this.state;
        let option = '';

        if (defaultChain) {
            option = defaultChain;
        } else {
            option = selectedChain;
        }

        window.SPL_Transaction.getMemberCryptoAddress().then((data) => {
            if (data) {
                let params = {
                    selectedCrypto: option,
                    orginalAddressList: data.orginalAddressList,
                };

                window.SPL_Transaction.filterAddressAndGetExchangeRate(params).then((filterData) => {
                    this.setState({
                        cryptoExchangeRate: filterData.cryptoExchangeRate,
                        memberAddressOption: filterData.cryptoAddressList,
                        chainBranch: option.branch,
                        isSubLoading: false,
                    });
                });
            }
        });
    };

    // getCryptoExchangeRate = () => {
    //   const { defaultChain, selectedChain } = this.state;
    //   let option = '';

    //   if (defaultChain) {
    //     option = defaultChain;
    //   } else {
    //     option = selectedChain;
    //   }

    //   let params = {
    //     merchantBankId: option.id
    //   };

    //   window.SPL_Transaction.getCryptoExchangeRate(params).then(data => {
    //     if (data) {
    //       if (data.status === 'S') {
    //         this.setState({ cryptoExchangeRate: data.cryptoExchangeRate });
    //       }
    //     }
    //   });
    // }

    handleMemberAddressChange = (value) => {
        this.setState({ selectedAddress: value }, () => {
            this.validateWithdrawCryptoSubmitBtn();
        });
    };

    handleCryptoAmountChange = (value) => {
        if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
            this.setState({ cryptoAmount: value, withdrawErrMsg: '' }, () => {
                this.calculateExchangeRate(value, true);
            });
        }
    };

    handleCurrencyAmountChange = (value) => {
        if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
            this.setState({ currencyAmount: value, withdrawErrMsg: '' });

            if (!value || value < Number(this.props.limit.minLimit) || value > Number(this.props.limit.dailyMaxAmount)) {
                this.setState(
                    {
                        cryptoAmount: 0,
                        remindMinAmount: `${this.props.t('transaction:transaction.min', 'Min')}: ${Number(this.props.limit.minLimit).toFixed(2)} / ${this.props.t(
                            'transaction:transaction.max',
                            'Max'
                        )}: ${Number(this.props.limit.dailyMaxAmount).toFixed(2)}`,
                    },
                    () => {
                        this.validateWithdrawCryptoSubmitBtn();
                    }
                );
            } else {
                this.setState({ remindMinAmount: '' });
                this.calculateExchangeRate(value, false);
            }
        }
    };

    calculateExchangeRate = (amount, isCrypto) => {
        const { defaultChain, selectedChain, cryptoExchangeRate, cryptoWithdrawFee } = this.state;
        let option = '';

        if (defaultChain) {
            option = defaultChain;
        } else {
            option = selectedChain;
        }

        let params = {
            amount: Number(amount),
            isCrypto: isCrypto,
            selectedCrypto: option,
            cryptoWithdrawFee: cryptoWithdrawFee,
            cryptoExchangeRate: cryptoExchangeRate,
        };
        let calculateExchangeRate = window.SPL_Transaction.calculateExchangeRate(params);

        if (isCrypto) {
            this.setState({ currencyAmount: calculateExchangeRate.amount }, () => {
                if (calculateExchangeRate.amount > Number(this.props.limit.minLimit) && calculateExchangeRate.amount < Number(this.props.limit.dailyMaxAmount)) {
                    this.setState({ remindMinAmount: '' }, () => {
                        this.validateWithdrawCryptoSubmitBtn();
                    });
                } else {
                    this.validateWithdrawCryptoSubmitBtn();
                }
            });
        } else {
            this.setState({ cryptoAmount: calculateExchangeRate.cryptoAmount }, () => {
                this.validateWithdrawCryptoSubmitBtn();
            });
        }

        if (calculateExchangeRate.cryptoFeeCharge || calculateExchangeRate.cryptoFeeCharge === 0) {
            this.setState({
                cryptoFeeCharge: calculateExchangeRate.cryptoFeeCharge,
            });
        }
    };

    validateWithdrawCryptoSubmitBtn = () => {
        const { defaultChain, selectedChain, selectedAddress, cryptoAmount, currencyAmount, getlatestRate, remindMinAmount } = this.state;
        let option = '';

        if (defaultChain) {
            option = defaultChain;
        } else {
            option = selectedChain;
        }

        if (option === '' || (selectedAddress && selectedAddress.length === 0) || getlatestRate || cryptoAmount === '' || currencyAmount === '' || remindMinAmount !== '') {
            this.setState({ disabledWithdrawCryptoButton: true });
        } else {
            this.setState({ disabledWithdrawCryptoButton: false });
        }
    };

    getMainWallet() {
        let component = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMainWallet()
                .then((mainWallet) => {
                    component.setState({ mainWallet: mainWallet.data });
                    resolve(mainWallet.data);
                })
                .catch((err) => {
                    component.setState({ iconLoading: false });
                });
        });
    }

    handleWithdrawCryptoSubmit = async () => {
        const { authSettingsReducer, popupReducer, dispatch } = this.props;
        const _msWithdrawalCryptoAModule = authSettingsReducer?.processedData?.[MODULE_TYPE.WITHDRAWAL_CRYPTO];
        const _config = {
            gaEnabled: _msWithdrawalCryptoAModule?.gaEnabled,
            sixPinEnabled: _msWithdrawalCryptoAModule?.sixPinEnabled,
        };

        const { currencyAmount } = this.state;

        this.setState({ isSubmitting: true });

        this.getMainWallet().then((mainWallet) => {
            if (currencyAmount > mainWallet.balance) {
                this.setState({
                    withdrawErrMsg: 'transaction:transaction.transfer.insuffficientMainWallet',
                    isSubmitting: false,
                });
            }
            return;
        });

        try {
            const mainWallet = await this.getMainWallet();

            if (currencyAmount > mainWallet.balance) {
                this.setState({ withdrawErrMsg: 'transaction:transaction.transfer.insuffficientMainWallet', isSubmitting: false });
                return;
            }

            // if enable GA on crypto withdrawal module, but haven't got enter pin number for GA yet, then will trigger pin popup
            if (_msWithdrawalCryptoAModule?.gaEnabled && !popupReducer?.enteredPin) {
                dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, true));
                dispatch(popupAction.setSubmitSuccessCb(this.submitWithdrawCrypto));
                this.setState({ isSubmitting: false });
            } else {
                this.submitWithdrawCrypto();
            }
        } catch (err) {
            console.error(err);
        }
    };

    submitWithdrawCrypto = () => {
        const { name } = this.props.user.account;
        const { currencyAmount, defaultChain, selectedChain, cryptoWithdrawFee, cryptoExchangeRate, selectedAddress } = this.state;
        const { t, authSettingsReducer, popupReducer, dispatch } = this.props;
        const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MODULE_TYPE.CRYPTO_ADDRESS];

        this.setState({ isSubmitting: true });
        dispatch(portalAction.setIsSubmitting(true));

        let option = '',
            memberAddress = {};

        if (defaultChain) {
            option = defaultChain;
        } else {
            option = selectedChain;
        }

        if (selectedAddress && selectedAddress.length > 0) {
            memberAddress = selectedAddress[0];
        }

        let params = {
            amount: currencyAmount,
            selectedCrypto: option,
            cryptoWithdrawFee: cryptoWithdrawFee,
            cryptoExchangeRate: cryptoExchangeRate,
            selectedMemberAddress: memberAddress,
            accountName: name,
            ...(_msCryptoAddressModule?.otp_feature && {
                verificationType: OVT.WITHDRAWAL_CRYPTO,
            }),
            ...(_msCryptoAddressModule?.gaEnabled && {
                verificationMethod: OVM.GA,
                verifyKey: popupReducer?.enteredPin,
            }),
        };

        window.SPL_Transaction.getAllowCryptoTransaction()
            .then((cryptoDetails) => {
                if (cryptoDetails && cryptoDetails.blockWithdraw) {
                    let autoclosedTimmer = Number(cryptoDetails.minutes) * 60000;
                    notification.showNotification('error', t('error:error.cryptoAccountUnderReview'), { autoClose: autoclosedTimmer }, true);
                } else {
                    window.SPL_Transaction.submitWithdrawCrypto(params).then((data) => {
                        if (data) {
                            if (data.status === 'S') {
                                this.setState({
                                    withdrawSuccessMsg: 'transaction:transaction.withdrawal.request-success',
                                    // reset fields upon success
                                    selectedAddress: [],
                                    currencyAmount: '',
                                    cryptoAmount: '',
                                });
                                dispatch(popupAction.setEnteredPin(''));
                            } else {
                                if (data.errorCode === 2717) {
                                    dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                                }
                                // if using GA then use notification, else show error on fields
                                if (_msCryptoAddressModule?.gaEnabled) {
                                    notification.showNotification('error', t(`settings:settings.messages.error.${data?.errorCode}`, data.msg), {
                                        autoClose: 2000,
                                    });
                                } else {
                                    this.setState({ withdrawErrMsg: t(data.msg) });
                                }
                            }
                        }
                    });
                }
            })
            .catch(() => {})
            .finally(() => {
                this.setState({ isSubmitting: false });
                dispatch(portalAction.setIsSubmitting(false));
            });
    };

    onReload() {
        this.setState({ reload: true });

        this.loadCryptoDisplaySettings();

        setTimeout(() => {
            this.setState({ reload: false, getlatestRate: false }, () => {
                this.validateWithdrawCryptoSubmitBtn();
            });
        }, 1000);
    }

    sendOtpCode = () => {
        if (!this.state.disableOtpBtn) {
            window.SPL_Member.triggerSendVerification('CRYPTO', null, this.state.address)
                .then(() => {
                    this.setState(
                        {
                            submittedOTP: true,
                            countdownTimer: 60,
                            addAddressErrMsg: '',
                            otpErrMsg: false,
                        },
                        () => {
                            this.timerCount();
                        }
                    );
                })
                .catch((err) => {
                    let errMsg = '';
                    const error = err?.message?.response?.data;
                    if (error?.message === 'Maximum Attempts Exceed' || error?.description === 'Maximum Attempts Exceed') {
                        errMsg = 'transaction:transaction.deposit.crypto.otp-maxAttempts';
                    }
                    this.setState({ addAddressErrMsg: errMsg, otpErrMsg: true, otpCode: '' });
                });
        }
    };

    timerCount() {
        let vm = this,
            secondCount = 0;

        if (vm.state.countdownTimer > 0) {
            setTimeout(() => {
                secondCount = vm.state.countdownTimer - 1;
                vm.setState({ countdownTimer: secondCount });
                this.timerCount();
            }, 1000);
        }
    }

    handleOtpChange = (e) => {
        this.setState({ otpCode: e.target.value }, () => {
            if (this.state.otpCode.length === 6) {
                this.validateOtp();
            }
        });
    };

    validateOtp = () => {
        window.SPL_Member.validateOtp(null, this.state.otpCode, this.state.address)
            .then((result) => {
                if (result) {
                    this.checkAddressBtnDisable();
                } else {
                    if (!this.state.otpErrMsg) {
                        this.setState({
                            addAddressErrMsg: 'global:global.verification.codeIncorrect',
                        });
                    }
                }
            })
            .catch(() => {
                if (!this.state.otpErrMsg) {
                    this.setState({
                        addAddressErrMsg: 'global:global.verification.generalError',
                    });
                }
            });
    };

    checkOtpBtnDisable = () => {
        const { selectedCurrency, selectedNetwork, address } = this.state;

        if (selectedCurrency && selectedCurrency.length > 0 && selectedNetwork && selectedNetwork.length > 0 && address) {
            this.setState({ disableOtpBtn: false });
        } else {
            this.setState({ disableOtpBtn: true });
        }
    };

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'cryptoSettings',
            });
            const { filteredDepositApiJson } = this.state;
            const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                showOnlineTransactionMode: tempSettings.showOnlineTransactionMode,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                customCountdown: tempSettings.customCountdown,
            };

            this.setState({ compSettings }, () => {
                const { showCustomArticle, customCountdown } = this.state.compSettings;
                if (showCustomArticle) {
                    this.readCustomDepositArticle();
                }

                trans_handlePGDepositCountDown(this, false, customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: false });
                resolve();
            });
        });
    }

    handleAmountChange = (value) => {
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                const updateAmount = {
                    ...this.state,
                    amount: value,
                    message: '',
                };
                this.setState(updateAmount, () => {
                    this.validateDepositAmount();
                });
            }
        } else {
            const updateAmount = {
                ...this.state,
                amount: '',
                message: '',
            };
            this.setState(updateAmount, () => {
                this.validateDepositAmount();
            });
        }
    };

    getPaymentGatewaySetting() {
        return new Promise((resolve) => {
            window.SPL_Transaction.getPaymentGatewaySetting().then((data) => {
                if (data) {
                    const { isCoinAmount } = data;
                    const amountFieldNotRequired = data.amountFieldNotRequired;
                    const updatedPaymentGetwayDetails = {
                        ...this.state,
                        isCoinAmount: isCoinAmount,
                        amountFieldNotRequired: amountFieldNotRequired,
                    };
                    this.setState(updatedPaymentGetwayDetails, () => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        });
    }

    validateDepositAmount = () => {
        const { amount, depositMinMaxLimit } = this.state;
        const depositLimitOBj = { minLimitQuick: depositMinMaxLimit?.min, maxLimitQuick: depositMinMaxLimit?.max };
        window.SPL_Transaction.validateDepositAmount(amount, depositLimitOBj, 'quickPay').then((errMsg) => {
            if (this.isMount) {
                let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);

                const updateErrorMsg = {
                    ...this.state,
                    amountErrMsg: errMsg,
                    limitAmount: errMsgLimitAmount.limitAmount,
                    currency: errMsgLimitAmount.currency,
                };

                this.setState(updateErrorMsg, () => {
                    this.checkError();
                });
            }
        });
    };

    checkError() {
        const { amount, amountErrMsg, selectedBank, submitRequiredBank } = this.state;

        const isInvalidAmount = !amount || amountErrMsg.length > 0;
        const isInvalidBank = submitRequiredBank && !selectedBank;
        const updateDepositButton = {
            ...this.state,
            disabledDepositButton: isInvalidAmount || isInvalidBank,
        };

        this.setState(updateDepositButton);
    }

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;
        const { depositMinMaxLimit } = this.state;

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                limitAmount = window.SPL_Other.formatAmount(depositMinMaxLimit.min);
            } else if (errMsg.includes('invalidMaxAmount')) {
                limitAmount = window.SPL_Other.formatAmount(depositMinMaxLimit.max);
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    componentDidUpdate(_, prevState) {
        if (
            this.state.depositMinMaxLimit !== prevState.depositMinMaxLimit ||
            this.state.defaultChain !== prevState.defaultChain ||
            this.state.selectedChain !== prevState.selectedChain ||
            this.state.selectedChainMin !== prevState.selectedChainMin
        ) {
            this.readCustomDepositArticle();
        }
    }

    readCustomDepositArticle() {
        const { compSettings, selectedChainMin, depositMinMaxLimit, selectedChain, defaultChain } = this.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'ct' : '';
        commonUtil.readCustomDepositArticle(this, customFilename, (content) => {
            // refer: super-portal-2 src/webapp/portal/settings/deposit/crypto/crypto.controller.js line 353
            const getMinLimit = window.SPL_Other.formatAmount(selectedChainMin || depositMinMaxLimit?.min);
            try {
                let stringified = JSON.stringify(content);
                stringified = stringified.replaceAll('{{minLimit}}', getMinLimit).replaceAll('{{selectedChannel}}', selectedChain?.displayName || defaultChain?.displayName);
                return JSON.parse(stringified);
            } catch (e) {
                return content;
            }
        });
    }

    submitCryptoForm() {
        const { disabledDepositButton, filteredDepositApiJson, compSettings, depositSubmitCountdown, amount, selectedChain, defaultChain } = this.state;
        const { customCountdown } = compSettings;
        const { depositSubmission, t, language } = this.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];
        let option = '';

        if (defaultChain) {
            option = defaultChain;
        } else {
            option = selectedChain;
        }

        if (!disabledDepositButton) {
            if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
                notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
            } else {
                const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
                let method = option;
                let selectedBankCode = option.code;
                let callBackUrl = '/myaccount/deposit';
                let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
                let stateUrl = `/${langPath}/quickpayredirect`;

                const customParam = {
                    bank: selectedBankCode,
                    cryptoAmount: amount,
                    returnUrl: null,
                    preferredWallet: null,
                    merchantBankId: method.id,
                    methodCode: method.code,
                };
                window.SPL_Transaction.requestDepositOnlineTransfer(
                    method,
                    selectedBankCode,
                    amount,
                    null,
                    null,
                    stateUrl,
                    this.props.language.key,
                    callBackUrl,
                    null,
                    null,
                    customParam
                ).then((data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(this, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });

                        const updateOnlinetransferMsg = {
                            ...this.state,
                            amount: '',
                            status: 'S',
                            message: 'transaction:transaction.deposit.onlinetransfer.redirectVendor',
                        };
                        this.setState(updateOnlinetransferMsg);
                    }
                });
            }
        }
    }

    onSubmitCryptoClick() {
        this.submitCryptoForm();
    }

    onHandleAmountChange(value) {
        this.handleAmountChange(value);
    }

    onValidateDepositAmountBlur() {
        this.validateDepositAmount();
    }

    onInputFieldChange = (event) => {
        const { name, value } = event.target;
        this.setState(
            (prevState) => ({
                fields: {
                    ...prevState.fields,
                    [name]: { ...prevState.fields[name], value: value },
                },
            }),
            () => {
                this.checkAddressBtnDisable();
            }
        );
    };

    render() {
        const {
            defaultCryptoOption,
            cryptoOptions,
            selectedCryptoOptions,
            selectedCryptoChain,
            cryptoQrCode,
            selectedChainMin,
            exchangeRate,
            defaultChain,
            selectedChain,
            cryptoQrLink,
            chainBranch,
            chainRemark,
            isLoading,
            isSubLoading,
            memberAddressOption,
            selectedAddress,
            guaranteedUntilTime,
            cryptoExchangeRate,
            withdrawChainRemark,
            transactionDetails,
            getlatestRate,
            disabledDepositButton,
            depositMinMaxLimit,
            amountErrMsg,
            isCoinAmount,
            amount,
            depositAmountOptions,
        } = this.state;
        const { key } = this.props.language;
        const { currency } = this.props.user.account;

        // passed props
        const { hideAddBankAccount, amountOptions } = this.props;
        let chainName = '';
        let chainCode = '';
        let chainCoin = '';

        if (defaultChain) {
            chainName = defaultChain.province;
            chainCode = defaultChain.code;
            chainCoin = defaultChain.coin;
        } else {
            chainName = selectedChain.province;
            chainCode = selectedChain.code;
            chainCoin = selectedChain.coin;
        }

        //control deposit channel
        if (defaultCryptoOption && cryptoOptions && defaultCryptoOption.length > 0 && cryptoOptions.length < 2) {
            this.handleCryptoChannelChange(defaultCryptoOption);
        }

        let transactionNoteProps = {
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
        };

        const coinAmountDisable = isCoinAmount.find((isCoin) => isCoin === chainCode);

        return (
            this.props.user.account && (
                <Translation>
                    {(t) => (
                        <div className='app-onlinetransfer crypto-deposit'>
                            <ul>
                                {this.props.type === 'deposit' && (
                                    <li>
                                        <div className='item deposit-channel'>
                                            {cryptoOptions && cryptoOptions.length > 1 && (
                                                <p>
                                                    {t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}{' '}
                                                    <span className='text-danger asterisk'>*</span>
                                                </p>
                                            )}
                                            <div className='deposit-amount'>
                                                {cryptoOptions ? (
                                                    <Fragment>
                                                        {cryptoOptions.length > 1 && (
                                                            <Select
                                                                key='cryptoOptions'
                                                                placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                                options={cryptoOptions}
                                                                valueField='cryptoOptions'
                                                                labelField='displayName'
                                                                values={defaultCryptoOption && defaultCryptoOption.length > 0 ? defaultCryptoOption : selectedCryptoOptions}
                                                                onChange={(values) => this.handleCryptoChannelChange(values)}
                                                                searchable={false}
                                                            />
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <SmallLoading></SmallLoading>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                )}

                                {this.props.type === 'deposit' && !this.props.screen.isMobile && <TransactionNote {...transactionNoteProps} />}
                            </ul>

                            {isLoading ? (
                                <ul>
                                    <li>
                                        <div className='item'>
                                            <p></p>
                                            <div>
                                                <SmallLoading></SmallLoading>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            ) : (
                                <Fragment>
                                    <ul className='small-banklist'>
                                        <li>
                                            <div className='item'>
                                                <p className='deposit-options'></p>
                                                <div className='bank-selector'>
                                                    {selectedCryptoOptions && (
                                                        <ul className='bank-list'>
                                                            {(selectedCryptoChain || []).map((chain, index) => {
                                                                const pgCode = `${chain.code}_${chain.network}`;
                                                                const isActive =
                                                                    this.state.defaultChain?.displayName === chain.displayName ||
                                                                    this.state.selectedChain?.displayName === chain.displayName;
                                                                return (
                                                                    <li className='small-bank' key={index}>
                                                                        <div className='banklist-group member-bankacc' onClick={() => this.handleChainChange(chain)}>
                                                                            <label>
                                                                                <input
                                                                                    type='radio'
                                                                                    name='bank-account'
                                                                                    onChange={() => this.handleChainChange(chain)}
                                                                                    checked={isActive}
                                                                                />
                                                                                <span className='checkmark'></span>
                                                                                <div className='banklist-group'>
                                                                                    <div className='group-box'>
                                                                                        <div className={`bank-img ${isActive ? 'bank-on' : 'bank-off'}`}>
                                                                                            <CryptoBankIcon
                                                                                                bankCode={`${chain.coin}_${chain.network}`}
                                                                                                coin={chain.province}
                                                                                                isActive={isActive}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            className={`bank-name ${
                                                                                                (this.state.defaultChain &&
                                                                                                    this.state.defaultChain.displayName === chain.displayName) ||
                                                                                                (this.state.selectedChain &&
                                                                                                    this.state.selectedChain.displayName === chain.displayName)
                                                                                                    ? 'bank-selected'
                                                                                                    : ''
                                                                                            }`}
                                                                                        >
                                                                                            {chain.displayName}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    {selectedChain ? (
                                        <>
                                            {this.props.type === 'deposit' && (
                                                <Fragment>
                                                    {isSubLoading ? (
                                                        <ul>
                                                            <li>
                                                                <div className='item'>
                                                                    <p></p>
                                                                    <div>
                                                                        <SmallLoading></SmallLoading>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ) : coinAmountDisable ? (
                                                        <ul className='deposit-bottom' id='deposit-bottom'>
                                                            <>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p className='deposit-options'>
                                                                            {t('transaction:transaction.deposit.coinAmount')} <span className='text-danger asterisk'>*</span>
                                                                        </p>
                                                                        <div className='deposit-amounts'>
                                                                            {depositMinMaxLimit ? (
                                                                                <input
                                                                                    name='amount'
                                                                                    className={`form-control-inner`}
                                                                                    id='amount'
                                                                                    min='0'
                                                                                    value={amount}
                                                                                    placeholder={
                                                                                        t('transaction:transaction.deposit.crypto.minLimit', 'Min Limit') +
                                                                                        chainCoin +
                                                                                        ' ' +
                                                                                        window.SPL_Other.formatAmount(depositMinMaxLimit.min)
                                                                                    }
                                                                                    onChange={(e) => this.onHandleAmountChange(e.target.value)}
                                                                                    onBlur={() => this.onValidateDepositAmountBlur()}
                                                                                />
                                                                            ) : (
                                                                                <SmallLoading></SmallLoading>
                                                                            )}

                                                                            {(amountErrMsg || []).map((errMsg, index) => {
                                                                                return (
                                                                                    <div key={index} className='invalid-feedback important-notice'>
                                                                                        {this.props.screen.isMobile && <i className='icon-tip'></i>}
                                                                                        <Trans i18nKey={errMsg}></Trans> {`${this.state.currency} ${this.state.limitAmount}`}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='item amount-select'>
                                                                        <p className='deposit-options'></p>
                                                                        <div className='amount-select-btn'>
                                                                            {depositAmountOptions.map((item) => (
                                                                                <button className='btn-amount' onClick={() => this.handleAmountChange(item)}>
                                                                                    {item}
                                                                                </button>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p className='deposit-options'></p>
                                                                        <div className='deposit-crypto-button'>
                                                                            <button
                                                                                type='submit'
                                                                                className='btn-normal btn-deposit'
                                                                                disabled={disabledDepositButton}
                                                                                onClick={() => this.onSubmitCryptoClick()}
                                                                            >
                                                                                <span>{t('transaction:transaction.deposit.onlinetransfer.depositbtn')}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        </ul>
                                                    ) : cryptoQrCode ? (
                                                        <Fragment>
                                                            <ul className='qrCode'>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p>
                                                                            {t('transaction:transaction.scanQrCode', 'Scan QR Code')}{' '}
                                                                            <span className='text-danger asterisk'>*</span>
                                                                        </p>
                                                                        <div className='qr-box'>
                                                                            <div className='box-image'>
                                                                                <QRCode id='cryptoQR' value={this.qrcode()} size='159' className='crypto-qr-img' />
                                                                                <span onClick={() => this.downloadQrCode()}>
                                                                                    {t('transaction:transaction.saveQr', 'Save QR')}
                                                                                </span>
                                                                            </div>

                                                                            <div className='box-info'>
                                                                                <p className='info-minDeposit'>
                                                                                    {t('transaction:transaction.minDeposit', 'Min Deposit')} = {chainName} {selectedChainMin}
                                                                                </p>
                                                                                <p className='info-currentRate'>
                                                                                    {t('transaction:transaction.currentRate', 'Current exchange rate')}
                                                                                </p>
                                                                                <p className='info-rate'>
                                                                                    {chainName} 1 = {currency} {exchangeRate && exchangeRate.toFixed(2)}
                                                                                </p>
                                                                                {chainName !== 'BTC' && <p className='info-chain'>CHAIN : {chainBranch}</p>}
                                                                                <p className='info-scanQr'>
                                                                                    {t(
                                                                                        'transaction:transaction.scanQrCodeWithWallet',
                                                                                        'SCAN THE QR CODE WITH YOUR CHAIN WALLET',
                                                                                        {
                                                                                            chain: chainName,
                                                                                        }
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className='share-qrCode'>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p>
                                                                            {t('transaction:transaction.sendToChainAddress', 'or send to this Chain address', {
                                                                                chain: chainName,
                                                                            })}
                                                                        </p>
                                                                        <div className='share-box'>
                                                                            <div className='share-column'>
                                                                                <span>{cryptoQrCode}</span>
                                                                                {cryptoQrLink && (
                                                                                    <button
                                                                                        className='checkStatus btn-normal-green'
                                                                                        onClick={() => this.getCryptoQrCode(cryptoQrLink)}
                                                                                    >
                                                                                        {t('transaction:transaction.checkStatus', 'Check Status')}
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                            <img
                                                                                className='share-btn'
                                                                                onClick={() => this.getShareInfo()}
                                                                                src='/public/html/images/common_img/icon-share.png'
                                                                                alt=''
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            {chainRemark && chainRemark.length > 0 && (
                                                                <ul>
                                                                    <li>
                                                                        <div className='item'>
                                                                            <div className='remark-box'>
                                                                                <p className='remark-title'>{t('transaction:transaction.reminder', 'Reminder')}:</p>
                                                                                <ul className='remark-content'>
                                                                                    {chainRemark.map((info, index) => (
                                                                                        <Fragment key={index}>
                                                                                            <li>{info}</li>
                                                                                        </Fragment>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                    <li>
                                                                        <span className='tnc-apply mb-only' onClick={() => this.getDepositTncReferral()}>
                                                                            * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            )}
                                                        </Fragment>
                                                    ) : (
                                                        !cryptoQrCode &&
                                                        !coinAmountDisable && (
                                                            <ul className='deposit-bottom' id='deposit-bottom'>
                                                                <Fragment>
                                                                    <li>
                                                                        <div className='item'>
                                                                            <p className='deposit-options'>
                                                                                {t('transaction:transaction.deposit.onlinetransfer.amount')}{' '}
                                                                                <span className='text-danger asterisk'>*</span>
                                                                            </p>

                                                                            <div className='deposit-amount'>
                                                                                <input
                                                                                    name='amount'
                                                                                    className={`form-control-inner ${this.state.amountError ? 'is-invalid' : ''}`}
                                                                                    id='name'
                                                                                    min='0'
                                                                                    value={this.state.amount}
                                                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                                        min: window.SPL_Other.formatAmount(depositMinMaxLimit && depositMinMaxLimit.min),
                                                                                        max: window.SPL_Other.formatAmount(depositMinMaxLimit && depositMinMaxLimit.max),
                                                                                    })}
                                                                                    onChange={(e) => this.handleAmountChange(e.target.value)}
                                                                                    // onKeyPress={(e) => this.disableDot(e)}
                                                                                    onBlur={this.validateDepositAmount}
                                                                                />
                                                                                {(amountErrMsg || []).map((errMsg, index) => {
                                                                                    return (
                                                                                        <div key={index} className='invalid-feedback important-notice'>
                                                                                            {this.props.screen.isMobile && <i className='icon-tip'></i>}
                                                                                            <Trans i18nKey={errMsg}></Trans>{' '}
                                                                                            {`${this.state.currency} ${this.state.limitAmount}`}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='item amount-select'>
                                                                            <p className='deposit-options'></p>
                                                                            <div className='amount-select-btn'>
                                                                                {depositAmountOptions.map((item) => (
                                                                                    <button className='btn-amount' onClick={() => this.handleAmountChange(item)}>
                                                                                        {item}
                                                                                    </button>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='item'>
                                                                            <p className='deposit-options'></p>
                                                                            <div className='deposit-crypto-button'>
                                                                                <button
                                                                                    type='submit'
                                                                                    className='btn-normal btn-deposit'
                                                                                    disabled={disabledDepositButton}
                                                                                    onClick={() => this.onSubmitCryptoClick()}
                                                                                >
                                                                                    <span>{t('transaction:transaction.deposit.onlinetransfer.depositbtn')}</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </Fragment>
                                                            </ul>
                                                        )
                                                    )}
                                                </Fragment>
                                            )}

                                            {this.props.type === 'withdraw' && (
                                                <Fragment>
                                                    {isSubLoading ? (
                                                        <ul>
                                                            <li>
                                                                <div className='item'>
                                                                    <p></p>
                                                                    <div>
                                                                        <SmallLoading></SmallLoading>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ) : (
                                                        <Fragment>
                                                            <ul>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p></p>
                                                                        <div className='bank-selector box-info'>
                                                                            <p className='title'>{t('transaction:transaction.currentRate', 'Current exchange rate')}</p>
                                                                            <ul className='chain-currency-rate-info-container'>
                                                                                <li className='chain-currency-rate-info'>
                                                                                    {chainBranch !== 'BTC' && <p>CHAIN : {chainBranch}</p>}
                                                                                    <p>
                                                                                        1 {chainName} = {currency} {cryptoExchangeRate.toFixed(2)}
                                                                                    </p>
                                                                                </li>

                                                                                <li className='chain-guaranteed-time'>
                                                                                    <p>
                                                                                        {t('transaction:transaction.deposit.crypto.guaranteedUntil', 'Guaranteed until') +
                                                                                            ' :'}
                                                                                    </p>
                                                                                    <p>{guaranteedUntilTime}</p>
                                                                                    {getlatestRate && (
                                                                                        <SVGElements
                                                                                            className={`icon-reload ${this.state.reload && 'start'}`}
                                                                                            name='reload-icon'
                                                                                            onClick={this.onReload.bind(this)}
                                                                                        />
                                                                                    )}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className='channel'>
                                                                <li>
                                                                    <div className='item'>
                                                                        {cryptoOptions && cryptoOptions.length > 1 && (
                                                                            <p>
                                                                                {t('transaction:transaction.withdrawal.channel', 'Withdrawal Channel')}{' '}
                                                                                <span className='text-danger asterisk'>*</span>
                                                                            </p>
                                                                        )}
                                                                        <div className='deposit-amount'>
                                                                            {cryptoOptions && cryptoOptions.length > 1 && (
                                                                                <Select
                                                                                    key='cryptoOptions'
                                                                                    placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                                                    valueField='cryptoOptions'
                                                                                    options={cryptoOptions}
                                                                                    labelField='displayName'
                                                                                    values={
                                                                                        defaultCryptoOption && defaultCryptoOption.length > 0
                                                                                            ? defaultCryptoOption
                                                                                            : selectedCryptoOptions
                                                                                    }
                                                                                    onChange={(values) => this.handleCryptoChannelChange(values)}
                                                                                    searchable={false}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className='address'>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p>
                                                                            {t('transaction:transaction.address', 'Address')} <span className='text-danger asterisk'>*</span>
                                                                        </p>
                                                                        <div className='deposit-amount'>
                                                                            <Select
                                                                                key='address'
                                                                                placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                                                options={memberAddressOption}
                                                                                valueField='address'
                                                                                labelField='address'
                                                                                values={selectedAddress}
                                                                                onChange={(values) => this.handleMemberAddressChange(values)}
                                                                                searchable={false}
                                                                            />

                                                                            {!hideAddBankAccount && (
                                                                                <AddCryptoAddressButton
                                                                                    module={MODULE_TYPE?.CRYPTO_ADDRESS}
                                                                                    onClick={() => this.getAddressModal(true)}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className='amount'>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p>
                                                                            {t('transaction:transaction.withdrawal.amount', 'Amount')} ({currency}){' '}
                                                                            <span className='text-danger asterisk'>*</span>
                                                                        </p>
                                                                        <div className='deposit-amount currency-amount'>
                                                                            <input
                                                                                name='currencyAmount'
                                                                                className='form-control-inner'
                                                                                id='currencyAmount'
                                                                                placeholder={`${this.props.t(
                                                                                    'transaction:transaction.min',
                                                                                    'Min'
                                                                                )}: ${window.SPL_Other.formatAmount(this.props?.limit?.minLimit)} / ${this.props.t(
                                                                                    'transaction:transaction.max',
                                                                                    'Max'
                                                                                )}: ${window.SPL_Other.formatAmount(this.props?.limit?.dailyMaxAmount)} `}
                                                                                value={this.state.currencyAmount}
                                                                                onChange={(e) => this.handleCurrencyAmountChange(e.target.value)}
                                                                            />

                                                                            {this.state.remindMinAmount ? (
                                                                                <p className='text-red'>{this.state.remindMinAmount}</p>
                                                                            ) : this.state.cryptoFeeCharge && this.state.cryptoFeeCharge !== 0 ? (
                                                                                <p className='text-red refill-box'>
                                                                                    {t('transaction:transaction.deposit.crypto.cryptoFeeChargeMsg', {
                                                                                        chain: chainName,
                                                                                        feeCharge: this.state.cryptoFeeCharge,
                                                                                    })}
                                                                                </p>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className='amount-select-btn'>
                                                                        {amountOptions?.map((item) => (
                                                                            <button className='btn-amount' onClick={() => this.handleCurrencyAmountChange(item)}>
                                                                                {item}
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className='amount'>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p>
                                                                            {t('transaction:transaction.withdrawal.amount', 'Amount')} ({chainName}){' '}
                                                                            <span className='text-danger asterisk'>*</span>
                                                                        </p>
                                                                        <div className='deposit-amount'>
                                                                            <input
                                                                                name='cryptoAmount'
                                                                                className='form-control-inner'
                                                                                id='cryptoAmount'
                                                                                placeholder={chainName}
                                                                                value={this.state.cryptoAmount}
                                                                                onChange={(e) => this.handleCryptoAmountChange(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className='amount'>
                                                                <li>
                                                                    <div className='item'>
                                                                        <p></p>
                                                                        <div className='deposit-amount withdrawCrypto-submitBox'>
                                                                            <button
                                                                                type='submit'
                                                                                className='btn-normal btn-withdraw'
                                                                                onClick={this.handleWithdrawCryptoSubmit}
                                                                                // disabled={this.state.disabledWithdrawCryptoButton}
                                                                            >
                                                                                <span>{t('transaction:transaction.withdrawal.withdraw')}</span>
                                                                            </button>
                                                                            {this.state.withdrawSuccessMsg && (
                                                                                <p className='text-success refill-box'>{t(this.state.withdrawSuccessMsg)}</p>
                                                                            )}
                                                                            {this.state.withdrawErrMsg && (
                                                                                <span className='invalid-feedback text-danger m-l-5'>{t(this.state.withdrawErrMsg)}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            {withdrawChainRemark && withdrawChainRemark.length > 0 && (
                                                                <ul>
                                                                    <li>
                                                                        <div className='item'>
                                                                            <div className='remark-box'>
                                                                                <p className='remark-title'>{t('transaction:transaction.reminder', 'Reminder')}:</p>
                                                                                <ul className='remark-content'>
                                                                                    {withdrawChainRemark.map((info, index) => (
                                                                                        <Fragment key={index}>
                                                                                            <li>{info}</li>
                                                                                        </Fragment>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            )}

                                                            <ul>
                                                                <li>
                                                                    <span className='tnc-apply mb-only' onClick={() => this.getWithdrawTncReferral()}>
                                                                        * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                            )}
                                        </>
                                    ) : null}
                                </Fragment>
                            )}

                            {this.state.memberVerifyPopup && (
                                <li className='messaging-popup form-popup memberVerify-popup'>
                                    <div className='popup'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('transaction:transaction.memberVerify', 'Member Information Verification')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getMemberVerifyPopup()} />
                                                {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                            </div>
                                        </div>

                                        <div className='popup-body'>
                                            {this.state.showName && (
                                                <div className='name-popup'>
                                                    <p>{t('global:global.form.fullname')}</p>
                                                    <div className='popup-field'>
                                                        <input
                                                            name='fullname'
                                                            className={`form-control-inner`}
                                                            id='name'
                                                            placeholder='Full Name'
                                                            value={this.state.name}
                                                            type='text'
                                                            onChange={this.handleNameChange}
                                                            onBlur={this.validateFullname}
                                                        />
                                                        {this.state.nameError &&
                                                            this.state.nameError.map((errMsg, index) => {
                                                                return (
                                                                    <p key={index} className='text-red'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </p>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.showPhone && (
                                                <div className='num-popup'>
                                                    <p>{t('global:global.form.phone')}</p>
                                                    <div className='popup-field phone-field'>
                                                        <div className='phone-box'>
                                                            <FlagsSelect
                                                                telCodeList={this.state.telCodeList}
                                                                onSelect={this.selectCode}
                                                                countryCode={this.state.countryCode}
                                                            />
                                                            <input
                                                                name='mobile'
                                                                className={`popup-phone`}
                                                                id='mobile'
                                                                placeholder='Enter mobile'
                                                                value={this.state.phone}
                                                                onChange={this.handlePhoneChange}
                                                                onBlur={this.validatePhone}
                                                            />
                                                        </div>
                                                        {this.state.phoneError &&
                                                            this.state.phoneError.map((errMsg, index) => {
                                                                return (
                                                                    <p key={index} className='text-red'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </p>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.showEmail && (
                                                <div className='email-popup'>
                                                    <p>{t('global:global.form.email')}</p>
                                                    <div className='popup-field'>
                                                        <input
                                                            name='email'
                                                            className={`form-control-inner`}
                                                            id='email'
                                                            placeholder='Enter email'
                                                            value={this.state.email}
                                                            onChange={this.handleEmailChange}
                                                            onBlur={this.validateEmail}
                                                        />
                                                        {this.state.emailError &&
                                                            this.state.emailError.map((errMsg, index) => {
                                                                return (
                                                                    <p key={index} className='text-red'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </p>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.requestRefill && <p className='text-red refill-box'>Please fill in again.</p>}
                                        </div>

                                        <div className='popup-footer'>
                                            <button onClick={this.handleMemberVerifySubmit} disabled={this.state.memberVerifyDisable} className='btn-normal btn-submit-popup'>
                                                {t('transaction:transaction.deposit.localPay.submit', 'Submit')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getMemberVerifyPopup()}></div>
                                </li>
                            )}

                            {this.state.getShareInfo && (
                                <div className='messaging-popup share-popup'>
                                    <div className='popup'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('settings:settings.inviteFriend', 'Invite Friend')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getShareInfo()} />
                                                {/* <MdClose onClick={() => this.getShareInfo()} /> */}
                                            </div>
                                        </div>
                                        <div className='popup-body'>
                                            <div className='icon-box' onClick={() => this.copy(cryptoQrCode)}>
                                                <SVGElements className='icon-copy' name='copy-icon' />
                                                {/* <i className="icon-copy"></i> */}
                                            </div>
                                            <EmailShareButton url={cryptoQrCode}>
                                                <EmailIcon></EmailIcon>
                                            </EmailShareButton>
                                            <WhatsappShareButton url={cryptoQrCode}>
                                                <WhatsappIcon></WhatsappIcon>
                                            </WhatsappShareButton>
                                            <TelegramShareButton url={cryptoQrCode}>
                                                <TelegramIcon></TelegramIcon>
                                            </TelegramShareButton>
                                            <LineShareButton url={cryptoQrCode}>
                                                <LineIcon></LineIcon>
                                            </LineShareButton>
                                        </div>
                                    </div>
                                    <div className='popup-overlay' onClick={() => this.getShareInfo()}></div>
                                </div>
                            )}

                            {this.state.getDepositTncReferral && (
                                <li className='messaging-popup mobile-notice'>
                                    <div className='popup notice-box'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getDepositTncReferral()} />
                                                {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                            </div>
                                        </div>

                                        <TransactionNote {...transactionNoteProps} />
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getDepositTncReferral()}></div>
                                </li>
                            )}

                            {this.state.getWithdrawTncReferral && (
                                <li className='messaging-popup mobile-notice'>
                                    <div className='popup notice-box'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getWithdrawTncReferral()} />
                                                {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                            </div>
                                        </div>

                                        {transactionDetails && transactionDetails.withdrawal && transactionDetails.withdrawal[key.toLowerCase()] && (
                                            <div className='popup-body'>
                                                <div className='title'>
                                                    <i className='icon-notice-important'></i>
                                                    <span>{transactionDetails.withdrawal[key.toLowerCase()].noteTitle}</span>
                                                </div>
                                                <ul>
                                                    {(transactionDetails.withdrawal[key.toLowerCase()].stepNote || []).map((step, stepIndex) => (
                                                        <li key={step.id}>
                                                            <span>{stepIndex + 1}</span>
                                                            <p>{step.note}</p>
                                                        </li>
                                                    ))}
                                                    <li>
                                                        <p>{transactionDetails.withdrawal[key.toLowerCase()].noteText_1}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getWithdrawTncReferral()}></div>
                                </li>
                            )}

                            {this.state.addressModal && (
                                <AddCryptoAddressPopup
                                    onRequestClose={() => {
                                        this.getAddressModal(false);
                                    }}
                                    onSubmitSuccessCb={this.onAddCryptoAddressSuccessCb.bind(this)}
                                />
                            )}

                            <div className='row d-flex justify-content-center'></div>
                        </div>
                    )}
                </Translation>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'global', 'error', 'googleAuthenticator'])(withRouter(Crypto)));

export const AddCryptoAddressButton = ({ onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MODULE_TYPE?.CRYPTO_ADDRESS, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <>
            <button className='btn-addnewbank' onClick={onAddBtnClick}>
                <IoIosAdd />
            </button>
        </>
    );
};
